import React, { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "submit" | "reset";
  extraClassName?: string;
  title?: string;
  text?: string;
  children?: ReactNode;
  btnVariant?: "icon" | "link";
  btnColor?: "gradient" | "gradientText" | "purple" | "dark-medium" | "gradientOrange";
  btnSize?: "sm";
  isFullWidth?: boolean;
  icon?: ReactNode;
  iconPlacement?: "left";
  onClick?: () => void;
}

function Button(
  {
    type,
    extraClassName,
    text,
    title,
    children,
    btnVariant,
    btnColor,
    btnSize,
    isFullWidth,
    icon,
    iconPlacement,
    onClick,
    ...props
  }: ButtonProps,
  ref?: React.Ref<HTMLButtonElement>
) {
  const { i18 } = useLanguage();

  /*
  "btn.text": "Button Text",
  "btn.title": "Button Title",
  "btn.ariaLabel": "Button Title",
*/
  return (
    <button
      type={type ? type : "button"}
      className={clsx("btn", extraClassName && extraClassName, isFullWidth && "isFullWidth")}
      title={title ?? text ?? i18("Button Title", "btn.title")}
      aria-label={title ?? text ?? i18("Button Title", "btn.ariaLabel")}
      data-variant={btnVariant && btnVariant}
      data-color={btnColor && btnColor}
      data-size={btnSize && btnSize}
      data-icon-placement={iconPlacement && iconPlacement}
      onClick={onClick}
      {...props}
      ref={ref}
    >
      {"gradientText" === btnColor ? (
        <span className="btnWrapper">
          {text ? <span className="btnText">{text}</span> : children}
          {icon && <span className="btnIcon">{icon}</span>}
        </span>
      ) : (
        <>
          {text ? text : children}
          {icon && icon}
        </>
      )}
    </button>
  );
}

export default forwardRef(Button);
