import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import BigNumber from "bignumber.js";
import { findIndex } from "lodash";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssetBalances,
  selectAssets,
  selectContracts,
  selectPools,
  // selectGlobalConfig,
  selectWalletInfo,
  selectChains,
} from "@axvdex/state/wallet/walletSelectors";
import { formatBalance, fromHumanAmountToTokenBalance } from "@axvdex/utils/formatNumber";
import {
  executeContract,
  handleClaimUnmint,
  handleUnMint,
  retryWalletWithDispatch,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import { IAsset } from "@axvdex/utils/interfaces";
import imgSanitize from "@axvdex/utils/imgSanitize";
// import estimatedFees, {
//   GAS_ESTIMATION_STATIC_MINT_DERIVATIVE,
//   GAS_ESTIMATION_STATIC_UNMINT_DERIVATIVE,
// } from "@axvdex/utils/estimatedFees";
import parseWalletErrorMessage from "@axvdex/utils/parseWalletErrorMessage";
import useLanguage from "@axvdex/hooks/useLanguage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { ReactComponent as IcnTrade } from "../../assets/icons/icn-exchange-vertical.svg";

import CustomSelect from "../form-element/CustomSelect";
import Button from "../common/Button";
import CustomNumericInput from "../form-element/CustomNumericInput";
import UserBalance from "../user/UserBalance";
import CustomLoader from "../common/CustomLoader";

interface MyAssetsManageTokenDerivativeFormProps {
  onCloseModal: () => void;
  selectedAsset: IAsset | null;
  setSelectedAsset: (asset: IAsset) => void;
}

function MyAssetsManageTokenDerivativeForm({
  onCloseModal,
  selectedAsset,
  setSelectedAsset,
}: MyAssetsManageTokenDerivativeFormProps) {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets);
  const pools = useAppSelector(selectPools);
  const chains = useAppSelector(selectChains);
  const assetBalances = useAppSelector(selectAssetBalances);
  // const globalConfig = useAppSelector(selectGlobalConfig);
  const contracts = useAppSelector(selectContracts);

  const [tokenOptions, setTokenOptions] = useState([]);

  const [counterPartyToken, setCounterPartyToken] = useState(null);

  useEffect(() => {
    setTokenOptions(
      Object.entries(assets)
        .filter(asset => asset[1].xDerivativeTarget || asset[1].isDerivative)
        .map(asset => {
          return {
            label: asset[1].symbol + ` (${chains[asset[1].contextChainId].displayName})`,
            value: asset[1].id,
            optionPrefix: (
              <img
                src={imgSanitize(asset[1].symbol)}
                alt={i18(`${asset[1].symbol} - Token`, "managetokens.select.options.prefix.alt", {
                  symbol: asset[1].symbol,
                })}
              />
            ),
          };
        })
    );
  }, [assets]);

  useEffect(() => {
    if (selectedAsset) {
      const walletChainContext = walletInfo.connectedChains[selectedAsset.contextChainId];
      const counterPartyAssetID =
        assets[selectedAsset.id].xDerivativeTarget ?? assets[selectedAsset.id].derivativeSource;
      if (!counterPartyAssetID) {
        setCounterPartyToken(null);
        return;
      }
      if (counterPartyAssetID && assets[counterPartyAssetID]) {
        setCounterPartyToken({
          label: assets[counterPartyAssetID].symbol,
          value: assets[counterPartyAssetID].id,
          img: (
            <img
              src={imgSanitize(assets[counterPartyAssetID].symbol)}
              alt={i18(`${assets[counterPartyAssetID].symbol} - Token`, "managetokens.select.options.prefix.alt", {
                symbol: assets[counterPartyAssetID].symbol,
              })}
            />
          ),
        });
      }
      if (!assetBalances[counterPartyAssetID] && assets[counterPartyAssetID]) {
        if (assets[counterPartyAssetID].isNative && walletChainContext) {
          dispatch(
            updateNativeBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              denom: assets[counterPartyAssetID].denom,
            })
          );
        } else if (walletChainContext) {
          dispatch(
            updateTokenBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              tokenAddress: assets[counterPartyAssetID].address,
            })
          );
        }
      }
    }
  }, [selectedAsset]);

  const getMinimumAmount = () => {
    const derivativeContract = Object.entries(contracts).find(
      ([_, contract]) =>
        contract.config &&
        contract.config.network_settings &&
        (selectedAsset.id === contract.config.network_settings.native_asset_denom ||
          counterPartyToken.value === contract.config.network_settings.native_asset_denom)
    );

    return {
      minimum_deposit_amount: derivativeContract
        ? derivativeContract[1].config.owner_settings.minimum_deposit_amount
        : "0",
      minimum_withdrawal_amount: derivativeContract
        ? derivativeContract[1].config.owner_settings.minimum_withdrawal_amount
        : "0",
    };
  };

  const handleTokenChange = selectedOption => {
    if (!assetBalances[selectedOption.value]) {
      const walletChainContext = walletInfo.connectedChains[assets[selectedOption.value].contextChainId];
      if (walletChainContext && assets[selectedOption.value].isNative) {
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            denom: selectedOption.value,
          })
        );
      } else if (walletChainContext) {
        dispatch(
          updateTokenBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            tokenAddress: selectedOption.value,
          })
        );
      }
    }
    setSelectedAsset(assets[selectedOption.value]);
  };

  const [amountInput, setAmountInput] = useState("");

  const [isLoadingActionTrigger, setIsLoadingActionTrigger] = useState(false);

  const handleMintActionTrigger = async () => {
    setIsLoadingActionTrigger(true);
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    const walletChainContext = walletInfo.connectedChains[assets[counterPartyToken.value].contextChainId];
    try {
      const derivativeContract =
        contracts["derivative-" + counterPartyToken.label + "_" + assets[counterPartyToken.value].contextChainId];
      // console.log(derivativeContract);
      // const derivativeContract = Object.entries(contracts).find(
      //   ([_, contract]) =>
      //     contract.config &&
      //     contract.config.network_settings &&
      //     (selectedAsset.id === contract.config.network_settings.native_asset_denom ||
      //       counterPartyToken.value === contract.config.network_settings.native_asset_denom)
      // );

      const poolAddress =
        derivativeContract.config.mint_derivative_allowlist &&
        derivativeContract.config.mint_derivative_allowlist.find((allowAddress: string) => pools[allowAddress]);

      if (poolAddress) {
        const res = await executeContract(
          walletChainContext,
          poolAddress,
          {
            swap: {
              swap_to_asset_index: 1,
            },
          },
          [
            {
              denom: assets[selectedAsset.id].denom,
              amount: fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedAsset.id].decimals),
            },
          ]
        );
        txLink = `/${res.transactionHash}`;
        isSuccessful = true;
        msg = i18("Success get xAsset", "managetokens.derivative.mint.msg.success");
        setAmountInput("");
      } else {
        msg = i18(
          "No mint_derivative_allowlist address pool found...",
          "managetokens.derivative.mint.msg.failed.address"
        );
        isSuccessful = false;
      }
    } catch (e) {
      console.log(e);
      const errMsg = parseWalletErrorMessage(e.message, i18, walletChainContext.chainState.feeCurrencies);
      msg = i18(`Failed get xAsset. Error: ${errMsg}`, "managetokens.derivative.mint.msg.failed", { errMsg });
      isSuccessful = false;
    }
    // send toast and update relevant balances
    dispatch(
      sendToast({
        type: isSuccessful ? "tx-success" : "tx-fail",
        info: { msg, txLink, toastID: "" + new Date().getTime() },
      })
    );
    for (const assetID of [
      selectedAsset.id,
      counterPartyToken.value,
      walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
    ]) {
      if (assets[assetID].isNative) {
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            denom: assets[assetID].denom,
          })
        );
      } else {
        dispatch(
          updateTokenBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            tokenAddress: assets[assetID].address,
          })
        );
      }
    }

    setIsLoadingActionTrigger(false);
  };

  const reconnectWallet = async () => {
    setIsLoadingActionTrigger(true);
    await dispatch(
      retryWalletWithDispatch({
        chainToConnect: chains[selectedAsset.contextChainId],
      })
    );
    setIsLoadingActionTrigger(false);
  };

  const handleUnMintActionTrigger = async () => {
    setIsLoadingActionTrigger(true);
    const amount = fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedAsset.id].decimals);
    await dispatch(
      handleUnMint({
        derivativeAsset: selectedAsset,
        amount,
        i18,
      })
    );
    setIsLoadingActionTrigger(false);
  };

  const submitButtonText = () => {
    const action = assets[selectedAsset.id].xDerivativeTarget
      ? i18("Get xAsset", "managetokens.derivative.submitBtn.text.mint")
      : i18("Unmint xAsset", "managetokens.derivative.submitBtn.text.unmint");

    if (!walletInfo.connectedChains[selectedAsset.contextChainId]?.signingStargateClient) {
      return {
        buttonText: i18(
          "Not connected to " + chains[selectedAsset.contextChainId]?.displayName + ". Try reconnect...",
          "poolCardTable.submitBtn.text.notConnected"
        ),
        disabled: false,
        reconnectWalletAction: true,
      };
    }

    if ("0" === amountInput || !amountInput || "" === amountInput) {
      return {
        buttonText: i18("Enter Amount", "managetokens.derivative.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (
      BigNumber(fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedAsset.id].decimals)).gt(
        BigNumber(assetBalances[selectedAsset.id])
      )
    ) {
      return {
        buttonText: i18(
          "Not enough " + assets[selectedAsset.id].symbol + " balance",
          "managetokens.derivative.submitBtn.text.notEnoughBalance",
          { symbol: assets[selectedAsset.id].symbol }
        ),
        disabled: true,
      };
    }

    if (
      BigNumber(fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedAsset.id].decimals)).lt(
        assets[selectedAsset.id].xDerivativeTarget
          ? getMinimumAmount().minimum_deposit_amount
          : getMinimumAmount().minimum_withdrawal_amount
      )
    ) {
      return {
        buttonText: i18("Minimum amount not reached", "managetokens.derivative.submitBtn.text.minimumAmount"),
        disabled: true,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this
    // const calcEstimatedFees = estimatedFees(
    //   assets[selectedAsset.id].xDerivativeTarget
    //     ? GAS_ESTIMATION_STATIC_MINT_DERIVATIVE
    //     : GAS_ESTIMATION_STATIC_UNMINT_DERIVATIVE,
    //   globalConfig.statusCounters[selectedAsset.contextChainId].estimatedFeesReference
    // );
    // const feeDenom =
    //   globalConfig.statusCounters[selectedAsset.contextChainId].estimatedFeesReference.estimatedFee[0].denom;
    // if (assetBalances[feeDenom] && BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for ${action}`,
    //       "managetokens.derivative.submitBtn.text.walletNoFunds",
    //       {
    //         symbol: assets[feeDenom].symbol,
    //         action: action,
    //       }
    //     ),
    //     disabled: true,
    //   };
    // }

    // // if the input is the source denom we need to check if it will consume more fees
    // // this can only happen on mint part
    // // current balance - input amount < estimated fees
    // if (
    //   assets[selectedAsset.id].xDerivativeTarget &&
    //   selectedAsset.id === feeDenom &&
    //   assetBalances[feeDenom] &&
    //   BigNumber(assetBalances[feeDenom])
    //     .minus(fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedAsset.id].decimals))
    //     .lt(calcEstimatedFees)
    // ) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for ${action}`,
    //       "managetokens.derivative.submitBtn.text.walletNoFunds",
    //       {
    //         symbol: feeDenom,
    //         action: action,
    //       }
    //     ),
    //     disabled: true,
    //   };
    // }

    return {
      buttonText: action,
      disabled: false,
    };
  };

  const switchTokens = () => {
    setSelectedAsset(assets[counterPartyToken.value]);
  };

  return (
    <form className={clsx("dashboardMyAssetsForm", "derivative")}>
      <fieldset className="derivativeFromFieldset withGradientBorderBottom">
        <legend className="visuallyHidden">{i18("Select token", "managetokens.derivative.legend")}</legend>

        <div className="selectTokenSubFieldset derivativeFrom">
          <div className="flexbox">
            <CustomSelect
              name="derivative_from_token"
              labelText={i18("Asset", "managetokens.derivative.select.label")}
              hiddenLabel={true}
              placeholder={i18("Select asset", "managetokens.derivative.select.placeholder")}
              items={
                // filter out derivative assets that have withdrawals disabled
                tokenOptions.filter(
                  token =>
                    Object.values(contracts).find(
                      contract => contract.address === assets[token.value].derivativeContract
                    )?.config.owner_settings.is_withdrawals_enabled
                )
              }
              value={tokenOptions[findIndex(tokenOptions, item => item.value === selectedAsset?.id) || 0]}
              onChange={handleTokenChange}
              required
            />

            <CustomNumericInput
              extraClassName="derivativeFromUsdFormGroup"
              name="derivative_from_usd"
              labelText={i18("Enter amount", "managetokens.derivative.input.label")}
              hiddenLabel={true}
              placeholder={"0"}
              value={amountInput}
              onChange={e => setAmountInput(e.target.value)}
              disabled={!counterPartyToken}
            />
          </div>

          <div className="helpText selectTokenSubFieldsetHelpText">
            <span className="textGrey">
              {selectedAsset
                ? formatBalance(
                    fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedAsset.id].decimals),
                    assets[selectedAsset.id].price,
                    assets[selectedAsset.id].decimals
                  ).usdConversion
                : 0}
              &nbsp;USD&nbsp;
            </span>
            {/* <span className="textGrey">0&nbsp;USD&nbsp;-&nbsp;</span> */}
            {/* <span className="textGradient">{t("Use USD instead")}</span> */}
          </div>
        </div>

        {selectedAsset && assetBalances[selectedAsset.id] && (
          <div className="selectTokenBalanceAmount">
            <UserBalance
              balanceValue={
                formatBalance(
                  assetBalances[selectedAsset.id],
                  assets[selectedAsset.id].price,
                  assets[selectedAsset.id].decimals
                ).amount
              }
              balanceUsdValue={
                formatBalance(
                  assetBalances[selectedAsset.id],
                  assets[selectedAsset.id].price,
                  assets[selectedAsset.id].decimals
                ).usdConversion
              }
            />
            {selectedAsset && counterPartyToken && (
              <Button
                btnColor="dark-medium"
                text={i18("Max", "managetokens.derivative.maxBtn.text")}
                title={i18("Set Max Value", "managetokens.derivative.maxBtn.title")}
                onClick={() =>
                  setAmountInput(
                    formatBalance(
                      assetBalances[selectedAsset.id],
                      assets[selectedAsset.id].price,
                      assets[selectedAsset.id].decimals
                    ).amount.toString()
                  )
                }
              />
            )}
          </div>
        )}
        <Button
          extraClassName="switchIcon"
          btnColor="dark-medium"
          btnVariant="icon"
          title={i18("Switch trade tokens", "trade.form.switchBtn.title")}
          icon={<IcnTrade />}
          onClick={switchTokens}
        />
      </fieldset>

      {counterPartyToken && (
        <>
          <fieldset className="derivativeToFieldset withGradientBorderBottom">
            <legend className="visuallyHidden">{i18("Select token", "managetokens.derivative.legend")}</legend>
            <div className="selectTokenSubFieldset derivativeTo">
              <div className="flexbox">
                <div className="inlineFlexbox">
                  <span className="tokenIcon">{counterPartyToken.img}</span>
                  <span className="tokenName">{assets[counterPartyToken.value].symbol}</span>
                </div>

                <CustomNumericInput
                  extraClassName="derivativeFromUsdFormGroup gradientText"
                  name="derivative_to_usd"
                  labelText={i18("Amount", "managetokens.derivative.counterParty.input.label")}
                  hiddenLabel={true}
                  placeholder={"0"}
                  value={amountInput.replace(/^0+/, "")}
                  disabled
                />
              </div>

              <div className="helpText selectTokenSubFieldsetHelpText">
                <span className="textGrey">
                  {
                    formatBalance(
                      fromHumanAmountToTokenBalance(
                        amountInput.replaceAll(",", ""),
                        assets[counterPartyToken.value].decimals
                      ),
                      assets[counterPartyToken.value].price,
                      assets[counterPartyToken.value].decimals
                    ).usdConversion
                  }
                  &nbsp;USD&nbsp;
                </span>
              </div>
            </div>

            {assetBalances[counterPartyToken.value] && (
              <div className="selectTokenBalanceAmount">
                <UserBalance
                  balanceValue={
                    formatBalance(
                      assetBalances[counterPartyToken.value],
                      assets[counterPartyToken.value].price,
                      assets[selectedAsset.id].decimals
                    ).amount
                  }
                  balanceUsdValue={
                    formatBalance(
                      assetBalances[counterPartyToken.value],
                      assets[counterPartyToken.value].price,
                      assets[counterPartyToken.value].decimals
                    ).usdConversion
                  }
                />
              </div>
            )}
          </fieldset>
        </>
      )}

      <div className="buttonContainer">
        <Button
          btnColor="gradientText"
          text={i18("Cancel", "managetokens.cancelBtn.text")}
          title={i18("Cancel and close", "managetokens.cancelBtn.title")}
          onClick={onCloseModal}
        />
        {isLoadingActionTrigger ? (
          <CustomLoader size="xs" text={i18("Processing...", "managetokens.loader.text")} />
        ) : (
          counterPartyToken && (
            <Button
              btnColor="gradient"
              title={i18("Submit", "managetokens.derivative.submitBtn.title")}
              text={submitButtonText().buttonText}
              onClick={async () =>
                submitButtonText().reconnectWalletAction
                  ? await reconnectWallet()
                  : assets[selectedAsset.id].xDerivativeTarget
                  ? await handleMintActionTrigger()
                  : await handleUnMintActionTrigger()
              }
              disabled={submitButtonText().disabled}
            />
          )
        )}
      </div>
      {selectedAsset && counterPartyToken && !assets[selectedAsset.id].xDerivativeTarget && !isLoadingActionTrigger && (
        <section className="pendingUnmintSection">
          <UnmintList selectedAsset={assets[selectedAsset.id]} counterPartyAsset={assets[counterPartyToken.value]} />
        </section>
      )}
    </form>
  );
}

const UnmintList = ({ selectedAsset, counterPartyAsset }: { selectedAsset: IAsset; counterPartyAsset: IAsset }) => {
  // if the asset selected is an Astrovault derivative => get the corresponding derivative contract
  // get user specific pending unminting from the contract {"user_withdrawals": {"address": ""}}
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectContracts);
  const assets = useAppSelector(selectAssets);
  const [unmints, setUnmints] = useState(null);
  const [curWindow, setCurWindow] = useState(null);
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);

  const walletChainContext = walletInfo.connectedChains[selectedAsset.contextChainId];

  useEffect(() => {
    selectedAsset.derivativeContract && getInit();
  }, [selectedAsset]);

  const getInit = async () => {
    if (walletChainContext) {
      const [res, curWindowDetails] = await Promise.all([
        rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, selectedAsset.derivativeContract, {
          user_withdrawals: { address: walletChainContext.address },
        }),
        rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, selectedAsset.derivativeContract, {
          window: {},
        }),
      ]);
      setUnmints(res);
      setCurWindow(curWindowDetails);
    }
  };

  const handleClaim = async () => {
    setIsLoadingClaim(true);
    await dispatch(handleClaimUnmint({ derivativeAsset: selectedAsset, i18 }));
    await getInit();
    setIsLoadingClaim(false);
  };

  const defaultColumns = useMemo(() => {
    return [
      {
        id: "token",
        accessorKey: "token",
        header: () => <span>{i18("Token", "managetokens.derivative.unmint.table.token")}</span>,
        width: "auto",
        cell: ({ row }) => (
          <span
            className="tokenIcon"
            title={row.original.counterPartyAsset.symbol}
            aria-label={row.original.counterPartyAsset.symbol}
          >
            <img src={imgSanitize(row.original.counterPartyAsset.symbol)} alt={row.original.counterPartyAsset.symbol} />
          </span>
        ),
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: () => <span>{i18("Amount", "managetokens.derivative.unmint.table.amount")}</span>,
        cell: ({ row }) => (
          <div className="inlineFlexbox">
            {
              formatBalance(row.original.amount, row.original.selectedAsset.price, row.original.selectedAsset.decimals)
                .amount
            }
            <span className="tableInputValueConversion">
              ($
              {
                formatBalance(
                  row.original.amount,
                  row.original.selectedAsset.price,
                  row.original.selectedAsset.decimals
                ).usdConversion
              }
              )
            </span>
          </div>
        ),
      },
      // {
      //   id: "startDate",
      //   accessorKey: "startDate",
      //   header: () => <span>{i18("Start date", "managetokens.derivative.unmint.table.startDate")}</span>,
      //   cell: ({ row }) =>
      //     row.original.startDate ? (
      //       <time dateTime="2001-05-15T19:00" className="date">
      //         {dayjs(row.original.startDate * 1000).format("DD/MM/YYYY")}&nbsp;
      //         <br />
      //         <em style={{ color: "var(--warm-grey)" }}>{dayjs(row.original.startDate * 1000).format("LT")}</em>
      //       </time>
      //     ) : (
      //       "-"
      //     ),
      // },
      {
        id: "endDate",
        accessorKey: "date",
        header: () => <span>{i18("Estimated end date", "managetokens.derivative.unmint.table.endDate")}</span>,
        cell: ({ row }) =>
          row.original.endDate ? (
            <time dateTime="2001-05-15T19:00" className="date">
              {dayjs(row.original.endDate * 1000).format("DD/MM/YYYY")}
              <em style={{ color: "var(--warm-grey)" }}>{dayjs(row.original.endDate * 1000).format("LT")}</em>
            </time>
          ) : (
            i18("queued", "managetokens.derivative.unmint.table.queued")
          ),
      },
    ];
  }, []);

  const [columns, setColumns] = useState(() => [...defaultColumns]);
  const [data, setData] = useState(() => []);

  useEffect(() => {
    if (unmints) {
      setData(
        unmints.map(unmint => {
          return {
            id: unmint.window_id,
            selectedAsset,
            counterPartyAsset,
            amount: unmint.amount,
            startDate: unmint.window_details.withdrawal_started_timestamp,
            endDate: unmint.window_details.withdrawal_estimated_end_timestamp,
          };
        })
      );
    }
  }, [unmints]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const getContract = () => {
    return Object.entries(contracts).find(
      ([_, contract]) => contract.address === assets[selectedAsset.id].derivativeContract
    )[1];
  };

  const displayUnmintingTime = () => {
    const contract = getContract();
    if (contract) {
      const unmintSeconds = contract.config.network_settings.unbonding_time,
        unmintDays = Math.floor(unmintSeconds / (24 * 3600));

      return 0 === unmintDays
        ? { number: Math.floor(unmintSeconds / 3600), dim: i18("hours", "global.lower.hours") }
        : {
            number: unmintDays,
            dim: 1 === unmintDays ? i18("day", "global.lower.day") : i18("days", "global.lower.days"),
          };
    }
  };

  const displayWindowTime = () => {
    const contract = getContract();
    if (contract) {
      let windowSeconds = contract.config.network_settings.window_time;
      if (curWindow) {
        const curTime = new Date().getTime() / 1000;
        const estimatedTriggerWindow = windowSeconds + curWindow.creation_timestamp;
        const timeLeft = estimatedTriggerWindow - curTime;

        if (timeLeft < -3600 * 3)
          // BUFFER OF negative 3 hours. After this buffer, enter "maintenance mode"
          return null;

        windowSeconds = timeLeft < 3600 ? 3600 : timeLeft;
      }

      const windowDays = Math.floor(windowSeconds / (24 * 3600));
      return 0 === windowDays
        ? { number: Math.floor(windowSeconds / 3600), dim: i18("hours", "global.lower.hours") }
        : {
            number: windowDays,
            dim: 1 === windowDays ? i18("day", "global.lower.day") : i18("days", "global.lower.days"),
          };
    }
  };

  const dut = displayUnmintingTime(),
    dwt = displayWindowTime();
  return (
    <>
      <p className="textGrey">
        {null === dut || null === dwt
          ? // if withdrawals are disabled, don't show any text
            Object.values(contracts).find(contract => contract.address === selectedAsset.derivativeContract)?.config
              .owner_settings.is_withdrawals_enabled
            ? i18(
                `Unminting is taking an unusual amount of time, this is being actively investigated`,
                "managetokens.derivative.warningWindow.text"
              )
            : ""
          : i18(
              `Unminting takes ${dut.number} ${dut.dim}, plus around ${dwt.number} ${dwt.dim} of window time`,
              "managetokens.derivative.pendingUnminting.text",
              {
                unmintingTime: `${dut.number}`,
                unmintingDim: `${dut.dim}`,
                windowTime: `${dwt.number}`,
                windowDim: `${dwt.dim}`,
              }
            )}
      </p>
      {!(unmints && unmints.length > 0) && (
        <h4 className="h4">{i18("Unmints to claim will display here", "managetokens.derivative.unmint.title")}</h4>
      )}
      {selectedAsset.derivativeContract && (
        <section className={clsx("pendingUnmintTableSection", !(unmints && unmints.length > 0) && "visuallyHidden")}>
          <div className="pendingUnmintTableSectionHeader flexbox">
            <h3 className="h3">{i18("Pending unmints", "managetokens.derivative.unmint.pendingUnmints.title")}</h3>

            {unmints && unmints.length > 0 && (
              <Button
                text={i18("Claim", "managetokens.derivative.unmint.pendingUnmints.claim")}
                btnColor="gradientText"
                onClick={handleClaim}
                disabled={
                  !(
                    isLoadingClaim ||
                    unmints.find(unmint => {
                      const endDate = Object.entries(contracts).find(
                        ([_, contract]) => contract.address === selectedAsset.derivativeContract
                      )[1].config.is_external_source
                        ? unmint.window_details.withdrawal_external_end_timestamp
                        : unmint.window_details.withdrawal_estimated_end_timestamp;
                      if (!endDate) return false;
                      return new Date().getTime() > endDate * 1000;
                    })
                  )
                }
              />
            )}
          </div>

          <table className={clsx("table", "withGradientBorderRows", "pendingUnmintTable")}>
            <thead>
              {useReactTable({
                data,
                columns,
                getCoreRowModel: getCoreRowModel(),
              })
                .getHeaderGroups()
                .map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
            </thead>
            <tbody>
              {useReactTable({
                data,
                columns,
                getCoreRowModel: getCoreRowModel(),
              })
                .getRowModel()
                .rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
      )}
    </>
  );
};

export default MyAssetsManageTokenDerivativeForm;
