import BigNumber from "bignumber.js";
import { lp_token_from_18_to_6, normalize_amount } from "../formatNumber";
import { IPool } from "../interfaces";
import { div, min, times } from "../math";

const stakeSimulation = (pool: IPool, amounts: (string | null)[]) => {
  try {
    // if this pool is standard, the amounts is length == 2 and the ratio needs to match!
    if ("standard" === pool.type) {
      if (!amounts[0] && !amounts[1]) {
        throw new Error("No amount specified");
      }

      if ("0" === pool.total_share) {
        // it means there is no liquidity so this user can input both amounts as he will define the ratio
        const funds = [];
        pool.poolAssets.forEach((asset, index) => {
          if (asset.info.native_token && amounts[index] !== "0") {
            funds.push({
              denom: asset.info.native_token.denom,
              amount: BigNumber(amounts[index])
                .times(Math.pow(10, pool.assetDecimals[index]))
                .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                .toString(10),
            });
          }
        });

        return {
          firstAssetRatio: "0",
          secondAssetRatio: "0",
          returnAmounts: [amounts[0], amounts[1]],
          lpTokensAmount: null,
          tx: {
            provide_liquidity: {
              assets: [
                {
                  info: pool.poolAssets[0].info,
                  amount: BigNumber(amounts[0])
                    .times(Math.pow(10, pool.assetDecimals[0]))
                    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                    .toString(10),
                },
                {
                  info: pool.poolAssets[1].info,
                  amount: BigNumber(amounts[1])
                    .times(Math.pow(10, pool.assetDecimals[1]))
                    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                    .toString(10),
                },
              ],
              direct_staking: {
                not_claim_rewards: true,
              },
            },
          },
          funds,
        };
      }

      const poolTotalShares = BigNumber(pool.total_share).div(Math.pow(10, 6)).toString(10);
      const poolAsset0Amount = BigNumber(pool.poolAssets[0].amount)
        .div(Math.pow(10, pool.assetDecimals[0]))
        .toString(10);
      const poolAsset1Amount = BigNumber(pool.poolAssets[1].amount)
        .div(Math.pow(10, pool.assetDecimals[1]))
        .toString(10);

      const firstAssetRatio = poolAsset0Amount !== "0" ? BigNumber(poolAsset1Amount).div(poolAsset0Amount) : null;
      const secondAssetRatio = poolAsset1Amount !== "0" ? BigNumber(poolAsset0Amount).div(poolAsset1Amount) : null;

      const returnAmounts: any = [null, null];

      if (!amounts[0]) {
        returnAmounts[1] = amounts[1]!;
        if (pool.total_share !== "0") {
          returnAmounts[0] = BigNumber(returnAmounts[1]).times(secondAssetRatio!).toString();
        }
      }

      if (!amounts[1]) {
        returnAmounts[0] = amounts[0]!;
        if (pool.total_share !== "0") {
          returnAmounts[1] = BigNumber(returnAmounts[0]).times(firstAssetRatio!).toString();
        }
      }

      if (amounts[1] && amounts[0]) {
        returnAmounts[0] = amounts[0]!;
        returnAmounts[1] = amounts[1]!;
      }

      let lpTokensAmount: string | null = "";
      if ("0" === pool.total_share) {
        lpTokensAmount = null;
      } else {
        lpTokensAmount = min([
          BigNumber(returnAmounts[0])
            .times(poolTotalShares)
            .div(poolAsset0Amount)
            .decimalPlaces(0, BigNumber.ROUND_FLOOR)
            .toString(10),
          BigNumber(returnAmounts[1])
            .times(poolTotalShares)
            .div(poolAsset1Amount)
            .decimalPlaces(0, BigNumber.ROUND_FLOOR)
            .toString(10),
        ]);
      }

      const funds = [];
      pool.poolAssets.forEach((asset, index) => {
        if (asset.info.native_token && amounts[index] !== "0") {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: BigNumber(returnAmounts[index])
              .times(Math.pow(10, pool.assetDecimals[index]))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
          });
        }
      });

      const res = {
        firstAssetRatio,
        secondAssetRatio,
        returnAmounts: returnAmounts.map(amount => BigNumber(amount).toString(10)),
        lpTokensAmount,
        tx: {
          provide_liquidity: {
            assets: [
              {
                info: pool.poolAssets[0].info,
                amount: BigNumber(returnAmounts[0])
                  .times(Math.pow(10, pool.assetDecimals[0]))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
              {
                info: pool.poolAssets[1].info,
                amount: BigNumber(returnAmounts[1])
                  .times(Math.pow(10, pool.assetDecimals[1]))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
            ],
            direct_staking: {
              not_claim_rewards: true,
            },
          },
        },
        funds,
      };

      return res;
    }

    if ("stable" === pool.type || "hybrid" === pool.type) {
      let lpTokensAmount = BigInt(0);
      const assets_amount: string[] = [];

      amounts.forEach((amount, i) => {
        if (amount) {
          const amountRaw = Number(times(amount, Math.pow(10, pool.assetDecimals[i]))).toLocaleString("fullwide", {
            useGrouping: false,
          });

          if (amountRaw !== "NaN") {
            lpTokensAmount = normalize_amount(amountRaw.toString(), pool.assetDecimals[i]) + lpTokensAmount;

            assets_amount.push(amountRaw.toString());
          } else {
            assets_amount.push("0");
          }
        } else {
          assets_amount.push("0");
        }
      });

      const funds = [];
      pool.poolAssets.forEach((asset, index) => {
        if (asset.info.native_token && assets_amount[index] !== "0") {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: assets_amount[index],
          });
        }
      });

      return {
        returnAmounts: amounts,
        lpTokensAmount: div(lp_token_from_18_to_6(lpTokensAmount.toString()).toString(), Math.pow(10, 6)),
        tx: {
          deposit: {
            assets_amount,
            direct_staking: {
              not_claim_rewards: true,
            },
          },
        },
        funds,
      };
    }
  } catch (e) {
    /* empty */
  }
};

export default stakeSimulation;

export const hybridStakeCheckUnbalancingThreshold = (pool: IPool, amountsToDeposit: bigint[]) => {
  const PRECISION = BigInt(1000000);

  // normalize the assets_amount
  const assets_amount_normalized = [
    normalize_amount(amountsToDeposit[0], pool.assetDecimals[0]),
    normalize_amount(amountsToDeposit[1], pool.assetDecimals[1]),
  ];

  const pools_amount_normalized = [
    normalize_amount(pool.poolAssets[0].amount, pool.assetDecimals[0]),
    normalize_amount(pool.poolAssets[1].amount, pool.assetDecimals[1]),
  ];

  const pool0_value = BigInt(pools_amount_normalized[0]);
  const pool1_value = (BigInt(pools_amount_normalized[1]) * BigInt(pool.hybridRatioDetails.ratio)) / PRECISION;

  const pool_total_value = pool0_value + pool1_value;

  const asset0_deposit_value = assets_amount_normalized[0];
  const asset1_deposit_value = (assets_amount_normalized[1] * BigInt(pool.hybridRatioDetails.ratio)) / PRECISION;

  const pool_asset0_after_per =
    ((pool0_value + asset0_deposit_value) * PRECISION) /
    (pool_total_value + asset0_deposit_value + asset1_deposit_value);
  const pool_asset1_after_per =
    ((pool1_value + asset1_deposit_value) * PRECISION) /
    (pool_total_value + asset0_deposit_value + asset1_deposit_value);

  if (pool_asset0_after_per > BigInt(pool.settings.max_deposit_unbalancing_threshold)) {
    if (amountsToDeposit[0] > BigInt(0)) {
      return true;
    }
  }
  if (pool_asset1_after_per > BigInt(pool.settings.max_deposit_unbalancing_threshold)) {
    if (amountsToDeposit[1] > BigInt(0)) {
      return true;
    }
  }

  return false;
};

export const stableStakeCheckUnbalancingThreshold = (pool: IPool, amountsToDeposit: bigint[]) => {
  const pool_assets = pool.poolAssets;
  const max_deposit_unbalancing_threshold = pool.settings.max_deposit_unbalancing_threshold;
  const PRECISION = BigInt(1000000);

  // For asset pool native token, balance is already increased
  // To calculated properly we should add user deposit from the pool as the message will only trigger the transfer from after
  const pool_assets_after_deposit = [];
  let pool_assets_total_amount = BigInt(0);
  for (const [i, pool_asset] of pool_assets.entries()) {
    const new_amount = BigInt(pool_asset.amount) + amountsToDeposit[i];
    const formated_new_amount = normalize_amount(new_amount, pool.assetDecimals[i]);
    pool_assets_after_deposit.push(formated_new_amount);
    pool_assets_total_amount += formated_new_amount;
  }

  for (const [i, asset_amount] of pool_assets_after_deposit.entries()) {
    const asset_pool_per = (asset_amount * PRECISION) / pool_assets_total_amount;
    if (asset_pool_per > max_deposit_unbalancing_threshold) {
      if (amountsToDeposit[i] > BigInt(0)) {
        return true;
      }
    }
  }

  return false;
};
