import { createAsyncThunk } from "@reduxjs/toolkit";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import parseWalletErrorMessage from "@axvdex/utils/parseWalletErrorMessage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { postTransactions } from "@axvdex/api/user";
import { AsyncThunkConfig } from "..";
import { executeContract, fetchUserInfo, updateNativeBalance, updateTokenBalance } from "../wallet/walletThunks";
import { sendToast, setSalesBids, updateNicknames, updateUser } from "../wallet/walletSlice";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "../persist";
import { IWalletConnectedChainInfo } from "../wallet/initialState";

export const executeBidOnSaleAction = createAsyncThunk<
  { incentives: any[] },
  {
    chainId: string;
    saleAddress: string;
    address?: string;
    denom?: string;
    amount: string;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeBidOnSaleAction",
  async ({ chainId, saleAddress, address, denom, amount, i18 }, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    const state = getState().wallet;
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      if (denom) {
        res = await executeContract(
          walletInfo.connectedChains[chainId],
          saleAddress,
          {
            bid: {},
          },
          [
            {
              denom,
              amount,
            },
          ]
        );
      }
      if (address) {
        res = await executeContract(
          walletInfo.connectedChains[chainId],
          address,
          {
            send: {
              contract: saleAddress,
              amount,
              msg: Buffer.from(
                JSON.stringify({
                  bid: {},
                })
              ).toString("base64"),
            },
          },
          []
        );
      }

      txLink = `${walletInfo.connectedChains[chainId].chainState.explorerURL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Bid successful`;
    } catch (e) {
      console.log(e.message);
      msg = `Bid failed. Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletInfo.connectedChains[chainId].chainState.feeCurrencies
      )}`;
      isSuccessful = false;
    }

    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update native asset as it is used for gas fees
    if (walletInfo.connectedChains[chainId].chainState.feeCurrencies)
      for (const feeCurrency of walletInfo.connectedChains[chainId].chainState.feeCurrencies) {
        dispatch(
          updateNativeBalance({
            client: walletInfo.connectedChains[chainId].signingClient,
            denom: feeCurrency.coinMinimalDenom,
            userAddress: walletInfo.connectedChains[chainId].address,
          })
        );
      }

    // update user bids
    await dispatch(
      updateUserSaleBids({
        client: walletInfo.connectedChains[chainId].signingClient,
        walletAddress: walletInfo.connectedChains[chainId].address,
        saleAddress,
        withDetails: true,
      })
    );
    // const userInfo = await fetchUserInfo(null, walletInfo.connectedChains[chainId].address, true);
    // dispatch(updateUser(userInfo));
    // update balances of the bid asset
    if (address)
      dispatch(
        updateTokenBalance({
          client: walletInfo.connectedChains[chainId].signingClient,
          tokenAddress: address,
          userAddress: walletInfo.connectedChains[chainId].address,
        })
      );
    if (denom)
      dispatch(
        updateNativeBalance({
          client: walletInfo.connectedChains[chainId].signingClient,
          denom,
          userAddress: walletInfo.connectedChains[chainId].address,
        })
      );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    if (permits["cosmos_" + walletInfo.pubKey]) {
      await postTransactions(
        { txHash: res.transactionHash, chainId },
        {
          pubkey: walletInfo.pubKey,
          signature: permits["cosmos_" + walletInfo.pubKey],
        }
      );
      const userInfo = await fetchUserInfo(permits["cosmos_" + walletInfo.pubKey], walletInfo.pubKey);
      dispatch(updateUser(userInfo));
    }

    // a bid can have an incentive on it, so we check if there is an incentive and return it to then be displayed to the user
    const bid_event = res?.logs?.[0]?.events?.find(e => "wasm-astrovault-sale-bid" === e.type)?.attributes;

    const incentives = [];

    if (bid_event) {
      bid_event.forEach(e => {
        if ("incentive" === e.key) {
          incentives.push(e.value);

          const incentive = JSON.parse(e.value);
          // update balances of incentives
          if ("asset" === incentive[0] && state.assets[incentive[1]]?.address)
            dispatch(
              updateTokenBalance({
                client: walletInfo.connectedChains[chainId].signingClient,
                tokenAddress: incentive[1],
                userAddress: walletInfo.connectedChains[chainId].address,
              })
            );
          if ("asset" === incentive[0] && state.assets[incentive[1]]?.denom)
            dispatch(
              updateNativeBalance({
                client: walletInfo.connectedChains[chainId].signingClient,
                denom: incentive[1],
                userAddress: walletInfo.connectedChains[chainId].address,
              })
            );
        }
      });
    }

    return {
      incentives,
    };
  }
);

export const updateUserSaleBids = createAsyncThunk<
  string,
  {
    client: SigningCosmWasmClient;
    walletAddress: string;
    saleAddress: string;
    withDetails: boolean;
  },
  AsyncThunkConfig
>("wallet/updateUserSaleBids", async ({ client, walletAddress, saleAddress, withDetails }, { dispatch }) => {
  const response = await rpcClientQuerySmartContractWrapper(client, saleAddress, {
    user_bids: {
      address: walletAddress,
      with_details: withDetails,
    },
  });
  dispatch(setSalesBids({ saleAddress, saleUserBids: response }));
  return response.locked;
});

export const redeemFromVesting = createAsyncThunk<
  void,
  {
    chainId: string;
    vestingAddress: string;
    sale_id: number;
    i18: any;
    targetAssetAddr?: string;
  },
  AsyncThunkConfig
>(
  "wallet/redeemFromVesting",
  async ({ chainId, vestingAddress, sale_id, targetAssetAddr, i18 }, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      res = await executeContract(
        walletInfo.connectedChains[chainId],
        vestingAddress,
        {
          redeem_assets_from_vesting: {
            sale_ids: [sale_id],
          },
        },
        []
      );

      txLink = `${walletInfo.connectedChains[chainId].chainState.explorerURL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Redeem successful`;
    } catch (e) {
      console.log(e.message);
      msg = `Redeem failed. Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletInfo.connectedChains[chainId].chainState.feeCurrencies
      )}`;
      isSuccessful = false;
    }

    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update native asset as it is used for gas fees
    if (walletInfo.connectedChains[chainId].chainState.feeCurrencies)
      for (const feeCurrency of walletInfo.connectedChains[chainId].chainState.feeCurrencies) {
        dispatch(
          updateNativeBalance({
            client: walletInfo.connectedChains[chainId].signingClient,
            denom: feeCurrency.coinMinimalDenom,
            userAddress: walletInfo.connectedChains[chainId].address,
          })
        );
      }

    // update balances of the target asset
    if (targetAssetAddr)
      dispatch(
        updateTokenBalance({
          client: walletInfo.connectedChains[chainId].signingClient,
          tokenAddress: targetAssetAddr,
          userAddress: walletInfo.connectedChains[chainId].address,
        })
      );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
  }
);

export const updateNicknamesFromBlockchain = createAsyncThunk<
  string,
  { addresses: string[]; client: SigningCosmWasmClient; chainId: string },
  AsyncThunkConfig
>("wallet/updateNicknamesFromBlockchain", async ({ addresses, client, chainId }, { dispatch, getState }) => {
  const state = getState();
  try {
    const response = await rpcClientQuerySmartContractWrapper(
      client,
      state.wallet.contracts["nicknames_" + chainId].address,
      {
        nicknames: {
          addresses,
        },
      }
    );

    dispatch(updateNicknames(response));
    return response;
  } catch (e) {
    console.error(e);
  }
});

export const updateNickname = createAsyncThunk<
  void,
  {
    payment: any;
    nickname: string;
    target_address: string;
    i18: any;
    walletConnectedChainInfo: IWalletConnectedChainInfo;
  },
  AsyncThunkConfig
>(
  "wallet/updateNickname",
  async ({ payment, nickname, target_address, i18, walletConnectedChainInfo }, { dispatch, getState }) => {
    const state = getState();
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      res = await executeContract(walletConnectedChainInfo, payment.info.token.contract_addr, {
        send: {
          contract: state.wallet.contracts["nicknames_" + walletConnectedChainInfo.chainState.chainId].address,
          amount: payment.amount,
          msg: Buffer.from(
            JSON.stringify({
              set_nickname: {
                nickname,
                target_address,
              },
            })
          ).toString("base64"),
        },
      });

      txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Update nickname successful`;
    } catch (e) {
      console.log(e.message);
      msg = `Update nickname error. Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletConnectedChainInfo.chainState.feeCurrencies
      )}`;
      isSuccessful = false;
    }

    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update native asset as it is used for gas fees
    dispatch(
      updateNativeBalance({
        client: walletConnectedChainInfo.signingClient,
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletConnectedChainInfo.address,
      })
    );

    // update balances of the payment
    dispatch(
      updateTokenBalance({
        client: walletConnectedChainInfo.signingClient,
        tokenAddress: payment.info.token.contract_addr,
        userAddress: walletConnectedChainInfo.address,
      })
    );

    // update nickname
    dispatch(
      updateNicknamesFromBlockchain({
        client: walletConnectedChainInfo.signingClient,
        addresses: [walletConnectedChainInfo.address, target_address],
        chainId: walletConnectedChainInfo.chainState.chainId,
      })
    );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
  }
);

export const getNicknameProtection = createAsyncThunk<
  void,
  {
    walletConnectedChainInfo: IWalletConnectedChainInfo;
    payment: any;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/getNicknameProtection", async ({ payment, i18, walletConnectedChainInfo }, { dispatch, getState }) => {
  const state = getState();
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(walletConnectedChainInfo, payment.info.token.contract_addr, {
      send: {
        contract: state.wallet.contracts["nicknames_" + walletConnectedChainInfo.chainState.chainId].address,
        amount: payment.amount,
        msg: Buffer.from(
          JSON.stringify({
            get_protection: {},
          })
        ).toString("base64"),
      },
    });

    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `Get nickname protection successful`;
  } catch (e) {
    console.log(e.message);
    msg = `Get nickname protection error. Error: ${parseWalletErrorMessage(
      e.message,
      i18,
      walletConnectedChainInfo.chainState.feeCurrencies
    )}`;
    isSuccessful = false;
  }

  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update native asset as it is used for gas fees
  dispatch(
    updateNativeBalance({
      client: walletConnectedChainInfo.signingClient,
      denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
      userAddress: walletConnectedChainInfo.address,
    })
  );

  // update balances of the payment
  dispatch(
    updateTokenBalance({
      client: walletConnectedChainInfo.signingClient,
      tokenAddress: payment.info.token.contract_addr,
      userAddress: walletConnectedChainInfo.address,
    })
  );

  // update nickname
  dispatch(
    updateNicknamesFromBlockchain({
      client: walletConnectedChainInfo.signingClient,
      addresses: [walletConnectedChainInfo.address],
      chainId: walletConnectedChainInfo.chainState.chainId,
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
});
