import BigNumber from "bignumber.js";
import clsx from "clsx";
import { useEffect } from "react";
import { AlertTriangle } from "react-feather";
import { NumericFormat } from "react-number-format";
import Button from "@axvdex/components/common/Button";
import UserRewardsAmount from "@axvdex/components/user/UserRewardsAmount";

export const SwapDetailsContainer = ({
  simulationValues,
  tradeInfo,
  slippage,
  setMarketImpactSwitch,
  marketImpactSwitch,
  setExchangeRateSwitchFrom,
  exchangeRateSwitchFrom,
  i18,
  setToAddr,
}: any) => {
  useEffect(() => setToAddr(null), []);

  return (
    <ul className="tradeFormList">
      <li>
        <em className="tradeListName">
          {i18(`Minimum received (${slippage}%)`, "trade.form.minimumReceived.em", { slippage: slippage })}
        </em>
        <NumericFormat
          className="tradeListValue"
          displayType="text"
          thousandSeparator=","
          decimalSeparator="."
          decimalScale={6}
          suffix={` ${tradeInfo.toAsset.symbol} ($${
            simulationValues?.minimumReceivedInUSD >= 0.01
              ? Math.round(simulationValues?.minimumReceivedInUSD * 100) / 100
              : "0.00"
          })`}
          value={
            simulationValues && simulationValues?.minimumReceivedInUSD >= 0.000001
              ? parseFloat(simulationValues?.minimumReceived) < 0
                ? "0"
                : simulationValues?.minimumReceived
              : "0"
          }
        />
      </li>
      <li>
        <div className="inlineFlexbox">
          <em className="tradeListName">
            {marketImpactSwitch
              ? i18("Price impact", "trade.form.priceImpact.em")
              : i18("Market impact", "trade.form.marketImpact.em")}
          </em>

          {simulationValues && (
            <Button
              btnColor="gradientText"
              btnVariant="icon"
              title={
                marketImpactSwitch
                  ? i18("See market impact", "trade.form.marketImpact.btn.title")
                  : i18("See price impact", "trade.form.priceImpact.btn.title")
              }
              icon={<i className="feather icon-repeat" />}
              onClick={() => setMarketImpactSwitch(!marketImpactSwitch)}
            />
          )}
        </div>

        {marketImpactSwitch ? (
          <div className="inlineFlexbox">
            {Number(simulationValues?.priceImpactPer) > 5 && (
              <span className="tradeListValuePreIcon">
                <AlertTriangle />
              </span>
            )}
            <NumericFormat
              className={clsx(
                "tradeListValue",
                Number(simulationValues?.priceImpactPer) > 3 && "bold",
                Number(simulationValues?.priceImpactPer) > 5 && "red"
              )}
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              suffix="%"
              prefix={Number(simulationValues?.priceImpactPer) > 100 ? ">" : ""}
              value={Number(simulationValues?.priceImpactPer) > 100 ? 100 : simulationValues?.priceImpactPer}
            />
          </div>
        ) : (
          <div className="inlineFlexbox">
            {Number(simulationValues?.marketImpactPer) > 5 && (
              <span className="tradeListValuePreIcon">
                <AlertTriangle />
              </span>
            )}
            <NumericFormat
              className={clsx(
                "tradeListValue",
                Number(simulationValues?.marketImpactPer) > 3 && "bold",
                Number(simulationValues?.marketImpactPer) > 5 && "red",
                Number(simulationValues?.marketImpactPer) < 0 && "green"
              )}
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              suffix="%"
              prefix={Number(simulationValues?.marketImpactPer) > 100 ? ">" : ""}
              value={Number(simulationValues?.marketImpactPer) > 100 ? 100 : simulationValues?.marketImpactPer}
            />
          </div>
        )}
      </li>
      <li>
        <em className="tradeListName">{i18("Fees", "trade.form.fees.em")}</em>
        <div className="inlineFlexbox">
          {parseFloat(simulationValues?.feePer) > 5 && (
            <span className="tradeListValuePreIcon">
              <AlertTriangle />
            </span>
          )}
          <NumericFormat
            className={clsx(
              "tradeListValue",
              parseFloat(simulationValues?.feePer) > 3 && "bold",
              parseFloat(simulationValues?.feePer) > 5 && "red"
            )}
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            suffix={`% ($${simulationValues ? Math.round(simulationValues?.feeInUSD * 100) / 100 || 0 : 0})`}
            value={
              simulationValues ? (parseFloat(simulationValues?.feePer) > 100 ? 100 : simulationValues?.feePer) : "0"
            }
          />
        </div>
      </li>
      <li>
        <div className="inlineFlexbox">
          <em className="tradeListName">{i18("Exchange rate", "trade.form.exchangeRate.em")}</em>
          {simulationValues && (
            <Button
              btnColor="gradientText"
              btnVariant="icon"
              title={i18("Reverse", "trade.form.exchangeRate.btn.title")}
              icon={<i className="feather icon-repeat" />}
              onClick={() => setExchangeRateSwitchFrom(!exchangeRateSwitchFrom)}
            />
          )}
        </div>

        {simulationValues ? (
          <span className="inlineFlexbox">
            <span className="tradeListValue">
              {exchangeRateSwitchFrom
                ? `1 ${tradeInfo.fromAsset.symbol} = ${
                    "-" === simulationValues?.offerToAskRatio
                      ? "-"
                      : BigNumber(simulationValues?.offerToAskRatio).decimalPlaces(6, BigNumber.ROUND_FLOOR) || "-"
                  } ${tradeInfo.toAsset.symbol}`
                : `1 ${tradeInfo.toAsset.symbol} = ${
                    "-" === simulationValues?.askToOfferRatio || Number(simulationValues?.marketImpactPer) > 100
                      ? "-"
                      : BigNumber(simulationValues?.askToOfferRatio).decimalPlaces(6, BigNumber.ROUND_FLOOR) || "-"
                  } ${tradeInfo.fromAsset.symbol}`}
            </span>
          </span>
        ) : (
          <span className="tradeListValue">-</span>
        )}

        {/* <NumericFormat
  className="tradeListValue"
  displayType="text"
  thousandSeparator=","
  decimalSeparator="."
  decimalScale={3}
  suffix=""
  value={simulationValues?.offerToAskRatio || "-"}
  /> */}
      </li>

      <li>
        <em className="tradeListName">{i18("GRVT8 Rewards", "trade.grvt8rewards")}</em>
        <UserRewardsAmount
          rewardsValueProps={{
            value: Math.round(Number(simulationValues?.expectedCashbackAmount) * 1000000) / 1000000 || 0,
          }}
        />
      </li>
    </ul>
  );
};
