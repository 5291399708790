import { createContext, ReactNode, useContext, useState } from "react";

interface ModalState {
  modalType: string | null;
  modalProps: object;
  isOpen: boolean;
}

interface GlobalModalContextValue {
  modal: ModalState;
  showModal: (modalType: string, modalProps?: object) => void;
  hideModal: () => void;
}

const GlobalModalContext = createContext<GlobalModalContextValue>({
  modal: { modalType: null, modalProps: {}, isOpen: false },
  showModal: () => {},
  hideModal: () => {},
});

export const useGlobalModalContext = () => useContext(GlobalModalContext);

interface GlobalModalProviderProps {
  children: ReactNode;
}

export const GlobalModalProvider = ({ children }: GlobalModalProviderProps) => {
  const [modal, setModal] = useState<ModalState>({
    modalType: null,
    modalProps: {},
    isOpen: false,
  });

  const showModal = (modalType: string, modalProps) => {
    //if location is legal page then enable scroll
    let isOpen;
    if ("/legal" === window.location.pathname) {
      if ("undefined" === typeof modalProps) {
        modalProps = {
          preventScroll: false,
          bodyOpenClassName: null,
        };
      } else {
        modalProps.preventScroll = false;
        modalProps.bodyOpenClassName = null;
      }
      isOpen = false;
    } else {
      isOpen = true;
    }

    setModal({ modalType, modalProps, isOpen });
  };

  const hideModal = () => {
    setModal({ modalType: null, modalProps: {}, isOpen: false });
  };

  return <GlobalModalContext.Provider value={{ modal, showModal, hideModal }}>{children}</GlobalModalContext.Provider>;
};
