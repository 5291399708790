export const getChainCosmosSDKVersion = async (restURL: string): Promise<number> => {
  const nodeInfoResponse = await fetch(`${restURL}/cosmos/base/tendermint/v1beta1/node_info`);
  const nodeInfoJSON = await nodeInfoResponse.json();

  const majorVersion = nodeInfoJSON?.application_version?.cosmos_sdk_version.split("v")[1].split(".")[0];
  const subVersions = nodeInfoJSON?.application_version?.cosmos_sdk_version.split("v")[1].split(".")[1];

  return majorVersion && subVersions ? parseFloat(majorVersion + "." + subVersions) : 0.47; // if we cant get it, default to lower version than 0.5
};

export const getIbcPacketInfoFromEventResponse = (events?: any, secretArrayLog?: any): any => {
  if (events) {
    const event = events.find(event => "send_packet" === event.type);
    if (!event) throw Error("No ibc packet found on tx...");

    let packet_sequence = event.attributes.find(attribute => "packet_sequence" === attribute.key)?.value;
    let packet_src_channel = event.attributes.find(attribute => "packet_src_channel" === attribute.key)?.value;
    let packet_dst_channel = event.attributes.find(attribute => "packet_dst_channel" === attribute.key)?.value;

    if (!packet_sequence || !packet_src_channel || !packet_dst_channel) {
      // some chains randomly have keys and values as base64 encoded...........
      // so if there is no checked we check for the base64 encoded version
      const packet_sequenceAttr = event.attributes.find(
        attribute => attribute.key === Buffer.from("packet_sequence", "utf-8").toString("base64")
      );
      const packet_src_channelAttr = event.attributes.find(
        attribute => attribute.key === Buffer.from("packet_src_channel", "utf-8").toString("base64")
      );
      const packet_dst_channelAttr = event.attributes.find(
        attribute => attribute.key === Buffer.from("packet_dst_channel", "utf-8").toString("base64")
      );

      if (packet_sequenceAttr) {
        packet_sequence = Buffer.from(packet_sequenceAttr.value, "base64").toString("utf-8");
      }
      if (packet_src_channelAttr) {
        packet_src_channel = Buffer.from(packet_src_channelAttr.value, "base64").toString("utf-8");
      }
      if (packet_dst_channelAttr) {
        packet_dst_channel = Buffer.from(packet_dst_channelAttr.value, "base64").toString("utf-8");
      }
    }

    return {
      packet_sequence,
      packet_src_channel,
      packet_dst_channel,
    };
  }
  return {
    packet_sequence: secretArrayLog.find(attribute => "packet_sequence" === attribute.key).value,
    packet_src_channel: secretArrayLog.find(attribute => "packet_src_channel" === attribute.key).value,
    packet_dst_channel: secretArrayLog.find(attribute => "packet_dst_channel" === attribute.key).value,
  };
};

export const ibcTransferPacketCheck = async (
  packet: {
    packet_sequence: string;
    packet_src_channel: string;
    packet_dst_channel: string;
  },
  srcChain: {
    restURL: string;
  },
  dstChain: {
    restURL: string;
  }
) => {
  let isSuccessful = false;
  let msg = "";
  let timeoutTx = null;
  let receiveTx = null;
  let ackTx = null;

  // query both chains to check their sdk version
  const srcSDKVersion = await getChainCosmosSDKVersion(srcChain.restURL);
  const dstSDKVersion = await getChainCosmosSDKVersion(dstChain.restURL);

  // eslint-disable-next-line promise/avoid-new
  await new Promise(resolve => setTimeout(resolve, 10000));

  // eslint-disable-next-line no-constant-condition
  while (1) {
    // Due to changes on cosmos.sdk 0.50 the events query param was deprecated and only query is needed
    const timeoutResponse = await fetch(
      srcSDKVersion >= 0.5
        ? `${srcChain.restURL}/cosmos/tx/v1beta1/txs?query=timeout_packet.packet_src_channel='${packet.packet_src_channel}'%20AND%20timeout_packet.packet_sequence='${packet.packet_sequence}'`
        : `${srcChain.restURL}/cosmos/tx/v1beta1/txs?events=timeout_packet.packet_src_channel='${packet.packet_src_channel}'&events=timeout_packet.packet_sequence='${packet.packet_sequence}'`
    );
    const timeoutResponseJson = await timeoutResponse.json();

    if (timeoutResponseJson.txs.length > 0) {
      isSuccessful = false;
      timeoutTx = timeoutResponseJson;
      msg = "IBC transfer timedout!";
      break;
    }

    const recvResponse = await fetch(
      dstSDKVersion >= 0.5
        ? `${dstChain.restURL}/cosmos/tx/v1beta1/txs?query=recv_packet.packet_dst_channel='${packet.packet_dst_channel}'%20AND%20recv_packet.packet_sequence='${packet.packet_sequence}'`
        : `${dstChain.restURL}/cosmos/tx/v1beta1/txs?events=recv_packet.packet_dst_channel='${packet.packet_dst_channel}'&events=recv_packet.packet_sequence='${packet.packet_sequence}'`
    );
    const res = await recvResponse.json();
    if (res.txs.length > 0) {
      // there is a possibility that the packet was received but it errored out executing, we check this by looking for the write acknowledgement
      let packet_ack = res.tx_responses[0]?.events
        ?.find((e: any) => "write_acknowledgement" === e.type)
        ?.attributes?.find((attr: any) => "packet_ack" === attr.key);

      // some chains randomly have keys and values as base64 encoded...........
      // so if there is no packet_ack we check for the base64 encoded version
      if (!packet_ack) {
        const packet_ack_base64 = res.tx_responses[0]?.events
          ?.find((e: any) => "write_acknowledgement" === e.type)
          ?.attributes?.find((attr: any) => attr.key === Buffer.from("packet_ack", "utf-8").toString("base64"));

        if (packet_ack_base64) {
          packet_ack = {
            key: "packet_ack",
            value: Buffer.from(packet_ack_base64.value, "base64").toString("utf-8"),
          };
        }
      }

      if (packet_ack) {
        const ackResponse = await fetch(
          srcSDKVersion >= 0.5
            ? `${srcChain.restURL}/cosmos/tx/v1beta1/txs?query=acknowledge_packet.packet_src_channel='${packet.packet_src_channel}'%20AND%20acknowledge_packet.packet_sequence='${packet.packet_sequence}'`
            : `${srcChain.restURL}/cosmos/tx/v1beta1/txs?events=acknowledge_packet.packet_src_channel='${packet.packet_src_channel}'&events=acknowledge_packet.packet_sequence='${packet.packet_sequence}'`
        );
        const ackResponseJSON: any = await ackResponse.json();
        if (ackResponseJSON.txs.length > 0) {
          if (packet_ack.value.includes("error")) {
            isSuccessful = false;
            receiveTx = res;
            ackTx = ackResponseJSON;
            msg = "IBC transfer errored out on destination chain!";
            break;
          } else {
            isSuccessful = true;
            receiveTx = res;
            ackTx = ackResponseJSON;
            msg = "IBC transfer successful!";
            break;
          }
        }
      }
    }

    // eslint-disable-next-line promise/avoid-new
    await new Promise(resolve => setTimeout(resolve, 5000));
  }

  return {
    isSuccessful,
    msg,
    timeoutTx,
    receiveTx,
    ackTx,
  };
};
