import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Trans } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { AlertCircle, ArrowDown, Eye, X } from "react-feather";
import BigNumber from "bignumber.js";
import Button from "@axvdex/components/common/Button";
import CustomModal from "@axvdex/components/common/CustomModal";
import { useAppSelector } from "@axvdex/state";
import { selectAssets, selectChains, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { fetchWrapper } from "@axvdex/api";
import imgSanitize from "@axvdex/utils/imgSanitize";

import useLanguage from "@axvdex/hooks/useLanguage";
import { maxDecimalsToDisplay } from "@axvdex/utils/formatNumber";
import { AssetChainLogo } from "@axvdex/utils/chains";
import { ReactComponent as IcnSwap } from "../../assets/icons/icn-exchange.svg";
import { ReactComponent as IcnGravitate } from "../../assets/tokens/logo-grvt8.svg";
import styles from "../../styles/TradeVisualiserModal.module.scss";

interface TradeVisualiserProps {
  source: "trade" | "myhistory";
  tradeSequenceRaw: any;
  gasFees?: string;
  historicalTimestamp?: string;
}

function TradeVisualiserModal({ source, tradeSequenceRaw, gasFees, historicalTimestamp }: TradeVisualiserProps) {
  const { i18 } = useLanguage();

  if (!tradeSequenceRaw || 0 === tradeSequenceRaw.length) return null;
  const getFeeAsset = () =>
    walletInfo &&
    Object.keys(walletInfo.connectedChains).length > 0 &&
    walletInfo.connectedChains[assets[tradeSequenceRaw[0].fromAssetID].contextChainId] &&
    assets[
      walletInfo.connectedChains[assets[tradeSequenceRaw[0].fromAssetID].contextChainId].chainState.feeCurrencies[0]
        .coinMinimalDenom
    ];

  const walletInfo = useAppSelector(selectWalletInfo);
  const assets = useAppSelector(selectAssets);
  const chains = useAppSelector(selectChains);
  const coinDenom = getFeeAsset()?.symbol;
  const [historicalPrices, setHistoricalPrices] = useState(null);
  const [data, setData] = useState(null);

  const [visualiserModalIsOpen, setVisualiserModalIsOpen] = useState(false);
  const handleToggleVisualiserModal = useCallback(() => {
    setVisualiserModalIsOpen(visualiserModalIsOpen => !visualiserModalIsOpen);
  }, []);
  const handleCloseVisualiserModal = () => setVisualiserModalIsOpen(false);

  useEffect(() => {
    if (visualiserModalIsOpen && historicalTimestamp) {
      getHistoricalPrices();
    } else {
      setHistoricalPrices(null);
    }
  }, [visualiserModalIsOpen]);

  useEffect(() => {
    if (visualiserModalIsOpen && tradeSequenceRaw) {
      parseShowValues();
    }
  }, [visualiserModalIsOpen, historicalPrices]);

  const parseShowValues = () => {
    const res = tradeSequenceRaw.map((hop, _) => {
      const fromAssetPrice = getAssetPrice(hop.fromAssetID);
      const maxDecimalsUI = maxDecimalsToDisplay(
        BigNumber(hop.fromAssetAmount).div(Math.pow(10, assets[hop.fromAssetID].decimals))
      );
      return {
        tokenName: assets[hop.fromAssetID].symbol,
        tokenValue: parseFloat(
          BigNumber(hop.fromAssetAmount)
            .div(Math.pow(10, assets[hop.fromAssetID].decimals))
            .decimalPlaces(maxDecimalsUI, BigNumber.ROUND_FLOOR)
            .toString(10)
        ),
        tokenValueConversion: fromAssetPrice
          ? parseFloat(
              BigNumber(hop.fromAssetAmount)
                .div(Math.pow(10, assets[hop.fromAssetID].decimals))
                .times(fromAssetPrice)
                .decimalPlaces(2, BigNumber.ROUND_FLOOR)
                .toString(10)
            )
          : null,
        trades: [
          {
            tradeType: hop.actionType,
            tradeTypeStatus: hop.ibcError ? "failed" : "success",
            ibcError: hop.ibcError,
            tokenList:
              hop.actionType !== "mint" && hop.poolAssetIDs
                ? hop.poolAssetIDs.map(poolAssetID => assets[poolAssetID].symbol)
                : null,
            fees: parseFloat(
              BigNumber(hop.toAssetFeesAmount)
                .div(Math.pow(10, assets[hop.toAssetID].decimals))
                .decimalPlaces(2, BigNumber.ROUND_FLOOR)
                .toString(10)
            ),
            fromAssetChain: hop.fromAssetChain,
            toAssetChain: hop.toAssetChain,
            gravitate: parseFloat(BigNumber(hop.gvt8Minted).div(Math.pow(10, 6)).decimalPlaces(2).toString(10)),
          },
        ],
      };
    });

    //add last row with output result
    const lastHop = tradeSequenceRaw[tradeSequenceRaw.length - 1];
    const toAssetPrice = getAssetPrice(lastHop.toAssetID);
    const maxDecimalsUI = maxDecimalsToDisplay(
      BigNumber(BigNumber(lastHop.toAssetAmount).div(Math.pow(10, assets[lastHop.toAssetID].decimals)))
    );
    res.push({
      tokenName: assets[lastHop.toAssetID].symbol,
      tokenValue: parseFloat(
        BigNumber(lastHop.toAssetAmount)
          .div(Math.pow(10, assets[lastHop.toAssetID].decimals))
          .decimalPlaces(maxDecimalsUI, BigNumber.ROUND_FLOOR)
          .toString(10)
      ),
      tokenValueConversion: toAssetPrice
        ? parseFloat(
            BigNumber(lastHop.toAssetAmount)
              .div(Math.pow(10, assets[lastHop.toAssetID].decimals))
              .times(toAssetPrice)
              .decimalPlaces(2, BigNumber.ROUND_FLOOR)
              .toString(10)
          )
        : null,
      trades: [],
    });
    setData(res);
  };

  const getHistoricalPrices = async () => {
    let chainIds = assets[tradeSequenceRaw[0].fromAssetID].contextChainId;

    if (assets[tradeSequenceRaw[tradeSequenceRaw.length - 1].toAssetID].contextChainId !== chainIds) {
      chainIds += "," + assets[tradeSequenceRaw[tradeSequenceRaw.length - 1].toAssetID].contextChainId;
    }

    const historicalPrice = await fetchWrapper(
      "/asset-analytics?chainIds=" + chainIds + "&closest_to=" + new Date(historicalTimestamp).getTime()
    );
    setHistoricalPrices(historicalPrice.data);
  };

  const getAssetPrice = id => {
    let assetPrice = null;
    if (historicalTimestamp) {
      // if it's coming from history
      if (historicalPrices) {
        // if we already have price data ready
        const assetHistorical = historicalPrices.find(assetPrice => assetPrice.id === id);
        if (assetHistorical) assetPrice = assetHistorical.price;
      }
    } else {
      // if not coming from history it comes directly from asset current price
      assetPrice = assets[id]?.price || 0;
    }

    return assetPrice;
  };

  //{visualiserModalIsOpen && tradeSequenceRaw.length > 0 && (
  return (
    <>
      {"myhistory" === source && (
        <Button
          btnColor="purple"
          text={i18("Visualiser", "visualiser.btn.text")}
          icon={<Eye />}
          iconPlacement="left"
          onClick={handleToggleVisualiserModal}
        />
      )}

      {"trade" === source && <Button btnColor="gradientText" text="Visualiser" onClick={handleToggleVisualiserModal} />}

      <CustomModal
        isOpen={visualiserModalIsOpen}
        onClose={handleCloseVisualiserModal}
        extraClassName={clsx(styles.tradeVisualiserModal, "tradeVisualiserModal")}
      >
        <section className="sectionModalHeader withGradientBorderBottom">
          <h1 className="h2 visuallyHidden">{i18("Visualiser", "visualiser.title")}</h1>

          {data && (
            <>
              <div className="inlineFlexbox visualiserHeader">
                <div className="inlineFlexbox">
                  {data && (
                    <>
                      <NumericFormat
                        className="visualiserHeaderTokenValue"
                        displayType="text"
                        value={data[0].tokenValue}
                        suffix={` ${data[0].tokenName}`}
                      />
                      <div className="visualiserHeaderToken">
                        <img src={imgSanitize(data[0].tokenName)} alt={`${data[0].tokenName}`} />
                      </div>
                    </>
                  )}
                </div>

                <span className="visualiserHeaderSwapIconWrapper">
                  <IcnSwap />
                </span>

                <div className="inlineFlexbox">
                  <div className="visualiserHeaderToken">
                    <img
                      src={imgSanitize(data[data.length - 1].tokenName)}
                      alt={`${data[data.length - 1].tokenName}`}
                    />
                  </div>

                  <NumericFormat
                    className="visualiserHeaderTokenValue"
                    displayType="text"
                    value={data[data.length - 1].tokenValue}
                    suffix={` ${data[data.length - 1].tokenName}`}
                  />
                </div>
              </div>
              <br />
              <div style={{ paddingTop: ".5em" }}>
                <span>
                  {tradeSequenceRaw.find((hop: any) => "crosschainHop" === hop.actionType)
                    ? "Crosschain"
                    : chains[assets[tradeSequenceRaw[0].fromAssetID].contextChainId].displayName}
                </span>
              </div>
            </>
          )}
        </section>

        <section className="historicalNote">
          {historicalPrices !== null && historicalPrices.length > 0 && (
            <small>
              <Trans
                i18nKey="visualiser.historicalNote"
                date={new Date(historicalPrices[0].timestamp).toLocaleString().replace(",", " at ")}
              >
                Note: Dollar amounts from approx.{" "}
                {new Date(historicalPrices[0].timestamp).toLocaleString().replace(",", " at ")}
              </Trans>
            </small>
          )}
        </section>

        <section className="sectionModalList">
          {data ? (
            <ul className="visualiserList">
              {data.map((token, idx) => {
                return (
                  <li className="visualiserListItem" key={idx}>
                    <div className="visualiserListItemWrapper flexbox">
                      <div className="inlineFlexbox">
                        <span className="visualiserListItemTokenImage">
                          <img src={imgSanitize(token.tokenName)} alt={token.tokenName} />
                        </span>

                        <NumericFormat
                          className="visualiserListItemToken"
                          displayType="text"
                          value={token.tokenValue}
                          suffix={` ${token.tokenName}`}
                        />
                      </div>

                      <NumericFormat
                        className="visualiserListItemValueConversion"
                        displayType="text"
                        value={token.tokenValueConversion}
                        prefix={"$"}
                      />
                    </div>

                    {token.trades && (
                      <ul className="visualiserList operations">
                        {token.trades.map((action, actionIdx) => {
                          const nextToken =
                            action.tradeType !== "mint" && action.tradeTypeStatus !== "failed"
                              ? data[data.length >= idx + 1 ? idx + 1 : idx].tokenName
                              : null;
                          return (
                            <li
                              className={clsx("visualiserListItem", action.tradeType, action.tradeTypeStatus)}
                              key={actionIdx}
                            >
                              {"mint" === action.tradeType ? (
                                <div className="visualiserListItemAction inlineFlexbox">
                                  <span className="visualiserListItemActionIcon">
                                    <ArrowDown />
                                  </span>
                                  <span className="visualiserListItemActionType">
                                    {i18("Mint", "visualiser.visualiserListItemActionType.mint")}
                                  </span>
                                </div>
                              ) : "crosschainHop" === action.tradeType ? (
                                <>
                                  <div className="visualiserListItemAction flexbox">
                                    <span className="visualiserListItemActionIcon">
                                      {"failed" === action.tradeTypeStatus ? (
                                        <X style={{ color: "red" }} />
                                      ) : (
                                        <ArrowDown />
                                      )}
                                    </span>

                                    <div className="visualiserListItemActionType">
                                      <em>{i18("Crosschain", "visualiser.visualiserListItemActionType.crosschain")}</em>
                                      <small className="">{`from ${action.fromAssetChain} to ${action.toAssetChain}`}</small>
                                      {action.ibcError && (
                                        <small
                                          style={{ marginTop: ".5em" }}
                                          className=""
                                        >{`Refund delivered on ${action.fromAssetChain}`}</small>
                                      )}
                                    </div>
                                  </div>

                                  <small className="inlineFlexbox">
                                    {"failed" === action.tradeTypeStatus ? (
                                      <span className="inlineFlexbox failedMessage">
                                        {i18("Failed", "visualiser.failedMessage")}
                                        <AlertCircle />
                                      </span>
                                    ) : (
                                      <span className="visualiserListItemActionFees">
                                        <span className="inlineFlexbox arch">
                                          <NumericFormat
                                            className="fee"
                                            displayType="text"
                                            value={action.fees}
                                            prefix={i18("Fees: ", "visualiser.visualiserListItemActionFees.fees")}
                                          />
                                          <span className="feesIconWrapper" title={nextToken} aria-label={nextToken}>
                                            <img src={imgSanitize(nextToken)} alt={nextToken} className="xs" />
                                          </span>
                                        </span>
                                      </span>
                                    )}
                                  </small>
                                </>
                              ) : (
                                <>
                                  <div className="visualiserListItemAction flexbox">
                                    <span className="visualiserListItemActionIcon">
                                      <IcnSwap />
                                    </span>
                                    <ul className="tokenGridList">
                                      {action.tokenList.map((item, idx) => (
                                        <li key={idx}>
                                          <span title={item} aria-label={item}>
                                            <img src={imgSanitize(item)} alt={item} />
                                          </span>
                                        </li>
                                      ))}
                                    </ul>

                                    <div className="visualiserListItemActionType">
                                      <em>{i18("Swap", "visualiser.visualiserListItemActionType.swap")}</em>
                                      <small className="">{action.tradeType}</small>
                                    </div>
                                  </div>

                                  <small className="inlineFlexbox">
                                    {"failed" === action.tradeTypeStatus ? (
                                      <span className="inlineFlexbox failedMessage">
                                        {i18("Failed", "visualiser.failedMessage")}
                                        <AlertCircle />
                                      </span>
                                    ) : (
                                      <span className="visualiserListItemActionFees">
                                        <span className="inlineFlexbox arch">
                                          <NumericFormat
                                            className="fee"
                                            displayType="text"
                                            value={action.fees}
                                            prefix={i18("Fees: ", "visualiser.visualiserListItemActionFees.fees")}
                                          />
                                          <span className="feesIconWrapper" title={nextToken} aria-label={nextToken}>
                                            <img src={imgSanitize(nextToken)} alt={nextToken} className="xs" />
                                          </span>
                                        </span>

                                        <span className="inlineFlexbox gravitate">
                                          <NumericFormat
                                            displayType="text"
                                            value={action.gravitate}
                                            prefix={i18("GRVT8: ", "visualiser.visualiserListItemActionFees.grvt8")}
                                          />
                                          <span className="feesIconWrapper">
                                            <IcnGravitate />
                                          </span>
                                        </span>
                                      </span>
                                    )}
                                  </small>
                                </>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>{i18("There are no trades yet.", "visualiser.noTrades")}</p>
          )}
        </section>

        {tradeSequenceRaw && tradeSequenceRaw.length > 0 && (
          <section className="sectionModalFooter">
            <div className="tradeVisualiserTotalGrid">
              <div className="tradeVisualiserTotalGridWrapper">
                <div className="tradeVisualiserTotalGriditem">
                  <>
                    <small>
                      {historicalTimestamp
                        ? i18("Network Fee", "visualiser.networkFee")
                        : i18("Estimated Network Fee", "visualiser.estimatedNetworkFee")}
                    </small>

                    <span className={"inlineFlexbox network"}>
                      {gasFees && getFeeAsset() ? (
                        <>
                          {
                            <NumericFormat
                              displayType="text"
                              value={BigNumber(gasFees)
                                .div(Math.pow(10, getFeeAsset().decimals))
                                .decimalPlaces(4, BigNumber.ROUND_FLOOR)
                                .toString(10)}
                            />
                          }
                          {
                            <span className="feesIconWrapper" title={`${coinDenom}`} aria-label={`${coinDenom}`}>
                              <AssetChainLogo asset={getFeeAsset()} style={{ marginLeft: "1.5em" }} />
                            </span>
                          }
                        </>
                      ) : (
                        i18("Chain not connected", "visualiser.unavailable")
                      )}
                    </span>
                    <span className={"inlineFlexbox network"}>
                      {gasFees && getFeeAsset() ? (
                        <small>
                          <NumericFormat
                            displayType="text"
                            prefix="($"
                            suffix=")"
                            value={BigNumber(gasFees)
                              .div(Math.pow(10, getFeeAsset().decimals))
                              .times(getAssetPrice(getFeeAsset().id))
                              .decimalPlaces(4, BigNumber.ROUND_FLOOR)
                              .toString(10)}
                          />
                        </small>
                      ) : (
                        i18("Chain not connected", "visualiser.unavailable")
                      )}
                    </span>
                  </>
                </div>
              </div>

              <div className="tradeVisualiserTotalGridWrapper">
                <div className="tradeVisualiserTotalGriditem">
                  <small>{i18("Total Trade Fee", "visualiser.totalTradeFee")}</small>
                  <NumericFormat
                    displayType="text"
                    value={tradeSequenceRaw
                      .reduce((total, element) => {
                        const toAssetPrice = getAssetPrice(element.toAssetID);
                        return (
                          total +
                          (toAssetPrice
                            ? parseFloat(
                                BigNumber(element.toAssetFeesAmount)
                                  .div(Math.pow(10, assets[element.toAssetID].decimals))
                                  .times(toAssetPrice)
                                  .decimalPlaces(4, BigNumber.ROUND_FLOOR)
                                  .toString()
                              )
                            : 0)
                        );
                      }, 0)
                      .toFixed(4)}
                    prefix={"$"}
                  />
                </div>

                <div className="tradeVisualiserTotalGriditem">
                  <small className="inlineFlexbox">{i18("Total GRVT8", "visualiser.totalGrvt8")}</small>

                  <span className={"inlineFlexbox gravitate fees"}>
                    <NumericFormat
                      displayType="text"
                      value={tradeSequenceRaw
                        .reduce(
                          (total, element) =>
                            total +
                            parseFloat(
                              BigNumber(element.gvt8Minted || 0)
                                .div(Math.pow(10, 6))
                                .decimalPlaces(4, BigNumber.ROUND_FLOOR)
                                .toString(10)
                            ),
                          0
                        )
                        .toFixed(4)}
                      prefix={""}
                    />
                    <span className="feesIconWrapper">
                      <IcnGravitate />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </section>
        )}
      </CustomModal>
    </>
  );
}

export default TradeVisualiserModal;
