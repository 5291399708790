import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BigNumber from "bignumber.js";
import { gsap } from "gsap";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import {
  selectAssetBalances,
  selectAssets,
  // selectChains,
  // selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { executeBidOnSaleAction } from "@axvdex/state/outbid/saleThunks";
import { updateNativeBalance, updateTokenBalance } from "@axvdex/state/wallet/walletThunks";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";
import CustomNumericInput from "../form-element/CustomNumericInput";
import UserBalance from "../user/UserBalance";
import Button from "../common/Button";
import { ReactComponent as IcnTwitter } from "../../assets/icons/icn-twitterx.svg";
import SaleIncentiveCongratsModal from "./SaleIncentiveCongratsModal";

const SaleBidSection = ({ outbidSale, selectedSale, salesBids, onWhitelist, setOnWhitelist }: any) => {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const { control } = useForm();
  const walletInfo = useAppSelector(selectWalletInfo);
  // const user = useAppSelector(selectUser);
  const assets = useAppSelector(selectAssets);
  const assetBalances = useAppSelector(selectAssetBalances);
  // const chains = useAppSelector(selectChains);

  const [selectedBidAsset, setSelectedBidAsset] = useState<ListItemsProps | null>(null);
  const [bidInputAmount, setBidInputAmount] = useState<string>("");
  const [minBidAmount, setMinBidAmount] = useState<string>(null);
  // 1/2 comment to open by default the congrats model for testing
  const [saleIncentiveCongratsModalOpen, setSaleIncentiveCongratsModalOpen] = useState({ open: false, incentives: [] });
  // 2/2 uncomment to open by default the congrats model for testing,
  // IGNORE ERRORS if browser routes to deeplink, and use parent URL to avoid errors
  // const [saleIncentiveCongratsModalOpen, setSaleIncentiveCongratsModalOpen] = useState({
  //   open: true,
  //   incentives: [["asset", "archway1qrvfsfdyw79g8zxepkafrsj53xvz2v072mk6kqe3yj82n8ahznpssuzzc5", "23000000"]],
  // });
  const walletChainContext = walletInfo?.connectedChains[outbidSale.contextChainId];

  // keep track of minBidAmount, so it can update the state when it receives the websocket update
  useEffect(() => {
    if (selectedSale && minBidAmount !== outbidSale.salesState[selectedSale.value].config.sale_settings.min_ticket) {
      // if the input has the same value as the pre min bid amount, update to new one
      const minTicketsHumanFormat = BigNumber(minBidAmount).div(Math.pow(10, 6)).decimalPlaces(0).toString(10);
      if (minTicketsHumanFormat === bidInputAmount) {
        setBidInputAmount(
          BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.min_ticket)
            .div(Math.pow(10, 6))
            .decimalPlaces(0)
            .toString(10)
        );
      }
      setMinBidAmount(outbidSale.salesState[selectedSale.value].config.sale_settings.min_ticket);
    }

    const remainingTickets = BigNumber(
      outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
    ).minus(outbidSale.salesState[selectedSale.value].sale_state.state.tickets_count);

    if (BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.min_ticket).gte(remainingTickets)) {
      setBidInputAmount(BigNumber(remainingTickets).div(Math.pow(10, 6)).decimalPlaces(0).toString(10));
    }
  }, [outbidSale, selectedSale]);

  useEffect(() => {
    if (assets && selectedSale && !selectedBidAsset && minBidAmount) {
      const bidAsset = outbidSale.salesState[selectedSale.value].config.bid_assets[0];
      if (!bidAsset) return;
      const assetID = bidAsset.info.native_token?.denom || bidAsset.info.token?.contract_addr;
      if (assets[assetID])
        setSelectedBidAsset({
          value: assets[assetID].symbol,
          label: assets[assetID].symbol,
          id: assets[assetID].id,
          optionPrefix: <img src={imgSanitize(assets[assetID].symbol)} alt={""} />,
        });
      setBidInputAmount(BigNumber(minBidAmount).div(Math.pow(10, 6)).decimalPlaces(0).toString(10));
    }
    // initialize get balances when everything is ready
    if (assets && selectedSale) getBidAssetBalances();
  }, [selectedSale, assets, assetBalances, minBidAmount]);

  const getBidAssetBalances = async () => {
    const requestsBalances = [];
    for (const bidAsset of outbidSale.salesState[selectedSale.value].config.bid_assets) {
      const id = bidAsset.info.token?.contract_addr || bidAsset.info.native_token?.denom;
      if (!assets[id]) continue;
      if (!assetBalances[id]) {
        if (!assets[id].isNative && walletChainContext) {
          requestsBalances.push(
            dispatch(
              updateTokenBalance({
                client: walletChainContext.signingClient,
                tokenAddress: assets[id].address,
                userAddress: walletChainContext.address,
              })
            )
          );
        } else if (walletChainContext) {
          requestsBalances.push(
            dispatch(
              updateNativeBalance({
                client: walletChainContext.signingClient,
                denom: assets[id].denom,
                userAddress: walletChainContext.address,
              })
            )
          );
        }
      } else {
        requestsBalances.push(Promise.resolve());
      }
    }

    await Promise.all(requestsBalances);
  };

  const convertAssetAmountToTicketNumber = (id: string, inputAmount: string) => {
    const assetSelected = outbidSale.salesState[selectedSale.value].config.bid_assets.find(
      (bidAsset: any) => bidAsset.info.native_token?.denom === id || bidAsset.info.token?.contract_addr === id
    );

    return BigNumber(inputAmount)
      .times(assetSelected.asset_to_ticket_ratio)
      .decimalPlaces(4, BigNumber.ROUND_FLOOR)
      .toString(10);
  };

  const convertTicketNumberToAssetAmount = (id: string, ticketAmount: string) => {
    const assetSelected = outbidSale.salesState[selectedSale.value].config.bid_assets.find(
      (bidAsset: any) => bidAsset.info.native_token?.denom === id || bidAsset.info.token?.contract_addr === id
    );

    if (!assetSelected) return null;
    // due to decimal places problems, if the ratio has decimal places and the ticket amount is equal to min bid amount,
    // add 0.001 to the result, so it has excess tokens and the contract handle the refund of excess
    if (
      "0" !== ticketAmount &&
      assetSelected.asset_to_ticket_ratio % 1 > 0 &&
      BigNumber(ticketAmount).lte(BigNumber(minBidAmount).div(Math.pow(10, 6)).decimalPlaces(0).toNumber())
    ) {
      return BigNumber(ticketAmount)
        .div(assetSelected.asset_to_ticket_ratio)
        .plus(0.001)
        .decimalPlaces(6, BigNumber.ROUND_CEIL)
        .toString(10);
    }
    return BigNumber(ticketAmount)
      .div(assetSelected.asset_to_ticket_ratio)
      .decimalPlaces(
        6,
        ticketAmount === BigNumber(minBidAmount).div(Math.pow(10, 6)).decimalPlaces(0).toString(10)
          ? BigNumber.ROUND_CEIL
          : BigNumber.ROUND_FLOOR
      )
      .toString(10);
  };

  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const handleUserBid = async () => {
    setIsTransactionLoading(true);

    try {
      const amount = BigNumber(
        convertTicketNumberToAssetAmount(
          selectedBidAsset.id,
          "" !== bidInputAmount ? bidInputAmount.replaceAll(",", "") : "0"
        )
      )
        .times(Math.pow(10, assets[selectedBidAsset.id].decimals))
        .decimalPlaces(0, BigNumber.ROUND_UP)
        .toString(10);

      const res = await dispatch(
        executeBidOnSaleAction({
          chainId: outbidSale.contextChainId,
          saleAddress: selectedSale.value,
          address: assets[selectedBidAsset.id].address,
          denom: assets[selectedBidAsset.id].denom,
          amount,
          i18,
        })
      );

      console.log(res);
      if ((res?.payload as any).incentives?.length > 0) {
        const incentives = (res?.payload as any).incentives.map(incentive => JSON.parse(incentive));
        // open congratulations modal with incentives won
        setSaleIncentiveCongratsModalOpen({
          open: true,
          incentives,
        });
      }

      // if the user has bid, he is on the whitelist
      setOnWhitelist(true);
    } catch (e) {
      console.log(e);
    }

    setIsTransactionLoading(false);
  };

  const buttonConfig = () => {
    const disabledConfig = { disabled: true, extraClass: "" };
    const action = i18("Bid", "sale.bidSection.bid");

    if (!walletInfo.isConnected) {
      return {
        buttonText: i18("Connect wallet", "trade.submitBtn.text.connectWallet"),
        ...disabledConfig,
      };
    }

    if ("WaitingToStart" === outbidSale.salesState[selectedSale.value].sale_state.status) {
      return {
        buttonText: i18("Sale didn't start yet", "sale.bidSection.saleNotStarted"),
        ...disabledConfig,
      };
    }

    if (
      ["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
        outbidSale.salesState[selectedSale.value].sale_state.status
      )
    ) {
      return {
        buttonText: i18("Sale ended", "sale.bidSection.saleEnded"),
        ...disabledConfig,
      };
    }

    if ("WhitelistBidding" === outbidSale.salesState[selectedSale.value].sale_state.status && !onWhitelist) {
      return {
        buttonText: i18("VIP bid only", "sale.bidSection.vipBidOnly"),
        ...disabledConfig,
      };
    }

    if (!bidInputAmount) {
      return {
        buttonText: i18("Enter Amount", "sale.bidSection.enterAmount"),
        ...disabledConfig,
      };
    }

    if (
      BigNumber(bidInputAmount.replaceAll(",", "")).gt(
        BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.max_ticket).div(Math.pow(10, 6))
      )
    ) {
      return {
        buttonText: i18(
          `Max ${BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.max_ticket).div(
            Math.pow(10, 6)
          )} tickets per TX`,
          "sale.bidSection.maxTickets",
          {
            amount: BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.max_ticket).div(
              Math.pow(10, 6)
            ),
          }
        ),
        ...disabledConfig,
      };
    }

    const remainingTickets = BigNumber(
      outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
    )
      .minus(outbidSale.salesState[selectedSale.value].sale_state.state.tickets_count)
      .div(Math.pow(10, 6));

    if (
      !remainingTickets.lt(BigNumber(minBidAmount).div(Math.pow(10, 6))) &&
      BigNumber(bidInputAmount.replaceAll(",", "")).lt(BigNumber(minBidAmount).div(Math.pow(10, 6)))
    ) {
      return {
        buttonText: i18(
          `Min ${BigNumber(minBidAmount).div(Math.pow(10, 6))} tickets per TX`,
          "sale.bidSection.minTicketsBtn",
          {
            amount: BigNumber(minBidAmount).div(Math.pow(10, 6)),
          }
        ),
        ...disabledConfig,
      };
    }

    if (
      remainingTickets.lt(BigNumber(minBidAmount).div(Math.pow(10, 6))) &&
      remainingTickets.toString(10) !== bidInputAmount.replaceAll(",", "")
    ) {
      return {
        buttonText: i18(`Only ${remainingTickets.toString(10)} tickets per TX`, "sale.bidSection.onlyTicketsBtn", {
          amount: remainingTickets.toString(10),
        }),
        ...disabledConfig,
      };
    }

    // check if there is a wallet cap
    if (
      salesBids &&
      selectedSale &&
      salesBids[selectedSale.value] &&
      "BonusBidding" !== outbidSale.salesState[selectedSale.value].sale_state.status &&
      outbidSale.salesState[selectedSale.value].config.sale_settings.wallet_max_ticket_cap &&
      BigNumber(bidInputAmount.replaceAll(",", ""))
        .plus(BigNumber(salesBids[selectedSale.value].total_final_ticket_amount).div(Math.pow(10, 6)))
        .gt(
          BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.wallet_max_ticket_cap).div(
            Math.pow(10, 6)
          )
        )
    ) {
      return {
        buttonText: i18(
          `Wallet can only buy a total of ${BigNumber(
            outbidSale.salesState[selectedSale.value].config.sale_settings.wallet_max_ticket_cap
          )
            .div(Math.pow(10, 6))
            .toString(10)} tickets`,
          "sale.bidSection.walletCapBtn",
          {
            amount: BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.wallet_max_ticket_cap)
              .div(Math.pow(10, 6))
              .toString(10),
          }
        ),
        ...disabledConfig,
      };
    }

    if (isTransactionLoading) {
      return {
        buttonText: i18("Executing...", "trade.submitBtn.text.executing"),
        ...disabledConfig,
      };
    }

    if (
      selectedBidAsset &&
      BigNumber(
        convertTicketNumberToAssetAmount(
          selectedBidAsset.id,
          "" !== bidInputAmount ? bidInputAmount.replaceAll(",", "") : "0"
        )
      ).gt(BigNumber(assetBalances[selectedBidAsset.id]).div(Math.pow(10, assets[selectedBidAsset.id].decimals)))
    ) {
      return {
        buttonText: i18(`Not enough balance`, "sale.bidSection.notEnoughBalance"),
        ...disabledConfig,
      };
    }

    return {
      buttonText: action,
      extraClass: "",
      disabled: false,
    };
  };

  return (
    <div style={{ marginBottom: "1em" }}>
      <div style={{ display: "flex" }}>
        <h3>
          {i18(`Buy tickets for ${outbidSale.projectSymbol} token`, "sale.bid.section", {
            project: outbidSale.projectSymbol,
          })}
        </h3>
      </div>

      <div className="input-bid-form-wrapper" style={{ marginBottom: "1em" }}>
        <form>
          <fieldset className="tradeFromTokenFieldset withGradientBorder">
            <fieldset className="selectTokenSubFieldset" style={{ paddingBottom: ".5em" }}>
              <div style={{ display: "flex", textAlignLast: "right" }}>
                <Button
                  style={{ marginRight: ".5em" }}
                  btnColor="dark-medium"
                  text={i18("Max", "sale.bidSection.maxBtn")}
                  title={i18("Select max value", "sale.bidSection.maxBtnSelect")}
                  onClick={() => {
                    const maxTicketsThatUserCanBuy = convertAssetAmountToTicketNumber(
                      selectedBidAsset.id,
                      BigNumber(assetBalances[selectedBidAsset.id])
                        .div(Math.pow(10, assets[selectedBidAsset.id].decimals))
                        .toString(10)
                    );
                    const remainingTickets = BigNumber(
                      outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
                    )
                      .minus(outbidSale.salesState[selectedSale.value].sale_state.state.tickets_count)
                      .div(Math.pow(10, 6));

                    let amountToInput;
                    if (
                      BigNumber(maxTicketsThatUserCanBuy).lt(
                        BigNumber(outbidSale.salesState[selectedSale.value].config.sale_settings.max_ticket).div(
                          Math.pow(10, 6)
                        )
                      )
                    ) {
                      amountToInput = maxTicketsThatUserCanBuy;
                    } else {
                      amountToInput = BigNumber(
                        outbidSale.salesState[selectedSale.value].config.sale_settings.max_ticket
                      )
                        .div(Math.pow(10, 6))
                        .toString(10);
                    }

                    if (BigNumber(BigNumber(amountToInput).toString(10)).gte(remainingTickets)) {
                      setBidInputAmount(
                        BigNumber(remainingTickets.decimalPlaces(6, BigNumber.ROUND_UP).toString(10)).toString(10)
                      );
                    } else {
                      setBidInputAmount(amountToInput);
                    }
                  }}
                />
                <Button
                  style={{ marginRight: ".5em" }}
                  btnColor="dark-medium"
                  text={i18("Min", "sale.bidSection.minBtn")}
                  title={i18("Select min value", "sale.bidSection.minBtnSelect")}
                  onClick={() => {
                    const remainingTickets = BigNumber(
                      outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
                    )
                      .minus(outbidSale.salesState[selectedSale.value].sale_state.state.tickets_count)
                      .toString(10);
                    const minTickets = outbidSale.salesState[selectedSale.value].config.sale_settings.min_ticket;

                    setBidInputAmount(
                      BigNumber(BigNumber(remainingTickets).lt(minTickets) ? remainingTickets : minTickets)
                        .div(Math.pow(10, 6))
                        .decimalPlaces(6)
                        .toString(10)
                    );
                  }}
                />
                <CustomNumericInput
                  extraClassName="fillAvailable"
                  name="bidInput"
                  labelText={i18("Choose amount", "trade.form.fromToken.input.label")}
                  value={bidInputAmount}
                  onChange={e => setBidInputAmount(e.target.value)}
                  hiddenLabel={true}
                  placeholder={"0"}
                  decimalScale={6}
                />
              </div>

              <MinTicketsComponent
                minTickets={outbidSale.salesState[selectedSale.value].config.sale_settings.min_ticket}
                remainingTickets={BigNumber(
                  outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
                )
                  .minus(outbidSale.salesState[selectedSale.value].sale_state.state.tickets_count)
                  .toString(10)}
                outbidSale={outbidSale}
                selectedSale={selectedSale}
              />
            </fieldset>

            <fieldset
              className="selectTokenSubFieldset tradeFromToken"
              style={{ borderBottom: "none", paddingBottom: "0em" }}
            >
              <div className="flexbox">
                <Controller
                  name="trade_from_token"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...field } }) => {
                    return (
                      <CustomSelect
                        {...field}
                        labelText={""}
                        hiddenLabel={true}
                        items={outbidSale.salesState[selectedSale.value].config.bid_assets
                          .map((asset: any) => {
                            const assetID = asset.info.native_token?.denom || asset.info.token?.contract_addr;
                            if (assets[assetID])
                              return {
                                value: assets[assetID].symbol,
                                label: assets[assetID].symbol,
                                id: assets[assetID].id,
                                optionPrefix: <img src={imgSanitize(assets[assetID].symbol)} alt={""} />,
                              };
                            return null;
                          })
                          .filter(asset => asset)}
                        placeholder={i18("Select token", "trade.form.fromToken.select.placeholder")}
                        value={selectedBidAsset}
                        onChange={(val: ListItemsProps) => {
                          setSelectedBidAsset(val);
                          setBidInputAmount(BigNumber(minBidAmount).div(Math.pow(10, 6)).decimalPlaces(0).toString(10));
                        }}
                      />
                    );
                  }}
                />

                <CustomNumericInput
                  extraClassName="gradientText"
                  name=""
                  labelText={""}
                  hiddenLabel={true}
                  placeholder={"0"}
                  value={
                    selectedBidAsset
                      ? convertTicketNumberToAssetAmount(
                          selectedBidAsset.id,
                          "" !== bidInputAmount ? bidInputAmount.replaceAll(",", "") : "0"
                        )
                      : "0"
                  }
                  decimalScale={6}
                  disabled={true}
                />
              </div>
            </fieldset>
            {selectedBidAsset && (
              <div className="selectTokenBalanceAmount" style={{ justifyContent: "right" }}>
                <UserBalance
                  balanceValue={BigNumber(assetBalances[selectedBidAsset.id])
                    .div(Math.pow(10, assets[selectedBidAsset.id].decimals))
                    .toNumber()}
                  balanceUsdValue={
                    BigNumber(assetBalances[selectedBidAsset.id])
                      .div(Math.pow(10, assets[selectedBidAsset.id].decimals))
                      .toNumber() * assets[selectedBidAsset.id].price
                  }
                />
              </div>
            )}
            <div style={{ display: "flex" }}>
              <Button
                title={i18("Share on X", "sale.share.x")}
                style={{ padding: "1em", margin: ".5em", marginTop: "1em" }}
                btnColor="dark-medium"
                onClick={() => {
                  const remainingTickets = BigNumber(
                    outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
                  )
                    .minus(outbidSale.salesState[selectedSale.value].sale_state.state.tickets_count)
                    .div(
                      Math.pow(
                        10,
                        outbidSale.salesState[selectedSale.value].config.target_allocation.target_asset_decimals
                      )
                    );

                  // const targetAssetDollarValue = BigNumber(
                  //   outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount
                  // ).div(
                  //   outbidSale.salesState[selectedSale.value].config.target_allocation.target_asset_allocation_amount
                  // );

                  const valueRemaining = remainingTickets
                    //.times(targetAssetDollarValue)
                    .decimalPlaces(0)
                    .toNumber()
                    .toLocaleString("en-US");

                  const winString =
                    "0" === valueRemaining
                      ? `Join in now for a chance to win ${outbidSale.projectSymbol} tokens%21`
                      : `Join in now for a chance to win %7E%24${valueRemaining} in ${outbidSale.projectSymbol} tokens%21`;

                  window.open(
                    "https://twitter.com/intent/tweet?text=" +
                      i18(
                        `I'm becoming my own VC by participating on Outbid%2C the gamified %23DeFi launchpad for the %23CosmosEcosystem built on %40axvdex%2C where every bid is a winner%21 ` +
                          winString,
                        "sale.share.x.post",
                        { prize: winString, project: outbidSale.project }
                      ).replace(/(%20|\s)+/g, "+")
                  );
                }}
              >
                <IcnTwitter style={{ width: "1em", height: "1em" }} />
              </Button>
              <Button
                style={{ margin: ".5em", marginRight: "0em", marginTop: "1em" }}
                btnColor="gradient"
                isFullWidth={true}
                text={buttonConfig().buttonText}
                title={i18("Submit", "trade.submitBtn.title")}
                disabled={buttonConfig().disabled}
                extraClassName={buttonConfig().extraClass}
                onClick={() => handleUserBid()}
              />
            </div>
          </fieldset>
        </form>
      </div>
      <SaleIncentiveCongratsModal
        saleIncentiveCongratsModalOpen={saleIncentiveCongratsModalOpen}
        setSaleIncentiveCongratsModalOpen={setSaleIncentiveCongratsModalOpen}
        outbidSale={outbidSale}
      />
    </div>
  );
};

const MinTicketsComponent = ({ minTickets, remainingTickets, outbidSale, selectedSale }: any) => {
  const { i18 } = useLanguage();
  const app = useRef<HTMLDivElement>(null);
  const prevAmount = useRef({ minTickets }).current;

  useEffect(() => {
    if (minTickets !== prevAmount.minTickets) {
      const ctx = gsap.context(() => {
        gsap.from(`.minTickets`, 1, {
          ease: "Power3.easeInOut",
          duration: 0.5,
          y: "50%",
          opacity: 0,
        });
      }, app);
      return () => ctx.revert();
    }
  }, [minTickets]);

  return (
    <div style={{ display: "flex", marginTop: ".5em", marginBottom: "0em" }}>
      <div style={{ marginLeft: "auto", marginRight: "1.5em" }}>
        <small style={{ fontSize: ".65em", color: "var(--warm-grey)" }}>
          1 Ticket ={" "}
          {BigNumber(1)
            .div(
              BigNumber(outbidSale.salesState[selectedSale.value].config.target_allocation.target_ticket_amount).div(
                outbidSale.salesState[selectedSale.value].config.target_allocation.target_asset_allocation_amount
              )
            )
            .decimalPlaces(2)
            .toString(10)}{" "}
          {outbidSale.projectSymbol}
        </small>
      </div>

      <div ref={app}>
        <small style={{ fontSize: ".65em", color: "var(--warm-grey)" }}>
          {i18("Minimum tickets", "sale.bidSection.minTickets")}:{" "}
          {BigNumber(BigNumber(remainingTickets).lt(minTickets) ? remainingTickets : minTickets)
            .div(Math.pow(10, 6))
            .decimalPlaces(6)
            .toString(10)}
        </small>
      </div>
    </div>
  );
};

export default SaleBidSection;
