import BigNumber from "bignumber.js";
import { ReactElement } from "react";
import { humanizeAmount } from "@axvdex/utils/formatNumber";
import imgSanitize from "@axvdex/utils/imgSanitize";

export interface Asset {
  // tokenWalletBalance: string;
  id: string;
  isNative: boolean;
  denom: string;
  address: string;
  decimals: number;
  isDerivative: boolean;
  symbol: string;
  label: string;
  price: number;
}
import { IAsset } from "@axvdex/utils/interfaces";
import { AssetChainLogo } from "@axvdex/utils/chains";
import { ListItemsProps } from "../form-element/CustomSelect";

export interface TradeInfo {
  fromFieldValue: number;
  fromTokenListDialog: boolean;
  fromFieldValueInUSD: number;
  fromAsset: IAsset;
  toAsset: IAsset;
  toFieldValue: number;
  toFieldValueInUSD: number;
  toTokenListDialog: boolean;
}

export const initialTradeInfo: TradeInfo = {
  fromTokenListDialog: false,
  toTokenListDialog: false,
  fromFieldValue: 0,
  fromAsset: {
    id: "",
    isNative: false,
    denom: "",
    address: "",
    decimals: 0,
    isDerivative: false,
    symbol: "",
    label: "",
    price: 0,
    contextChainId: "",
    isPriceWatch: null,
    isHidden: null,
    extraFields: null,
  },
  toAsset: {
    id: "",
    isNative: false,
    denom: "",
    address: "",
    decimals: 0,
    isDerivative: false,
    symbol: "",
    label: "",
    price: 0,
    contextChainId: "",
    isPriceWatch: null,
    isHidden: null,
    extraFields: null,
  },
  toFieldValue: 0,
  fromFieldValueInUSD: 0,
  toFieldValueInUSD: 0,
};

export const makeLists = (
  chainsStored,
  assetsStored,
  assetBalances,
  favouriteAssets,
  globalConfig,
  walletChainExternalContext
): {
  assetList: ListItemsProps[];
  chainList: ListItemsProps[];
} => {
  const chainIds = new Set<string>();
  const assetList: ListItemsProps[] = [];
  const alreadyAddedExternalAssets = new Set();
  Object.values(assetsStored).forEach((asset: IAsset) => {
    chainIds.add(asset.contextChainId);
    const assetBalance = assetBalances[asset.address || asset.denom];
    const balance = assetBalance ? humanizeAmount(assetBalance, asset.decimals) : "-";
    const usdBalance = assetBalance ? humanizeAmount(parseFloat(assetBalance) * asset.price, asset.decimals) : "-";

    assetList.push({
      value: asset.symbol + "_" + chainsStored[asset.contextChainId].displayName,
      label: asset.symbol,
      id: asset.id,
      optionPrefix: (<img src={imgSanitize(asset.symbol)} alt={""} />) as ReactElement<any, any>,
      optionSuffix:
        "-" === balance
          ? "-"
          : BigNumber(balance).decimalPlaces(2).toString() +
            " ($" +
            BigNumber(usdBalance !== "-" ? usdBalance : "0")
              .decimalPlaces(2)
              .toString() +
            ")",
      extraFields: {
        externalAsset: null,
        assetBalance: balance,
        assetBalanceUSD: usdBalance,
        contextChainId: asset.contextChainId,
        isMainChainCurrency: chainsStored[asset.contextChainId].currencies[0].coinMinimalDenom === asset.denom,
      },
    });

    // add external assets that can be used with IBC from non deployed chains to deployed chains and vice versa
    if (asset.isNative && asset.denom_trace?.base_denom) {
      // to get the correct chain we need to base the search on base denom AND channel because sometimes the same base denom is used on different chains
      const ibcDenomInfo = chainsStored[asset.contextChainId].ibcDenoms.find(
        ibcDenom =>
          ibcDenom.base_denom === asset.denom_trace?.base_denom &&
          "transfer/" + ibcDenom.this_chain_channel_id === asset.denom_trace?.path
      );
      if (!ibcDenomInfo) return;
      const fromChain: any = chainsStored[ibcDenomInfo.base_chain_id];
      if (!fromChain) return;
      if (alreadyAddedExternalAssets.has(asset.denom_trace?.base_denom + "_" + fromChain.chainId)) return;

      const assetBalance = assetBalances[asset.denom_trace?.base_denom];
      const balance = assetBalance ? humanizeAmount(assetBalance, asset.decimals) : "-";
      const usdBalance = assetBalance ? humanizeAmount(parseFloat(assetBalance) * asset.price, asset.decimals) : "-";
      chainIds.add(fromChain.chainId);
      alreadyAddedExternalAssets.add(asset.denom_trace?.base_denom + "_" + fromChain.chainId);

      const value = asset.symbol + "_" + chainsStored[fromChain.chainId].displayName;
      assetList.push({
        value,
        label: asset.symbol,
        id: null,
        optionPrefix: (<img src={imgSanitize(asset.symbol)} alt={""} />) as ReactElement<any, any>,
        optionSuffix:
          "-" === balance
            ? "-"
            : BigNumber(balance).decimalPlaces(2).toString() +
              " ($" +
              BigNumber(usdBalance !== "-" ? usdBalance : "0")
                .decimalPlaces(2)
                .toString() +
              ")",
        extraFields: {
          externalAsset: {
            id: asset.denom_trace?.base_denom,
            denom: asset.denom_trace?.base_denom,
            isNative: true,
            decimals: asset.decimals,
            price: asset.price,
            symbol: asset.symbol,
            contextChainId: fromChain.chainId,
            cw20_ics20: asset.denom_trace?.cw20_ics20,
          },
          assetBalance: balance,
          assetBalanceUSD: usdBalance,
          contextChainId: fromChain.chainId,
          walletChainExternalContext: walletChainExternalContext[value],
          isMainChainCurrency: fromChain.currencies[0].coinMinimalDenom === asset.denom_trace?.base_denom,
        },
      });
    }
  });

  assetList
    .sort(function (a, b) {
      const aSuffix = "-" === a.extraFields.assetBalanceUSD ? "0" : a.extraFields.assetBalanceUSD;
      const bSuffix = "-" === b.extraFields.assetBalanceUSD ? "0" : b.extraFields.assetBalanceUSD;
      if (BigNumber(aSuffix).gt(bSuffix)) {
        return -1;
      }
      if (BigNumber(aSuffix).lt(bSuffix)) {
        return 1;
      }
      return 0;
    })
    .sort(function (a, b) {
      // make main chain currency on top
      if (!a.extraFields.isMainChainCurrency && b.extraFields.isMainChainCurrency) return 1;
      if (a.extraFields.isMainChainCurrency && !b.extraFields.isMainChainCurrency) return -1;
    })
    .sort(function (a, b) {
      // make AXV on top if available
      if ("AXV" !== a.label && "AXV" === b.label) return 1;
      if ("AXV" === a.label && "AXV" !== b.label) return -1;
    });

  // filter chains that have deployments on, so we can put them on the top of the list
  const chainListDeployed = globalConfig?.chainContexts.map(chainContext => chainContext.chainId) || [];

  const chainList = [];

  chainIds.forEach(chainId => {
    chainList.push({
      value: chainId,
      label: chainsStored[chainId].displayName,
      optionPrefix: chainListDeployed.includes(chainId) ? (
        <AssetChainLogo chain={chainsStored[chainId]} style={{ fontSize: "var(--px14)", color: "var(--warm-grey)" }} />
      ) : null,
      optionSuffix: null,
      extraFields: {
        isDeployedChain: chainListDeployed.includes(chainId),
      },
    });
  });

  chainList
    // sort alphabetically first
    .sort((a, b) => a.label.localeCompare(b.label))
    // sort by deployment status
    .sort((a, b) => {
      if (!chainListDeployed.includes(a.value) && chainListDeployed.includes(b.value)) {
        return 1;
      }
      if (chainListDeployed.includes(a.value) && !chainListDeployed.includes(b.value)) {
        return -1;
      }
    });

  return {
    assetList,
    chainList,
  };
};
