import { SigningArchwayClient } from "@archwayhq/arch3.js/build";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { SigningStargateClient } from "@cosmjs/stargate";
import { ToastMessage } from "@axvdex/components/BackgroundToastSender";
import {
  IAsset,
  IContract,
  IPool,
  IFarm,
  ISwapLog,
  ILpBalance,
  IOutbidSale,
  INicknameInfo,
  IGlobalConfig,
  IChain,
  // ICurrency,
} from "../../utils/interfaces";

export interface IWalletInfo {
  name: string;
  pubKey: string;
  connectedChains: {
    [key: string]: IWalletConnectedChainInfo;
  };
  isConnected: boolean;
  connectionStatus: string;
}

export interface IWalletConnectedChainInfo {
  address: string;
  archwayClient?: SigningArchwayClient;
  signingClient: SigningCosmWasmClient;
  signingStargateClient: SigningStargateClient;
  connectAccountNumber: number;
  connectSequence: number;
  signerType: string;
  chainState: IChain;
}

export interface IState {
  isLoadingWallet: boolean;
  walletInfo: IWalletInfo;
  user: {
    favoriteSwaps: Array<any>;
    favoritePools: Array<any>;
    favoriteFarms: Array<any>;
    priceWatch: Array<any>;
    poolsWithBalance: Array<any>;
    farmsWithBalance: Array<any>;
    swapLogs: Array<ISwapLog>;
    myAssets: Array<any>;
    isUserDetailsLoad: boolean;
    favoriteAssets: Array<string>;
    grvt8Balance: { [key: string]: string } | null;
    salesBids: { [key: string]: any } | null;
    myDashboardGrid: any | null;
    myToastMessages: Array<ToastMessage>;
    myGlobalSettings?: any;
    vestingLockedAmount: string | null;
    myAssetUnmints: {
      [key: string]: {
        unmints: string[];
        readyUnmints: string[];
        readyTimestamps: number[];
      };
    };
    mySoftLockups: {
      [key: string]: {
        start_timestamp: string; // timestamp in string
        assets_amount: string[];
      }[];
    };
    notifications: {
      timestamp: number;
      type: string;
      msg: string;
      extraDetails?: any;
      isNew: boolean;
    }[];
    surveys: {
      [key: number]: any;
    };
    unmintDerivativesClaimable?: string[];
    unmintDerivativesUnClaimable?: string[];
    //myPoolUnlocks: { [key: string]: { unlocks: number; readyUnlocks: number } }; // for softlockups
  };
  contracts: { [key: string]: IContract };
  outbid: { [key: string]: IOutbidSale };
  outbidServerTime: number | null;
  pools: { [key: string]: IPool };
  farms: { [key: string]: IFarm };
  poolsLpBalance: { [key: string]: ILpBalance };
  farmsLpBalance: { [key: string]: ILpBalance };
  assets: { [key: string]: IAsset };
  chains: { [key: string]: IChain };
  globalConfig: IGlobalConfig;
  assetBalances: object;
  toast: { type: string; info: any; noStore?: boolean } | null;
  backgroundTemporaryState: { [key: string]: any }; // this is used to store temporary state for background processes where the user can see the progress of running tasks. ex. ibc crosschain swaps progress...
  updatingUser: boolean;
  nicknames: { [key: string]: INicknameInfo };
}

const state: IState = {
  isLoadingWallet: true,
  walletInfo: {
    isConnected: false,
    name: "",
    pubKey: "",
    connectedChains: {},
    connectionStatus: "",
  },
  user: {
    favoriteSwaps: [],
    favoritePools: [],
    favoriteFarms: [],
    priceWatch: [],
    poolsWithBalance: [],
    farmsWithBalance: [],
    swapLogs: [],
    myAssets: [],
    favoriteAssets: [],
    isUserDetailsLoad: false,
    grvt8Balance: null,
    salesBids: null,
    myDashboardGrid: null,
    myToastMessages: [],
    myGlobalSettings: null,
    // loadState(WHITE_LIST_PERSISTED_STATE_KEYS.globalSettings) === "undefined"
    //   ? {}
    //   : loadState(WHITE_LIST_PERSISTED_STATE_KEYS.globalSettings),
    vestingLockedAmount: null,
    myAssetUnmints: {},
    mySoftLockups: {},
    notifications: [],
    surveys: null,
  },
  contracts: {},
  outbid: {},
  outbidServerTime: null,
  pools: {},
  farms: {},
  poolsLpBalance: {},
  farmsLpBalance: {},
  assets: {},
  chains: {},
  globalConfig: null,
  assetBalances: {},
  toast: null,
  backgroundTemporaryState: {},
  updatingUser: false,
  nicknames: {},
};

export default state;
