import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import BigNumber from "bignumber.js";
import { isEqual } from "lodash";
import { AlertTriangle } from "react-feather";
import {
  formatBalance,
  fromHumanAmountToTokenBalance,
  // humanizeAmount
} from "@axvdex/utils/formatNumber";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  selectFarmsLpBalance,
  // selectGlobalConfig,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  executeFarmAction,
  retryWalletWithDispatch,
  // executePoolAction,
  // increasePoolAllowance,
  updateFarmLpStakingBalance,
  // updateLpStakingBalance,
  // updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
//import { NumericFormat } from "react-number-format";
// import stakeSimulation from "@axvdex/utils/swapScripts/stakeSimulation";
//import { gt } from "@axvdex/utils/math";
import imgSanitize from "@axvdex/utils/imgSanitize";
// import estimatedFees, {
//   GAS_ESTIMATION_STATIC_FARM_DEPOSIT,
//   GAS_ESTIMATION_STATIC_FARM_WITHDRAWAL,
//   GAS_ESTIMATION_STATIC_STABLE_POOL_DEPOSIT,
// } from "@axvdex/utils/estimatedFees";
import useLanguage from "@axvdex/hooks/useLanguage";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomLoader from "../common/CustomLoader";

// const DummyPoolCardDetailsTableList = [
//   { token: "ARCH", total: 10, deposits: 10 },
//   { token: "xARCH", total: 11, deposits: 15 },
// ];

interface FarmCardDepositTableProps {
  id: string;
  symbol: string;
  input: string;
  balance: number | null;
  deposited: number | null;
}

function FarmCardDetailsTable({ farmData, isDeposit }: any) {
  const { i18 } = useLanguage();
  const assetBalances = useAppSelector(selectAssetBalances, isEqual);
  const farmsLpBalance = useAppSelector(selectFarmsLpBalance, isEqual);

  const assets = useAppSelector(selectAssets, isEqual);
  const chains = useAppSelector(selectChains);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  // const globalConfig = useAppSelector(selectGlobalConfig);

  const [data, setData] = useState<FarmCardDepositTableProps[]>([]);
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const [deposited, setDeposited] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const walletChainContext = walletInfo?.connectedChains[farmData.contextChainId];

  useEffect(() => {
    const update = async () => {
      // if there is no wallet connect and auto connect is enabled, we should show the loader as we are trying to auto connect
      if (!walletInfo.isConnected && loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        setIsLoading(true);
        return;
      }
      if (0 === data.length || !deposited) setIsLoading(true);
      let balance = assetBalances[farmData.config.inc_token];
      if (!balance && !walletInfo.isConnected) {
        balance = "0";
      } else if (!balance) {
        balance = (
          await dispatch(
            updateTokenBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              tokenAddress: farmData.config.inc_token,
            })
          )
        ).payload;
      }

      let farmBalance = farmsLpBalance[farmData.address]?.locked;
      if (!farmBalance && !walletInfo.isConnected) {
        farmBalance = "0";
      } else if (!farmBalance) {
        farmBalance = (
          await dispatch(
            updateFarmLpStakingBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              lpStakingAddress: farmData.address,
            })
          )
        ).payload as string;
      }

      setDeposited(farmBalance);
      setData([
        {
          id: assets[farmData.config.inc_token].id,
          symbol: assets[farmData.config.inc_token].symbol,
          input: data && data[0] ? data[0].input : "",
          balance:
            formatBalance(balance, assets[farmData.config.inc_token].price, assets[farmData.config.inc_token].decimals)
              .amount || 0,
          deposited:
            formatBalance(
              farmBalance,
              assets[farmData.config.inc_token].price,
              assets[farmData.config.inc_token].decimals
            ).amount || 0,
        },
      ]);
      setIsLoading(false);
    };
    update();
  }, [walletInfo.isConnected, farmData, assetBalances]);

  const handleFarmInputChange = amount => {
    setData(prevData => {
      const newData = [...prevData];
      newData[0].input = amount.split(",").join("");
      return newData;
    });
  };

  const reconnectWallet = async () => {
    setIsTransactionStarts(true);
    await dispatch(
      retryWalletWithDispatch({
        chainToConnect: chains[farmData.contextChainId],
      })
    );
    setIsTransactionStarts(false);
  };

  const handleSubmit = async () => {
    setIsTransactionStarts(true);

    await dispatch(
      executeFarmAction({
        chainId: farmData.contextChainId,
        farmData,
        amount: BigNumber(data[0].input)
          .times(Math.pow(10, assets[farmData.config.inc_token].decimals))
          .decimalPlaces(0)
          .toString(10),
        isDeposit,
        i18,
        notClaimRewards:
          BigNumber(data[0].input)
            .times(Math.pow(10, assets[farmData.config.inc_token].decimals))
            .decimalPlaces(0)
            .toString(10) !== BigNumber(deposited).toString(10),
      })
    );

    handleFarmInputChange("0");

    setIsTransactionStarts(false);
  };

  const defaultColumns = useMemo<ColumnDef<FarmCardDepositTableProps>[]>(() => {
    return [
      {
        id: "token",
        accessorKey: "token",
        header: () => <span>{i18("Token Balance", "poolCardTable.table.columns.token")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img
                src={imgSanitize(row.original.symbol)}
                alt={row.original.symbol}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    updateTokenBalance({
                      client: walletChainContext.signingClient,
                      userAddress: walletChainContext.address,
                      tokenAddress: row.original.id,
                    })
                  )
                }
              />
            </span>

            {row.original.balance !== null && <span>{row.original.balance}</span>}
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className="visuallyHidden">{i18("Actions", "poolCardTable.table.columns.actions")}</span>,
        cell: ({ row }) => {
          return (
            <>
              {
                <>
                  <div className="formGroupInline">
                    {row.original.balance !== null && (
                      <Button
                        text={i18("Max", "poolCardTable.maxBtn.text")}
                        title={i18("Set Max Value", "poolCardTable.maxBtn.title")}
                        btnColor="gradientText"
                        onClick={() =>
                          handleFarmInputChange(isDeposit ? row.original.balance + "" : row.original.deposited + "")
                        }
                      />
                    )}
                    <CustomNumericInput
                      extraClassName="derivativeFromUsdFormGroup"
                      name={`amount_${row.index}`}
                      labelText={i18("Enter amount", "poolCardTable.input.label")}
                      hiddenLabel={true}
                      placeholder={"0"}
                      value={row.original.input}
                      onChange={e => {
                        handleFarmInputChange(e.target.value);
                      }}
                    />
                  </div>

                  <div className="tableInputValueConversion withMarginTop">
                    ($
                    {
                      formatBalance(
                        fromHumanAmountToTokenBalance(
                          row.original.input?.replaceAll(",", "") !== "."
                            ? row.original.input?.replaceAll(",", "")
                            : "0",
                          assets[row.original.id].decimals
                        ) || "0",
                        assets[row.original.id].price,
                        assets[row.original.id].decimals
                      ).usdConversion
                    }
                    )
                  </div>
                </>
              }
            </>
          );
        },
      },
    ];
  }, [i18]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const shouldDisableButton = amountWithinInput => {
    return amountWithinInput.find((value, _) => {
      return !BigNumber("" !== value ? value : "0")
        .times(Math.pow(10, assets[farmData.config.inc_token].decimals))
        .lte(isDeposit ? assetBalances[farmData.config.inc_token] : deposited);
    });
  };

  const submitButtonText = () => {
    const action = isDeposit
      ? i18("Deposit", "farmCardTable.submitBtn.text.deposit")
      : i18("Withdrawal", "farmCardTable.submitBtn.text.withdrawal");
    // const lcAction = isDeposit
    //   ? i18("deposit", "farmCardTable.submitBtn.text.lcdeposit")
    //   : i18("withdrawal", "farmCardTable.submitBtn.text.lcwithdrawal");

    if (!walletInfo.connectedChains[farmData.contextChainId]?.signingStargateClient) {
      return {
        buttonText: i18(
          "Not connected to " + chains[farmData.contextChainId]?.displayName + ". Try reconnect...",
          "poolCardTable.submitBtn.text.notConnected"
        ),
        disabled: false,
        reconnectWalletAction: true,
      };
    }

    if (data.every(value => !value.input || !Number(value.input))) {
      return {
        buttonText: i18("Enter Amount", "poolCardTable.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (
      (isDeposit && !farmData?.config.owner_settings?.is_deposit_enabled) ||
      (!isDeposit && !farmData?.config.owner_settings?.is_withdrawal_enabled)
    ) {
      return {
        buttonText: i18(`${action} disabled`, "farmCardTable.submitBtn.text.disabled", { action }),
        disabled: true,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "poolCardTable.submitBtn.text.executing"),
        disabled: true,
      };
    }

    if (shouldDisableButton(data.map(row => row.input))) {
      return {
        buttonText: i18("Not enough balance", "poolCardTable.submitBtn.text.notEnoughBalance"),
        disabled: true,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this
    // const calcEstimatedFees = estimatedFees(
    //   isDeposit ? GAS_ESTIMATION_STATIC_FARM_DEPOSIT : GAS_ESTIMATION_STATIC_FARM_WITHDRAWAL,
    //   globalConfig.statusCounters[farmData.contextChainId].estimatedFeesReference
    // );
    // const feeDenom = globalConfig.statusCounters[farmData.contextChainId].estimatedFeesReference.estimatedFee[0].denom;
    // if (assetBalances[feeDenom] && BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)) {
    //   return {
    //     buttonText: i18(`Not enough fees for ${lcAction}`, "farmCardTable.submitBtn.text.notEnoughFees", { lcAction }),
    //     disabled: true,
    //   };
    // }

    // // if the input is the source denom we need to check if it will consume more fees than the user have balance
    // // current balance - input amount < estimated fees
    // if (
    //   data.find(row => row.id === feeDenom) &&
    //   assetBalances[feeDenom] &&
    //   BigNumber(assetBalances[feeDenom])
    //     .minus(
    //       fromHumanAmountToTokenBalance(
    //         data.find(row => row.id === feeDenom).input.replaceAll(",", ""),
    //         assets[feeDenom].decimals
    //       ) || "0"
    //     )
    //     .lt(calcEstimatedFees)
    // ) {
    //   return {
    //     buttonText: i18(`Not enough fees for ${lcAction}`, "farmCardTable.submitBtn.text.notEnoughFees", { lcAction }),
    //     disabled: true,
    //   };
    // }

    return {
      buttonText: action,
      disabled: false,
    };
  };

  return (
    <section className={clsx("poolCardModalSectionTable withGradientBorderBottom")}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginBottom: "1.5em" }}>
          {walletChainContext?.signingStargateClient && <CustomLoader size="xs" />}
        </div>
      ) : (
        <>
          <table
            className={clsx(
              "table",
              "withGradientBorderRows",
              "poolCardModalDetailsTable",
              "isResponsive",
              "col-2",
              "stableDeposit"
            )}
          >
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>

            {deposited && (
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <div className="tableTFootFlexbox">
                      <small>{i18("Deposited", "poolCardTable.depositTable.deposited")}:</small>
                      <span>
                        {formatBalance(deposited, assets[farmData.config.inc_token].price, 6).amount}
                        <small>
                          &nbsp;($
                          {formatBalance(deposited, assets[farmData.config.inc_token].price, 6).usdConversion})
                        </small>
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </>
      )}

      {!isTransactionStarts ? (
        <>
          <Button
            text={submitButtonText().buttonText}
            title={i18("Submit", "poolCardTable.submitBtn.title")}
            aria-label={i18("Submit", "poolCardTable.submitBtn.title")}
            btnColor="gradient"
            isFullWidth={true}
            onClick={submitButtonText().reconnectWalletAction ? reconnectWallet : handleSubmit}
            disabled={submitButtonText().disabled}
          />
          {!isDeposit &&
            assetBalances &&
            farmData &&
            data.length > 0 &&
            BigNumber(data[0].input)
              .times(Math.pow(10, assets[farmData.config.inc_token].decimals))
              .decimalPlaces(0)
              .toString(10) === BigNumber(deposited).toString(10) && (
              <>
                <div className="flexbox helpText">
                  <span className="warning tokenIcon">
                    <AlertTriangle />
                  </span>
                  <span>
                    {i18(
                      `Withdrawing the full amount from a farm will automatically claim it's accumulated rewards`,
                      "poolCardTable.depositTable.withdrawFullAmount"
                    )}
                  </span>
                </div>
              </>
            )}
        </>
      ) : (
        <CustomLoader text="Processing..." size="xs" />
      )}
    </section>
  );
}

export default FarmCardDetailsTable;
