import { ReactNode, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import clsx from "clsx";
import { loadState, persistState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { updateUserDashboardGrid } from "@axvdex/api/user";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectMyDashboardGrid, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { deleteMyDashboardGrid } from "@axvdex/state/wallet/walletThunks";
import useLanguage from "@axvdex/hooks/useLanguage";
import DashboardMyAssets from "../components/DashboardMyAssets";
import DashboardMyHistory from "../components/DashboardMyHistory";
import DashboardMyPoolsAndFarms from "../components/DashboardMyPoolsAndFarms";
import DashboardMarket from "../components/DashboardMarket";
import DashboardGravitate from "../components/DashboardGravitate";
import DragHandle from "../components/common/DragHandle";
import Button from "./common/Button";
import DashboardMyPortfolio from "./DashboardMyPortfolio";
import DashboardAirdrop from "./DashboardAirdrop";

interface ItemProps {
  id: string;
  content: ReactNode;
}

interface ColumnProps {
  id: string;
  title: string;
  itemIds: string[];
}

interface GridDataProps {
  items: Record<string, ItemProps>;
  columns: Record<string, ColumnProps>;
  columnOrder: string[];
}

const gridData: GridDataProps = {
  items: {
    myAssets: { id: "myAssets", content: <DashboardMyAssets /> },
    myPortfolio: { id: "myPortfolio", content: <DashboardMyPortfolio /> },
    myHistory: { id: "myHistory", content: <DashboardMyHistory /> },
    myPool: { id: "myPool", content: <DashboardMyPoolsAndFarms /> },
    market: { id: "market", content: <DashboardMarket /> },
    gravitate: { id: "gravitate", content: <DashboardGravitate /> },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Left",
      itemIds: ["myAssets", "gravitate", "myHistory"],
    },
    "column-2": {
      id: "column-2",
      title: "Right",
      itemIds: ["myPortfolio", "myPool", "market"],
    },
  },
  columnOrder: ["column-1", "column-2"],
};

function DashboardGrid() {
  const dispatch = useAppDispatch();
  const { i18 } = useLanguage();
  const [state, setState] = useState(() => {
    const defaultGridData = { ...gridData };
    const dashboardGridStored = JSON.parse(localStorage.getItem(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid) || "{}");
    if (Object.keys(dashboardGridStored.columns || {}).length > 0) {
      return addPortfolioSection({ ...defaultGridData, columns: dashboardGridStored.columns });
    }
    return defaultGridData;
  });
  const walletInfo = useAppSelector(selectWalletInfo);
  const myDashboardGrid = useAppSelector(selectMyDashboardGrid);

  useEffect(() => {
    if (
      myDashboardGrid?.columns &&
      JSON.stringify(myDashboardGrid) !== localStorage.getItem(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid)
    ) {
      // add myPortfolio section if it does not exist when coming from remote
      const newGrid = addPortfolioSection({ ...gridData, columns: { ...myDashboardGrid.columns } });
      setState(newGrid);
    }
  }, [myDashboardGrid]);

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newItemIds = Array.from(start.itemIds);
      newItemIds.splice(source.index, 1);
      newItemIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        itemIds: newItemIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };

      setState(newState);

      const dashboardGridStored = JSON.parse(
        localStorage.getItem(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid) || "{}"
      );
      dashboardGridStored.columns = newState.columns;

      // we save it to the localstorage AND DB so user has the same layout on other devices
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid, dashboardGridStored);
      try {
        updateUserDashboardGrid(
          { myDashboardGrid: dashboardGridStored },
          {
            pubkey: walletInfo.pubKey,
            signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
          }
        );
      } catch (e) {
        console.log(e);
      }
      return;
    }

    // Moving from one list to another
    const startitemIds = Array.from(start.itemIds);
    startitemIds.splice(source.index, 1);
    const newStart = {
      ...start,
      itemIds: startitemIds,
    };

    const finishitemIds = Array.from(finish.itemIds);
    finishitemIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      itemIds: finishitemIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setState(newState);
    const dashboardGridStored = JSON.parse(localStorage.getItem(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid) || "{}");
    dashboardGridStored.columns = newState.columns;

    // we save it to the localstorage AND DB so user has the same layout on other devices
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid, dashboardGridStored);

    try {
      updateUserDashboardGrid(
        { myDashboardGrid: dashboardGridStored },
        {
          pubkey: walletInfo.pubKey,
          signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const resetLayout = async () => {
    setState(gridData);
    await dispatch(deleteMyDashboardGrid());
  };

  return (
    <section className="dashBoardSectionGrid">
      <div className="dashboardSectionGridButtonContainer">
        {walletInfo && <DashboardAirdrop />}
        <Button
          title={i18("Reset grid layout", "dashboard.grid.reset.title")}
          text={i18("Reset layout", "dashboard.grid.reset.text")}
          // icon={<RotateCcw />}
          // iconPlacement="left"
          onClick={resetLayout}
        />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <section className="sectionGrid">
          {state.columnOrder.map((columnId, index) => {
            const column = state.columns[columnId];
            if (!column) return null;
            const items = column.itemIds.map(itemId => state.items[itemId]);

            return (
              <div className="sectionGridColumn" key={index}>
                <Droppable droppableId={columnId} key={columnId}>
                  {provided => (
                    <>
                      <div {...provided.droppableProps} ref={provided.innerRef} className="sectionGridColumnItem">
                        {items
                          .filter(item => item) // remove elements not on code yet
                          .map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  className={clsx("sectionGridItem", snapshot.isDragging && "gridItemIsDragging")}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  {provided.dragHandleProps && (
                                    <DragHandle extraClassName="dashboardGridHandle" {...provided.dragHandleProps} />
                                  )}
                                  {item.content}
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {provided.placeholder}
                    </>
                  )}
                </Droppable>
              </div>
            );
          })}
        </section>
      </DragDropContext>
    </section>
  );
}

const addPortfolioSection = (grid: any) => {
  let newGrid = { ...grid };
  let myPortfolioDetected = false;
  for (const column of Object.values(newGrid.columns)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (column.itemIds.includes("myPortfolio")) {
      myPortfolioDetected = true;
      break;
    }
  }

  if (!myPortfolioDetected) {
    const newCol = { ...newGrid.columns["column-2"] };
    newCol.itemIds = ["myPortfolio", ...newGrid.columns["column-2"].itemIds];
    newGrid = {
      ...newGrid,
      columns: {
        ...newGrid.columns,
        ["column-2"]: newCol,
      },
    };
  }

  return newGrid;
};

export default DashboardGrid;
