import { useEffect, useState } from "react";
import clsx from "clsx";
import { ColumnDef } from "@tanstack/react-table";
import { AlertCircle, ArrowRight, Circle, Download, RefreshCw, Search, Star } from "react-feather";
import { useMediaQuery } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { includes, lowerCase } from "lodash";
import { Coin } from "@cosmjs/stargate";
import { updateSwapFavourites } from "@axvdex/state/wallet/walletSlice";

import { downloadLogs, updateSwapFavourite } from "@axvdex/api/user";
import { dashboardMyHistoryList } from "@axvdex/mocks/DummyData";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import imgSanitize from "@axvdex/utils/imgSanitize";
import getTradeSequence, {
  mintDerivativeParse,
  stableORhybridPoolSwapParse,
  standardPoolSwapParse,
} from "@axvdex/utils/getTradeSequence";

import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "../constants";
import { ReactComponent as IcnConversion } from "../assets/icons/icn-exchange.svg";
import styles from "../styles/DashboardMyHistory.module.scss";
import Button from "./common/Button";
import ButtonFavourite from "./common/ButtonFavourite";
import DragHandle from "./common/DragHandle";
import CustomDropdown from "./common/CustomDropdown";
import CustomInputButton from "./form-element/CustomInputButton";
import CustomSelect from "./form-element/CustomSelect";
import DashboardDndPaginationTable from "./DashboardDndPaginationTable";

import DashboardPaginationTable from "./DashboardPaginationTable";
import { getTokenList } from "./tokenListForSelect";
import CustomLoader from "./common/CustomLoader";
import { ToastMessage } from "./BackgroundToastSender";
import DashboardMultiDndPaginationTable from "./DashboardMultiDndPaginationTable";
import TradeVisualiserModal from "./modals/TradeVisualiserModal";
import { EventsEntity, IAsset, IChain, IContract, IPool, ISwapLog } from "utils/interfaces";
import { humanizeAmount } from "utils/formatNumber";
import { loadState, persistState, WHITE_LIST_PERSISTED_STATE_KEYS } from "state/persist";
import {
  selectAssets,
  selectChains,
  selectContracts,
  selectFavouriteSwaps,
  selectIsWalletLoading,
  selectMyToastMessages,
  selectPools,
  selectSwapLogs,
  selectWalletInfo,
} from "state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "state";

interface TableHistoryProps {
  id: string;
  date: string;
  time: string;
  timestamp: string;
  sections: {
    msgType: string;
    chainId: string;
    events: EventsEntity[];
    blockHeight: number;
    gasFees: Coin[] | null;
    ibcError?: boolean;
  }[];
  fromToken: {
    address: string;
    amount: string;
    symbol: string;
    chainName: string;
  };
  toToken: {
    address: string;
    amount: string;
    symbol: string;
    chainName: string;
  };
  selected: boolean;
  swapTokens: string;
}

const fetchSwapData = (
  pools: { [key: string]: IPool },
  assets: { [key: string]: IAsset },
  contracts: { [key: string]: IContract },
  chains: { [key: string]: IChain },
  swapLogs: ISwapLog[],
  favouriteList
) => {
  if (!Object.keys(pools).length || !Object.keys(assets).length) {
    return;
  }
  const transformedSwapLogs = [...swapLogs];

  const parseRouterEventsForMyHistory = (
    routerEvent: any,
    log: any,
    rawLog: any,
    blockHeight: any
  ): TableHistoryProps => {
    // we are at a routed swap
    // because the same type of action can be performed on multiple hops (ex. stable -> standard -> stable)
    // we need to separate inside the event that have multiple entries (ex. stable) by "_contract_address"
    // this defines the beginning of 1 hop / interaction

    // get first asset´
    // 0 index is the router address...
    const firstActionType = routerEvent.attributes.filter(attr => "hop" === attr.key)[0].value;
    const lastActionType = routerEvent.attributes.filter(attr => "hop" === attr.key)[
      routerEvent.attributes.filter(attr => "hop" === attr.key).length - 1
    ].value;

    let firstHopParsed = null;
    let lastHopParsed = null;

    let events = [...rawLog.events];
    [firstActionType, lastActionType].forEach((actionType, i) => {
      let entryCount = i === 1 ? -1 : 0;
      // with cosmos-sdk v0.47.0 version, the logs are structured in a different way, so we need to check the log block height
      // attribute logs with the same event type are now separated instead of all grouped together inside the same array
      // archway updated to cosmos-sdk v0.47.0 on block height 3554500
      if ("archway-1" !== log.contextChainId || ("archway-1" === log.contextChainId && blockHeight > 3554500)) {
        // entry count is always 0 as for the new log structure, the events are separated and the 0 index is always the actual event data we are looking for
        entryCount = 0;
        // to get last action, we need to reverse the array, so we can just do a .find
        // to get the first occurrence of the event type (that refers to the last action)
        if (1 === i) events = events.reverse();
      }
      if ("mint_derivative" === actionType) {
        const event = events.find(({ type }) => type.includes("wasm-astrovault-staking_derivative-mint_derivative"));
        const parsed = mintDerivativeParse(assets, contracts, event, entryCount);
        if (0 === i) firstHopParsed = parsed;
        else lastHopParsed = parsed;
      } else if ("standard_pool" === actionType) {
        const swapEvent = events.find(({ type }) => type.includes("wasm-astrovault-standard_pool-swap"));
        const cashbackEvent = events.find(({ type }) => type.includes("wasm-astrovault-cashback-mint"));
        const parsed = standardPoolSwapParse(assets, swapEvent, cashbackEvent, entryCount);
        if (0 === i) firstHopParsed = parsed;
        else lastHopParsed = parsed;
      } else if ("stable_pool" === actionType) {
        const swapEvent = events.find(({ type }) => type.includes("wasm-astrovault-stable_pool-swap"));
        const cashbackEvent = events.find(({ type }) => type.includes("wasm-astrovault-cashback-mint"));
        const parsed = stableORhybridPoolSwapParse(assets, pools, swapEvent, cashbackEvent, entryCount);
        if (0 === i) firstHopParsed = parsed;
        else lastHopParsed = parsed;
      } else if ("ratio_pool" === actionType) {
        const swapEvent = events.find(({ type }) => type.includes("wasm-astrovault-ratio_pool-swap"));
        const cashbackEvent = events.find(({ type }) => type.includes("wasm-astrovault-cashback-mint"));
        const parsed = stableORhybridPoolSwapParse(assets, pools, swapEvent, cashbackEvent, entryCount);
        if (0 === i) firstHopParsed = parsed;
        else lastHopParsed = parsed;
      }
    });

    if (firstHopParsed && lastHopParsed) {
      const assetIdentification = `${firstHopParsed.firstAsset.id}_${lastHopParsed.secondAsset.id}`;

      return {
        id: assetIdentification + "_" + log.timestamp,
        selected: favouriteList.includes(assetIdentification),
        swapTokens: assetIdentification,
        sections: [
          {
            msgType: log.msg_type,
            chainId: log.contextChainId,
            events,
            blockHeight: blockHeight,
            gasFees: log.gasFees,
          },
        ],
        timestamp: log.timestamp,
        date: dayjs(log.timestamp).format("DD/MM/YYYY"),
        time: dayjs(log.timestamp).format("LT"),
        fromToken: {
          address: firstHopParsed.firstAsset.id,
          amount: humanizeAmount(firstHopParsed.offerAmount, firstHopParsed.firstAsset.decimals),
          symbol: firstHopParsed.firstAsset.symbol,
          chainName: chains[log.contextChainId].displayName,
        },
        toToken: {
          address: lastHopParsed.secondAsset.id,
          amount: humanizeAmount(lastHopParsed.returnAmount, lastHopParsed.secondAsset.decimals),
          symbol: lastHopParsed.secondAsset.symbol,
          chainName: chains[log.contextChainId].displayName,
        },
      };
    }
  };

  const transformedData: (TableHistoryProps | null)[] = transformedSwapLogs.map(log => {
    try {
      // two types of logs => contract logs and ibc logs
      // contract log type
      if (log.contract_addr) {
        const rawLog = log?.raw_log[0];
        const events = [...rawLog.events];
        const blockHeight = log?.block_height;

        // parse logs depending on their log version (wasm only is the old way)
        if (rawLog.events.find(({ type }) => type.includes("wasm-astrovault-"))) {
          // current log structure
          if ("mint_derivative" === log.msg_type) {
            const mintEvent = rawLog.events.find(({ type }) =>
              type.includes("wasm-astrovault-staking_derivative-mint_derivative")
            );
            if (!mintEvent) return null;
            const mintParsed = mintDerivativeParse(assets, contracts, mintEvent);

            return {
              id: mintParsed.assetIdentification + "_" + log.timestamp,
              selected: favouriteList.includes(mintParsed.assetIdentification),
              swapTokens: mintParsed.assetIdentification,
              sections: [
                {
                  msgType: log.msg_type,
                  chainId: log.contextChainId,
                  events,
                  blockHeight: blockHeight,
                  gasFees: log.gasFees,
                },
              ],
              timestamp: log.timestamp,
              date: dayjs(log.timestamp).format("DD/MM/YYYY"),
              time: dayjs(log.timestamp).format("LT"),
              fromToken: {
                address: mintParsed.firstAsset.id,
                amount: humanizeAmount(mintParsed.offerAmount, mintParsed.firstAsset.decimals),
                symbol: mintParsed.firstAsset.symbol,
                chainName: chains[log.contextChainId].displayName,
              },
              toToken: {
                address: mintParsed.secondAsset.id,
                amount: humanizeAmount(mintParsed.returnAmount, mintParsed.secondAsset.decimals),
                symbol: mintParsed.secondAsset.symbol,
                chainName: chains[log.contextChainId].displayName,
              },
            };
          } else if ("swap" === log.msg_type) {
            const routerEvent = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-router-handle_route"));

            if (routerEvent) {
              return parseRouterEventsForMyHistory(routerEvent, log, rawLog, blockHeight);
            }

            // single pool swap
            const standardPoolSwap = rawLog.events.find(({ type }) =>
              type.includes("wasm-astrovault-standard_pool-swap")
            );
            const stablePoolSwap = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-stable_pool-swap"));
            const ratioPoolSwap = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-ratio_pool-swap"));

            if (standardPoolSwap) {
              const parsedSwap = standardPoolSwapParse(
                assets,
                standardPoolSwap,
                rawLog.events.find(({ type }) => type.includes("wasm-astrovault-cashback-mint"))
              );
              return {
                id: parsedSwap.assetIdentification + "_" + log.timestamp,
                selected: favouriteList.includes(parsedSwap.assetIdentification),
                swapTokens: parsedSwap.assetIdentification,
                sections: [
                  {
                    msgType: log.msg_type,
                    chainId: log.contextChainId,
                    events,
                    blockHeight: blockHeight,
                    gasFees: log.gasFees,
                  },
                ],
                timestamp: log.timestamp,
                date: dayjs(log.timestamp).format("DD/MM/YYYY"),
                time: dayjs(log.timestamp).format("LT"),
                fromToken: {
                  address: parsedSwap.firstAsset.id,
                  amount: humanizeAmount(parsedSwap.offerAmount, parsedSwap.firstAsset.decimals),
                  symbol: parsedSwap.firstAsset.symbol,
                  chainName: chains[log.contextChainId].displayName,
                },
                toToken: {
                  address: parsedSwap.secondAsset.id,
                  amount: humanizeAmount(parsedSwap.returnAmount, parsedSwap.secondAsset.decimals),
                  symbol: parsedSwap.secondAsset.symbol,
                  chainName: chains[log.contextChainId].displayName,
                },
              };
            } else if (stablePoolSwap || ratioPoolSwap) {
              const event = stablePoolSwap || ratioPoolSwap;
              const parsedSwap = stableORhybridPoolSwapParse(
                assets,
                pools,
                event,
                rawLog.events.find(({ type }) => type.includes("wasm-astrovault-cashback-mint"))
              );
              return {
                id: parsedSwap.assetIdentification + "_" + log.timestamp,
                selected: favouriteList.includes(parsedSwap.assetIdentification),
                swapTokens: parsedSwap.assetIdentification,
                sections: [
                  {
                    msgType: log.msg_type,
                    chainId: log.contextChainId,
                    events,
                    blockHeight: blockHeight,
                    gasFees: log.gasFees,
                  },
                ],
                timestamp: log.timestamp,
                date: dayjs(log.timestamp).format("DD/MM/YYYY"),
                time: dayjs(log.timestamp).format("LT"),
                fromToken: {
                  address: parsedSwap.firstAsset.id,
                  amount: humanizeAmount(parsedSwap.offerAmount, parsedSwap.firstAsset.decimals),
                  symbol: parsedSwap.firstAsset.symbol,
                  chainName: chains[log.contextChainId].displayName,
                },
                toToken: {
                  address: parsedSwap.secondAsset.id,
                  amount: humanizeAmount(parsedSwap.returnAmount, parsedSwap.secondAsset.decimals),
                  symbol: parsedSwap.secondAsset.symbol,
                  chainName: chains[log.contextChainId].displayName,
                },
              };
            }
          }
        } else {
          // old log structure
          const wasmObject = rawLog.events.find(({ type }) => "wasm" === type);
          const copyOfWasmObjectAttribute = [...wasmObject.attributes];
          const indexOfFirstAsset = wasmObject.attributes.findIndex(
            wasmData => ("action" === wasmData.key && "swap" === wasmData.value) || "mint_derivative" === wasmData.value
          );
          const indexOfSecondAsset = copyOfWasmObjectAttribute
            .reverse()
            .findIndex(
              wasmData =>
                ("action" === wasmData.key && "swap" === wasmData.value) || "mint_derivative" === wasmData.value
            );

          if (indexOfFirstAsset === -1 || indexOfSecondAsset === -1) return null;

          // verify if its solo derivative mint
          if (
            "mint_derivative" === wasmObject.attributes[indexOfFirstAsset].value &&
            indexOfFirstAsset === wasmObject.attributes.length - 1 - indexOfSecondAsset
          ) {
            // we are at a mint derivative log
            const derivativeContract = wasmObject.attributes[0].value;
            const derivativeState = Object.values(contracts).find(contract => contract.address === derivativeContract);
            const firstAssetAmount = wasmObject.attributes.find(attr => "amount" === attr.key).value;
            const secondAssetAmount = firstAssetAmount;
            if (!derivativeState) {
              return null;
            }
            const firstAssetID = derivativeState.config.network_settings.native_asset_denom;
            const secondAssetID = derivativeState.config.dx_token;
            const firstAsset = assets[firstAssetID];
            const secondAsset = assets[secondAssetID];
            const assetIdentification = `${firstAssetID}_${secondAssetID}`;
            return {
              id: assetIdentification + "_" + log.timestamp,
              selected: favouriteList.includes(assetIdentification),
              swapTokens: assetIdentification,
              sections: [
                {
                  msgType: log.msg_type,
                  chainId: log.contextChainId,
                  events,
                  blockHeight: blockHeight,
                  gasFees: log.gasFees,
                },
              ],
              timestamp: log.timestamp,
              date: dayjs(log.timestamp).format("DD/MM/YYYY"),
              time: dayjs(log.timestamp).format("LT"),
              fromToken: {
                address: firstAssetID,
                amount: humanizeAmount(firstAssetAmount, firstAsset.decimals),
                symbol: firstAsset.symbol,
                chainName: chains[log.contextChainId].displayName,
              },
              toToken: {
                address: secondAssetID,
                amount: humanizeAmount(secondAssetAmount, secondAsset.decimals),
                symbol: secondAsset.symbol,
                chainName: chains[log.contextChainId].displayName,
              },
            };
          }

          // log is a swap
          const { value: firstAddressContractAddress } = wasmObject.attributes[indexOfFirstAsset - 1];
          const { value: secondAddressContractAddress } = copyOfWasmObjectAttribute[indexOfSecondAsset + 1];

          let firstAssetAddress = "";
          let firstAssetAmount = "";
          let secondAssetAddress = "";
          let secondAssetAmount = "";

          if ("swap" === wasmObject.attributes[indexOfFirstAsset]?.value && !pools[firstAddressContractAddress]) {
            return null;
          }
          const firstMintDerivativeContract = Object.keys(contracts).find(
            contractKey => contracts[contractKey].address === firstAddressContractAddress
          );
          if ("mint_derivative" === wasmObject.attributes[indexOfFirstAsset]?.value && !firstMintDerivativeContract) {
            return null;
          }
          if ("swap" === wasmObject.attributes[indexOfSecondAsset]?.value && !pools[secondAddressContractAddress]) {
            return null;
          }
          const secondMintDerivativeContract = Object.keys(contracts).find(
            contractKey => contracts[contractKey].address === secondAddressContractAddress
          );
          if ("mint_derivative" === wasmObject.attributes[indexOfSecondAsset]?.value && !secondMintDerivativeContract) {
            return null;
          }

          if ("swap" === wasmObject.attributes[indexOfFirstAsset]?.value) {
            if ("standard" === pools[firstAddressContractAddress]?.type) {
              const { value: firstAssetAddressValue } = wasmObject.attributes.find(
                wasmData => "offer_asset" === wasmData.key
              );

              firstAssetAddress = firstAssetAddressValue;
              const { value: firstAssetOfferAmount } = wasmObject.attributes.find(
                wasmData => "offer_amount" === wasmData.key
              );

              firstAssetAmount = firstAssetOfferAmount;
            } else if (
              "stable" === pools[firstAddressContractAddress]?.type ||
              "hybrid" === pools[firstAddressContractAddress]?.type
            ) {
              const { value: firstAssetAddressAmount } = wasmObject.attributes.find(
                wasmData => "from_assets_amount" === wasmData.key
              );
              const transformFirstAssetAddress = JSON.parse(firstAssetAddressAmount);

              const firstAssetIndex = transformFirstAssetAddress.findIndex(firstAsset => Number(firstAsset) > 0);
              firstAssetAmount = transformFirstAssetAddress.find(firstAsset => Number(firstAsset) > 0);
              const poolAssets = pools[firstAddressContractAddress].poolAssets;
              const firstAsset = poolAssets[firstAssetIndex];

              firstAssetAddress = firstAsset?.info?.token?.contract_addr || firstAsset?.info?.native_token?.denom;
            }
          } else if ("mint_derivative" === wasmObject.attributes[indexOfFirstAsset]?.value) {
            firstAssetAddress = contracts[firstMintDerivativeContract]?.config.network_settings.native_asset_denom;
            firstAssetAmount =
              "amount" === wasmObject.attributes[indexOfFirstAsset + 1].key
                ? wasmObject.attributes[indexOfFirstAsset + 1].value // this is for arch derivative (source)
                : wasmObject.attributes[indexOfFirstAsset + 3].value; // this happens on external derivatives
          }

          if ("swap" === copyOfWasmObjectAttribute[indexOfSecondAsset]?.value) {
            if ("standard" === pools[secondAddressContractAddress]?.type) {
              const { value: secondAssetAskAddress } = copyOfWasmObjectAttribute.find(
                wasmData => "ask_asset" === wasmData.key
              );

              secondAssetAddress = secondAssetAskAddress;
              const { value: secondAssetAmountValue } = copyOfWasmObjectAttribute.find(
                wasmData => "return_amount" === wasmData.key
              );

              secondAssetAmount = secondAssetAmountValue;
            } else if (
              "stable" === pools[secondAddressContractAddress]?.type ||
              "hybrid" === pools[secondAddressContractAddress]?.type
            ) {
              const { value: secondAssetAddressValue } = copyOfWasmObjectAttribute.find(
                wasmData => "to_assets_amount" === wasmData.key
              );
              const transformSecondAssetAddress = JSON.parse(secondAssetAddressValue);

              const secondAssetIndex = transformSecondAssetAddress.findIndex(firstAsset => Number(firstAsset) > 0);
              secondAssetAmount = transformSecondAssetAddress.find(firstAsset => Number(firstAsset) > 0);

              const poolAssets = pools[secondAddressContractAddress].poolAssets;
              const secondAsset = poolAssets[secondAssetIndex];

              secondAssetAddress = secondAsset?.info?.token?.contract_addr || secondAsset?.info?.native_token?.denom;
            }
          } else if ("mint_derivative" === copyOfWasmObjectAttribute[indexOfSecondAsset]?.value) {
            secondAssetAddress = contracts[secondMintDerivativeContract]?.config.network_settings.native_asset_denom;
            secondAssetAmount = copyOfWasmObjectAttribute[indexOfSecondAsset + 1]?.value;
          }

          const firstAsset = assets[firstAssetAddress];
          const secondAsset = assets[secondAssetAddress];
          const assetIdentification = `${firstAssetAddress}_${secondAssetAddress}`;

          // prevent dashboard crash from bad db data
          if ("undefined" === typeof secondAsset) {
            return;
          }

          //Write a Typescript Interface for the below object and use it instead of any
          return {
            id: assetIdentification + "_" + log.timestamp,
            selected: favouriteList.includes(assetIdentification),
            swapTokens: assetIdentification,
            sections: [
              {
                msgType: log.msg_type,
                chainId: log.contextChainId,
                events,
                blockHeight: blockHeight,
                gasFees: log.gasFees,
              },
            ],
            timestamp: log.timestamp,
            date: dayjs(log.timestamp).format("DD/MM/YYYY"),
            time: dayjs(log.timestamp).format("LT"),
            fromToken: {
              address: firstAssetAddress,
              amount: humanizeAmount(firstAssetAmount, firstAsset.decimals),
              symbol: firstAsset.symbol,
              chainName: chains[log.contextChainId].displayName,
            },
            toToken: {
              address: secondAssetAddress,
              amount: humanizeAmount(secondAssetAmount, secondAsset?.decimals),
              symbol: secondAsset.symbol,
              chainName: chains[log.contextChainId].displayName,
            },
          };
        }
      }

      // ibc log type
      if (log.packet) {
        // check if it has router of the first step of ibc
        let fromObject: any;
        const fromRouterEvent = log.logs.find(({ type }) => type.includes("wasm-astrovault-router-handle_route"));
        const ibcTransferEvent = log.logs.find(({ type }) =>
          type.includes("wasm-astrovault-ibc_operator-cw20_ibc_mint_burn_transfer")
        );
        const ibcCallbackEvent = log.logs.find(({ type }) =>
          type.includes("wasm-astrovault-ibc_operator-cross_swap_self_callback")
        );

        if (fromRouterEvent) {
          fromObject = parseRouterEventsForMyHistory(
            fromRouterEvent,
            log,
            {
              events: log.logs,
            },
            log?.block_height
          );
        } else {
          // without router event, check the ibcTransfer or ibcCallback
          const fromTokenAddress = (ibcTransferEvent || ibcCallbackEvent).attributes.find(
            attr => "orig_cw20_address" === attr.key
          ).value;
          const fromTokenAmount = (ibcTransferEvent || ibcCallbackEvent).attributes.find(
            attr => "orig_amount" === attr.key
          ).value;
          fromObject = {
            fromToken: {
              address: fromTokenAddress,
              amount: humanizeAmount(fromTokenAmount, assets[fromTokenAddress].decimals),
              symbol: assets[fromTokenAddress].symbol,
              chainName: chains[assets[fromTokenAddress].contextChainId].displayName,
            },
          };
        }

        // TODO: ASSUMING THERE IS ONLY ONE HOP CORRESPONDENCE... CHANGE THIS TO SUPPORT MULTIPLE HOPS
        let toObject: any;
        const toLog = log.ibc_hops[0];
        const toRouterEvent = toLog.logs.find(({ type }) => type.includes("wasm-astrovault-router-handle_route"));
        const toFailEvent = toLog.logs.find(({ type }) =>
          type.includes("wasm-astrovault-ibc_operator-on_packet_failure")
        );
        let ibcError = false;
        if (toRouterEvent) {
          toObject = parseRouterEventsForMyHistory(
            toRouterEvent,
            toLog,
            {
              events: toLog.logs,
            },
            toLog?.block_height
          );
        } else if (toFailEvent) {
          ibcError = true;
          // if err then the asset is refunded on the original chain
          const toTokenAddress = toFailEvent.attributes.find(attr => "refund_asset" === attr.key).value;
          const toTokenAmount = toFailEvent.attributes.find(attr => "refund_amount" === attr.key).value;
          toObject = {
            toToken: {
              address: toTokenAddress,
              amount: humanizeAmount(toTokenAmount, assets[toTokenAddress].decimals),
              symbol: assets[toTokenAddress].symbol,
              chainName: chains[assets[toTokenAddress].contextChainId].displayName,
            },
          };
        } else {
          // without router event it means it just minted the target token
          const toTokenAddress = (ibcTransferEvent || ibcCallbackEvent).attributes.find(
            attr => "target_asset" === attr.key
          ).value;
          const toTokenAmount = (ibcTransferEvent || ibcCallbackEvent).attributes.find(
            attr => "target_amount" === attr.key
          ).value;
          toObject = {
            toToken: {
              address: toTokenAddress,
              amount: humanizeAmount(toTokenAmount, assets[toTokenAddress].decimals),
              symbol: assets[toTokenAddress].symbol,
              chainName: chains[assets[toTokenAddress].contextChainId].displayName,
            },
          };
        }

        const assetIdentification = fromObject.fromToken.address + "_" + toObject.toToken.address;
        return {
          id: assetIdentification + "_" + log.timestamp,
          selected: favouriteList.includes(assetIdentification),
          swapTokens: assetIdentification,
          sections: [
            {
              msgType: log.msg_type,
              chainId: log.contextChainId,
              events: log.logs,
              blockHeight: log?.block_height,
              gasFees: log.gasFees,
              ibcError,
            },
            {
              msgType: toLog.msg_type,
              chainId: toLog.contextChainId,
              events: toLog.logs,
              blockHeight: toLog?.block_height,
              gasFees: toLog.gasFees,
            },
          ],
          timestamp: log.timestamp,
          date: dayjs(log.timestamp).format("DD/MM/YYYY"),
          time: dayjs(log.timestamp).format("LT"),
          fromToken: fromObject.fromToken,
          toToken: toObject.toToken,
        };
      }
    } catch (e) {
      process.env.REACT_APP_MODE !== "MAINNET" && console.log(e);
      return null;
    }
  });

  return transformedData;
};

const FavouriteBtn = ({ id }: { id: string }) => {
  const walletInfo = useAppSelector(selectWalletInfo);
  const favouriteSwaps = useAppSelector(selectFavouriteSwaps);
  const dispatch = useAppDispatch();

  const toggleFavourite = async () => {
    const favIndex = favouriteSwaps.findIndex(fav => fav === id);
    const newSwapFavourites = [...favouriteSwaps];
    // if index is not present and the fav action was triggered, the action is to create this fav
    if (favIndex === -1) {
      newSwapFavourites.push(id);
    } else {
      newSwapFavourites.splice(favIndex, 1);
    }
    dispatch(updateSwapFavourites(newSwapFavourites));
    await updateSwapFavourite(
      { favoriteSwaps: newSwapFavourites },
      {
        pubkey: walletInfo.pubKey,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
      }
    );
  };

  return (
    <ButtonFavourite btnColor="purple" onToggleFavourite={toggleFavourite} isFavourite={favouriteSwaps.includes(id)} />
  );
};

function DashboardMyHistory() {
  const navigate = useNavigate();
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading);
  const walletInfo = useAppSelector(selectWalletInfo);
  const swapLogs = useAppSelector(selectSwapLogs);
  const pools = useAppSelector(selectPools);
  const assets = useAppSelector(selectAssets);
  const chains = useAppSelector(selectChains);
  const contracts = useAppSelector(selectContracts);
  const favouriteList = useAppSelector(selectFavouriteSwaps);

  const [isLoading, setIsLoading] = useState(true);
  const [downloadLogsLoading, setDownloadLogsLoading] = useState(false);
  const [showNotConnectedData, setshowNotConnectedData] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [allSwaps, setAllSwaps] = useState([]);
  const [filter, setFilter] = useState(() => {
    const f = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.myHistory).filter;
    return f ? f : "all";
  });
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [tabSelected, setTabSelected] = useState("trades");

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setAllSwaps([]);
      setIsLoading(false);
      setshowNotConnectedData(true);
    } else {
      setshowNotConnectedData(false);
      setIsLoading(false);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  useEffect(() => {
    // if (Object.keys(assetsStored)?.length !== Object.keys(assets)?.length) {
    const tokenList = getTokenList(assets);
    setTokenList(tokenList);
    // }
    // eslint-disable-next-line
  }, [assets]);

  useEffect(() => {
    const preparedLogs = fetchSwapData(pools, assets, contracts, chains, swapLogs, favouriteList);
    setAllSwaps(preparedLogs);
    // eslint-disable-next-line
  }, [swapLogs, favouriteList]);

  const [myHistorySearchMenuIsOpen, setMyHistorySearchMenuIsOpen] = useState(false);

  const historyColumns: ColumnDef<TableHistoryProps>[] = [
    {
      accessorKey: "date",
      header: () => <span>{i18("Date", "dashboard.history.trades.table.date")}</span>,
      cell: ({ row }) =>
        "favourites" !== filter && row.original.date && row.original.time ? (
          <time dateTime="2001-05-15T19:00" className="date">
            {row.original.date}&nbsp;<em>{row.original.time}</em>
            {row.original?.sections?.length > 0 && row.original.sections[0].ibcError && (
              <span style={{ color: "var(--red)", maxWidth: "1em", maxHeight: "1em" }}>
                <AlertCircle />
              </span>
            )}
          </time>
        ) : (
          "-"
        ),
    },
    {
      id: "transactions",
      header: () => <span>{i18("Transactions", "dashboard.history.trades.table.transactions")}</span>,
      cell: ({ row }) => {
        if (row.original.fromToken && row.original.toToken && row.original.sections) {
          // console.log(row.original.log.msg_type);
          return (
            <>
              {isMobileBreakpoint && (
                <time dateTime="2001-05-15T19:00" className="date">
                  {row.original.date}&nbsp;<em>{row.original.time}</em>
                </time>
              )}

              <div className="flexbox">
                <div className="flexboxItem">
                  <span
                    className="tokenIcon"
                    title={row.original.fromToken.symbol + " on " + row.original.fromToken.chainName}
                    aria-label={row.original?.fromToken.symbol}
                  >
                    <img
                      className="tokenIcon"
                      src={imgSanitize(row.original.fromToken.symbol)}
                      alt={`${Number(row.original.fromToken.amount).toFixed(6)} ${row.original.fromToken.symbol}`}
                    />
                  </span>

                  <span className="tokenInfo">
                    {"favourites" !== filter && (
                      <span className="tokenValue">{Number(row.original.fromToken.amount).toFixed(6)}</span>
                    )}
                    <span
                      title={row.original.fromToken.symbol + " on " + row.original.fromToken.chainName}
                      className="tokenName"
                    >
                      {row.original.fromToken.symbol}
                    </span>
                  </span>
                </div>

                <span className="tokenConversionIcon">
                  {"mint_derivative" === row.original.sections[0].msgType ? <ArrowRight /> : <IcnConversion />}
                </span>

                <div className="flexboxItem">
                  <span
                    className="tokenIcon"
                    title={row.original.toToken.symbol + " on " + row.original.toToken.chainName}
                    aria-label={row.original.toToken.symbol}
                  >
                    <img
                      className="tokenIcon"
                      src={imgSanitize(row.original.toToken.symbol)}
                      alt={`${Number(row.original.toToken.amount).toFixed(6)} ${row.original.toToken.symbol}`}
                    />
                  </span>

                  <span className="tokenInfo">
                    {"favourites" !== filter && (
                      <span className="tokenValue">{Number(row.original.toToken.amount).toFixed(6)}</span>
                    )}
                    <span
                      title={row.original.toToken.symbol + " on " + row.original.toToken.chainName}
                      className="tokenName"
                    >
                      {row.original.toToken.symbol}
                    </span>
                  </span>
                </div>
              </div>
            </>
          );
        }
        return "-";
      },
    },
    {
      id: "actions",
      header: () => <span>{i18("Actions", "dashboard.history.trades.table.actions")}</span>,
      cell: ({ row }) => {
        if (row.original.fromToken && row.original.toToken) {
          return (
            <div className="buttonContainer">
              <CustomDropdown
                extraClassName="dashboardMyHistoryActionsDropdown"
                customDropdownToggle={{
                  btnColor: "purple",
                  btnVariant: "icon",
                  btnTitle: i18("Menu", "dashboard.history.trades.table.actions.dropdown.title"),
                }}
                content={
                  <div className="btnGroup vertical">
                    <Button
                      btnColor="purple"
                      text={i18("Trade", "dashboard.history.trades.table.actions.trade")}
                      icon={<RefreshCw />}
                      iconPlacement="left"
                      onClick={() => {
                        navigate(
                          `/trade/?from=${row.original.fromToken.symbol}&to=${row.original.toToken.symbol}&amount=${
                            row.original.fromToken.amount
                          }&retrade=${true}`
                        );
                      }}
                    />

                    {row.original.sections && row.original.sections.length > 0 && (
                      <TradeVisualiserModal
                        source={"myhistory"}
                        tradeSequenceRaw={getTradeSequence(row.original.sections, assets, pools, contracts, chains)}
                        gasFees={
                          row.original.sections[0].gasFees && row.original.sections[0].gasFees.length > 0
                            ? row.original.sections[0].gasFees[0].amount
                            : null
                        }
                        historicalTimestamp={row.original.timestamp}
                      />
                    )}
                  </div>
                }
              />
              <FavouriteBtn id={row.original.swapTokens} />
            </div>
          );
        }
        return "-";
      },
    },
  ];

  useEffect(() => {
    if (allSwaps !== null && allSwaps !== undefined) {
      setSwaps(getSwapsToRender().map(swap => ({ ...swap, draggable: "favourites" === filter })));
      setIsLoading(false);
    }
  }, [filter, allSwaps]);

  const getSwapsToRender = () => {
    if ("favourites" === filter) {
      return allSwaps
        .filter(swap => swap.selected)
        .filter((v, i, a) => a.findIndex(v2 => v2.swapTokens === v.swapTokens) === i) // remove duplicates
        .map(swap => ({ ...swap, orderIndex: favouriteList.findIndex(favID => favID === swap.swapTokens) }))
        .sort((a, b) => a.orderIndex - b.orderIndex);
    }
    return allSwaps.filter(swap => swap); // remove null logs that are invalid
  };

  const onInputChange = (value: string) => {
    setMyHistorySearchMenuIsOpen(!!value);
    setSearchString(value);
  };

  const handleSearchKeyDown = event => {
    // setSearchString(event.target.value);
    if ("Enter" === event.key) {
      setSwaps(
        getSwapsToRender().filter(
          swap =>
            includes(lowerCase(swap.fromToken.symbol), lowerCase(event.target.value)) ||
            includes(lowerCase(swap.toToken.symbol), lowerCase(event.target.value))
        )
      );
      event.preventDefault();
    }
  };

  const handleSearchOnChange = (option, { action }) => {
    if ("select-option" === action) {
      setSwaps(
        getSwapsToRender().filter(
          swap => includes(swap.fromToken.symbol, option.value) || includes(swap.toToken.symbol, option.value)
        )
      );
    }
    if ("clear" === action) {
      setSwaps(getSwapsToRender());
    }
  };

  const handleOrderUpdate = (result: string[]) => {
    if (result.length) {
      const favouriteSwaps = [];
      for (const favID of result) {
        const swap = allSwaps.find(swap => swap.id === favID);
        favouriteSwaps.push(swap.swapTokens);
      }
      dispatch(updateSwapFavourites(favouriteSwaps));
      updateSwapFavourite(
        { favoriteSwaps: favouriteSwaps },
        {
          pubkey: walletInfo.pubKey,
          signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
        }
      );
    }
  };

  const updateFilter = (filter: string) => {
    const myHistoryConfig = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.myHistory);
    myHistoryConfig.filter = filter;
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.myHistory, myHistoryConfig);
    setFilter(filter);
  };

  return (
    <section className={clsx(styles.dashboardGridItemMyHistory, "dashboardGridItemMyHistory")}>
      <div className="dashboardGridItemTitle">
        <h2>{i18("My History", "dashboard.history.title")}</h2>

        <div
          className="buttonContainer"
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet))
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          {"trades" === tabSelected && (
            <CustomDropdown
              extraClassName="dashboardMenuDropdown history"
              customDropdownToggle={{
                btnVariant: "icon",
                btnColor: "dark-medium",
                btnTitle: i18("Menu", "dashboard.history.menu.title"),
              }}
              content={
                <>
                  <p className="label">{i18("Display", "dashboard.history.filter.header")}</p>

                  <div className="btnGroup vertical noBorder">
                    <CustomInputButton
                      type="radio"
                      id="all"
                      name="assets_sort"
                      labelText={i18("All", "dashboard.history.filter.all")}
                      labelIcon={<Circle />}
                      checked={"all" === filter}
                      onChange={() => {
                        updateFilter("all");
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="favourites"
                      name="assets_sort"
                      labelText={i18("Favourites", "dashboard.history.filter.favourites")}
                      labelIcon={<Star />}
                      checked={"favourites" === filter}
                      onChange={() => {
                        updateFilter("favourites");
                      }}
                    />
                  </div>

                  <p className="label">{i18("Logs", "dashboard.history.logs.header")}</p>

                  <div
                    className="btnGroup vertical noBorder"
                    style={{
                      alignItems: downloadLogsLoading ? "center" : undefined,
                    }}
                  >
                    {downloadLogsLoading ? (
                      <CustomLoader size="xs" />
                    ) : (
                      <CustomInputButton
                        type="radio"
                        id="downloadLogs"
                        name="downloadLogs"
                        labelText={i18("Download", "dashboard.history.logs.download")}
                        labelIcon={<Download />}
                        checked={false}
                        onClick={async () => {
                          setDownloadLogsLoading(true);
                          const res = await downloadLogs({
                            pubkey: walletInfo.pubKey,
                            signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
                              "cosmos_" + walletInfo.pubKey
                            ],
                          });
                          const data = await res.text();

                          const blob = new Blob([data], { type: "text/csv" });
                          const link$ = document.createElement("a");
                          link$.download = "Astrovault_Logs.csv";
                          link$.href = window.URL.createObjectURL(blob);
                          const clickEvt = new MouseEvent("click", {
                            view: window,
                            bubbles: true,
                            cancelable: true,
                          });
                          link$.dispatchEvent(clickEvt);
                          link$.remove();

                          setDownloadLogsLoading(false);
                        }}
                      />
                    )}
                  </div>

                  <CustomSelect
                    name="history_search"
                    labelText={i18("Search", "dashboard.history.search")}
                    hiddenLabel={true}
                    placeholder={i18("Search", "dashboard.history.search")}
                    items={tokenList}
                    customSelectIcon={<Search />}
                    hideChevronIcon={true}
                    onInputChange={onInputChange}
                    onChange={handleSearchOnChange}
                    onKeyDown={handleSearchKeyDown}
                    inputValue={searchString}
                    isClearable={true}
                    menuIsOpen={myHistorySearchMenuIsOpen}
                  />
                </>
              }
            />
          )}
          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>

      {showNotConnectedData ||
      (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
      !hasPermitStored(walletInfo) ? (
        <div style={{ pointerEvents: "none", filter: "blur(0.3rem)" }}>
          <DashboardDndPaginationTable
            tableId="myHistoryTable"
            extraClassName="dashboardHistoryTable"
            customData={dashboardMyHistoryList}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customColumns={historyColumns}
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      ) : isLoading ? (
        <CustomLoader size="xs" />
      ) : (
        <section className="withGradientBorder">
          {!showNotConnectedData && !isLoading && (
            <fieldset>
              <legend className="visuallyHidden">{i18("Select tab", "dashboard.history.tabs.legend")}</legend>
              <div className="btnGroup filterBtnGroup">
                <CustomInputButton
                  type="radio"
                  id={"history_trades"}
                  name={"history_filter"}
                  defaultChecked
                  labelText={i18("Trades", "dashboard.history.tabs.trades.title")}
                  onClick={() => setTabSelected("trades")}
                />
                <CustomInputButton
                  type="radio"
                  id={"history_messages"}
                  name={"history_filter"}
                  labelText={i18("Messages", "dashboard.history.tabs.messages.title")}
                  onClick={() => setTabSelected("messages")}
                />
              </div>
            </fieldset>
          )}
          {"trades" === tabSelected && (
            <>
              {swaps.length === 0 ? (
                <>
                  <span>{i18("No trade history yet...", "dashboard.history.tabs.trades.placeholder")}</span>
                </>
              ) : (
                <>
                  {"favourites" === filter ? (
                    /*<DashboardDndPaginationTable
                      tableId="myHistoryTable"
                      extraClassName="dashboardHistoryTable"
                      customData={swaps}
                      customColumns={historyColumns}
                      pagination={pagination}
                      setPagination={setPagination}
                      onOrderUpdate={handleOrderUpdate}
                    />*/
                    <DashboardMultiDndPaginationTable
                      tableId="myHistoryTable"
                      extraClassName="dashboardHistoryTable"
                      customData={swaps}
                      customColumns={historyColumns}
                      pagination={pagination}
                      setPagination={setPagination}
                      onOrderUpdate={handleOrderUpdate}
                    />
                  ) : (
                    <DashboardPaginationTable
                      tableId="myHistoryTable"
                      extraClassName="dashboardHistoryTable"
                      customData={swaps}
                      customColumns={historyColumns}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  )}
                </>
              )}
            </>
          )}
          {"messages" === tabSelected && <MessagesSection />}
        </section>
      )}
    </section>
  );
}

const MessagesSection = () => {
  const { i18 } = useLanguage();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const myToastMessages = useAppSelector(selectMyToastMessages);

  const messagesColumns: ColumnDef<ToastMessage>[] = [
    {
      accessorKey: "date",
      header: () => <span>{i18("Date", "dashboard.history.messages.table.date")}</span>,
      cell: ({ row }) => (
        <time dateTime="2001-05-15T19:00" className="date">
          {row.original.date}&nbsp;<em>{row.original.time}</em>
        </time>
      ),
    },
    {
      id: "message",
      header: () => <span>{i18("Message", "dashboard.history.messages.table.message")}</span>,
      cell: ({ row }) => <span>{row.original.message}</span>,
    },
    {
      id: "links",
      header: () => <span>{i18("Links", "dashboard.history.messages.table.links")}</span>,
      cell: ({ row }) => (
        <>
          {row.original.links.map(link => (
            <>
              <a
                key={link.url}
                target="_blank"
                href={link.url}
                rel="noreferrer"
                className="btn"
                data-color="gradient"
                data-variant="link"
              >
                {link.label}
              </a>
              <br />
            </>
          ))}
        </>
      ),
    },
  ];

  return (
    <div>
      {!myToastMessages || myToastMessages.length === 0 ? (
        <>
          <span>{i18("No message history yet...", "dashboard.history.tabs.messages.placeholder")}</span>
        </>
      ) : (
        <DashboardPaginationTable
          tableId="myMessagesTable"
          extraClassName="dashboardHistoryTable toasts"
          customData={myToastMessages}
          customColumns={messagesColumns}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </div>
  );
};

export default DashboardMyHistory;
