import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { map, mapValues, sortBy, toPairs, unzip, values } from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { abbreviateNumber, abbreviateUSD } from "@axvdex/utils/formatNumber";
import { useAppSelector } from "@axvdex/state";
import { selectAssets } from "@axvdex/state/wallet/walletSelectors";

HighchartsAccessibility(Highcharts);

interface POLChartProps {
  polBalances: {
    [key: string]: {
      amount: string;
      amountInUSD: number;
    };
  };
}

const OTHERS_THRESHOLD = 2.5;

//These are the colors for the chart copied from Statistics.module.scss, rearranged to match the order and no similar colors are next to each other
const colors = [
  "#00FF7F", // --chart-variation-electric-green
  "#00BFFF", // --chart-variation-electric-blue
  "#8A2BE2", // --chart-variation-electric-purple
  "#FF0033", // --chart-variation-electric-red
  // "#FF6600", // --chart-variation-electric-orange ... commented out so only used once as ARCH token reserved
  "#FF1493", // --chart-variation-electric-pink
  "#FFFF33", // --chart-variation-electric-yellow
  "#FF33FF", // --chart-variation-electric-magenta
  "#00FFFF", // --chart-variation-electric-cyan
  "#CCFF00", // --chart-variation-electric-lime
  "#00FFFF", // --chart-variation-neon-blue
  "#FF00FF", // --chart-variation-neon-purple
  "#00FF00", // --chart-variation-neon-green
  "#FFAA00", // --chart-variation-neon-orange
  "#FF00AA", // --chart-variation-neon-pink
  "#FFFF00", // --chart-variation-neon-yellow
  "#FF00FF", // --chart-variation-neon-magenta
  "#00FFEF", // --chart-variation-neon-turquoise
  "#00FFFF", // --chart-variation-neon-cyan
];

const generateColors = (n: number, idxArch: number) => {
  const backgroundColor = [];
  const borderColor = [];
  let i = 0;
  for (let idx = 0; idx < n; idx++) {
    if (idxArch === idx) {
      const reserved = "#FF4D00";
      backgroundColor.push(`${reserved}66`);
      borderColor.push(`${reserved}CC`);
    } else {
      backgroundColor.push(`${colors[i % colors.length]}66`);
      borderColor.push(`${colors[i % colors.length]}CC`);
      i++;
    }
  }
  return { backgroundColor, borderColor };
};

function POLChart({ polBalances }: POLChartProps) {
  const assets = useAppSelector(selectAssets);

  const polData = useMemo(() => {
    const totalUSD = map(values(polBalances), ({ amountInUSD }) => amountInUSD).reduce(
      (a: number, b: number) => a + b,
      0
    );
    const polAssets = { Others: { amountInUSD: 0, amount: 0 } };
    const assetsParsed = toPairs(polBalances)
      .map(([k, v], _) => [{ ...v, ...assets[k] }])
      .flat()
      .map(({ symbol, amountInUSD, decimals, amount, id }) => {
        return {
          symbol,
          amountInUSD,
          amount: id
            ? BigNumber(amount).div(Math.pow(10, decimals)).decimalPlaces(4, BigNumber.ROUND_FLOOR).toNumber()
            : 0,
        };
      });

    // group native and derivative together
    const assetsParsedData: {
      [key: string]: {
        symbol: string;
        amount: number;
        amountInUSD: number;
      };
    } = {};

    assetsParsed.forEach(asset => {
      let symbol = asset.symbol;
      if (!symbol) return;
      if ("x" === symbol[0]) {
        // if x is in front it's our derivative, so we remove it to be grouped to its respective native
        symbol = symbol.substring(1);
      }
      if (!assetsParsedData[symbol]) {
        assetsParsedData[symbol] = asset;
      } else {
        assetsParsedData[symbol].symbol = symbol;
        assetsParsedData[symbol].amount = BigNumber(assetsParsedData[symbol].amount)
          .plus(asset.amount)
          .decimalPlaces(4, BigNumber.ROUND_FLOOR)
          .toNumber();
        assetsParsedData[symbol].amountInUSD = assetsParsedData[symbol].amountInUSD + asset.amountInUSD;
      }
    });

    Object.values(assetsParsedData).forEach(({ symbol, amountInUSD, amount }) => {
      const percentage = (amountInUSD * 100) / totalUSD;
      if (percentage >= OTHERS_THRESHOLD) {
        polAssets[symbol] = { amountInUSD, amount };
      } else {
        polAssets.Others = {
          amountInUSD: polAssets.Others.amountInUSD + amountInUSD,
          amount: polAssets.Others.amount + amount,
        };
      }
    });

    const listOfAssets = unzip(sortBy(toPairs(polAssets), i => i[1].amountInUSD).reverse());
    let idxOrange: number = listOfAssets[0].indexOf("ARCH");
    if (-1 === idxOrange) {
      idxOrange = listOfAssets[0].indexOf("CONST");
      if (-1 === idxOrange) {
        idxOrange = listOfAssets[0].indexOf("BTC.axv"); // here as testnet test
      }
    }
    const { backgroundColor, borderColor } = generateColors(listOfAssets[1].length, idxOrange);
    return {
      labels: listOfAssets[0],
      datasets: [
        {
          data: values(mapValues(listOfAssets[1], "amountInUSD")),
          amounts: values(mapValues(listOfAssets[1], "amount")),
          totalData: values(mapValues(listOfAssets[1], "amountInUSD")).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0),
          backgroundColor,
          borderColor,
        },
      ],
    };
  }, [polBalances, assets]);

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: "pie",
            backgroundColor: "",
            height: "100%",
          },
          accessibility: {
            enabled: true,
            description: "Protocol Owned Liquidity asset distribution",
          },
          colors: polData.datasets[0].backgroundColor,
          title: {
            text: "",
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            formatter() {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return `<b>${this.key}</b>: ${this.point.amount} (${abbreviateUSD(this.y)})`;
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "{point.name}: {point.per}%",
              },
              showInLegend: true,
            },
          },
          series: [
            {
              name: "POL",
              colorByPoint: true,
              innerSize: "50%",
              data: polData.datasets[0].data.map((data, i) => {
                return {
                  name: polData.labels[i],
                  y: data,
                  per: BigNumber((data / polData.datasets[0].totalData) * 100)
                    .decimalPlaces(2)
                    .toString(),
                  amount: abbreviateNumber(polData.datasets[0].amounts[i]),
                  borderColor: polData.datasets[0].borderColor[i],
                };
              }),
            },
          ],
          credits: {
            enabled: false,
          },
        }}
      />
    </>
  );
}

export default POLChart;
