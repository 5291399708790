import { ReactNode, useState, useCallback, useMemo } from "react";
import clsx from "clsx";
import Tippy, { TippyProps } from "@tippyjs/react";
import { MoreHorizontal, X, ChevronDown } from "react-feather";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../../styles/CustomDropdown.module.scss";

interface CustomDropdownToggleProps {
  btnExtraClassName?: string;
  btnVariant?: "icon" | "link";
  btnColor?: "gradient" | "gradientText" | "purple" | "dark-medium";
  btnTitle?: string;
  btnText?: string;
  btnIconStyle?: "chevron"; //dropdown displays chevron down icon.
  btnOpenIcon?: ReactNode; //choose to display another icon on open
  btnContent?: ReactNode;
}

interface CustomDropdownProps extends TippyProps {
  extraClassName?: string;
  maxWidth?: string;
  zIndex?: number;
  customDropdownToggle: CustomDropdownToggleProps;
  content: ReactNode;
  badge?: ReactNode;
}

function CustomDropdown({
  extraClassName,
  maxWidth,
  zIndex,
  customDropdownToggle,
  content,
  badge,
}: CustomDropdownProps) {
  const { i18 } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const onShow = useCallback(() => setIsOpen(isOpen => !isOpen), []);
  const onHide = useCallback(() => setIsOpen(false), []);
  const className = useMemo(
    () => clsx(styles.customDropdownWrapper, "customDropdownWrapper", extraClassName && extraClassName),
    [extraClassName]
  );

  return (
    <div className={className}>
      <Tippy
        visible={isOpen}
        interactive
        offset={[0, 0]}
        delay={[0, 300]}
        arrow={false}
        zIndex={zIndex ? zIndex : 2}
        maxWidth={maxWidth ? maxWidth : "100%"}
        placement={"bottom-end"}
        animation="shift-away"
        className="customDropdown"
        onClickOutside={onHide}
        content={
          <div className="customDropdownContent">
            <div className="customDropdownContentWrapper">{content}</div>
          </div>
        }
      >
        <div style={{ width: "100%", position: "relative", display: "inline-block" }}>
          <button
            className={clsx(
              "btn customDropdownToggle",
              isOpen && "isOpen",
              customDropdownToggle.btnExtraClassName && customDropdownToggle.btnExtraClassName
            )}
            title={
              customDropdownToggle.btnTitle ??
              customDropdownToggle.btnText ??
              i18("Button Title", "customDropdownToggle.btnTitle")
            }
            aria-label={
              customDropdownToggle.btnTitle ??
              customDropdownToggle.btnText ??
              i18("Button Title", "customDropdownToggle.btnAriaLabel")
            }
            data-variant={customDropdownToggle.btnVariant && customDropdownToggle.btnVariant}
            data-color={customDropdownToggle.btnColor && customDropdownToggle.btnColor}
            data-icon-style={customDropdownToggle.btnIconStyle && customDropdownToggle.btnIconStyle}
            onClick={onShow}
          >
            {customDropdownToggle.btnText ? customDropdownToggle.btnText : customDropdownToggle.btnContent}

            {"chevron" === customDropdownToggle.btnIconStyle ? (
              <i className="icon customDropdownToggleIcon">
                <ChevronDown />
              </i>
            ) : (
              <>
                {isOpen ? (
                  <X />
                ) : (
                  <>{customDropdownToggle.btnOpenIcon ? customDropdownToggle.btnOpenIcon : <MoreHorizontal />}</>
                )}
              </>
            )}
            {badge}
          </button>
        </div>
      </Tippy>
    </div>
  );
}

export default CustomDropdown;
