import { fetchWrapper } from "./index";

export const getUser = headers => fetchWrapper("GET", "/user", undefined, {}, headers);

export const getUserLogs = (headers, msgType = "swap,mint_derivative,ibc") =>
  fetchWrapper("GET", `/user/logs?msgType=${msgType}`, undefined, {}, headers);

export const updateUserPriceWatch = (body, headers) => fetchWrapper("PUT", "/user/priceWatch", body, {}, headers);

export const updateUserAssets = (body, headers) => fetchWrapper("PUT", "/user/myAssets", body, {}, headers);

export const updateUserDashboardGrid = (body, headers) =>
  fetchWrapper("PUT", "/user/myDashboardGrid", body, {}, headers);

export const updateUserGlobalSettings = (body, headers) =>
  fetchWrapper("PUT", "/user/myGlobalSettings", body, {}, headers);

export const updateUserToastMessages = (body, headers) =>
  fetchWrapper("PUT", "/user/myToastMessages", body, {}, headers);

export const updateUserFavouriteAssets = (body, headers) =>
  fetchWrapper("PUT", "/user/assetFavorite", body, {}, headers);

export const postSignature = body => fetchWrapper("POST", "/user/permit", body);

export const postTransactions = (body, headers) => fetchWrapper("POST", "/user/tx", body, {}, headers);

export const postPoolFavourite = (methodType, body, headers) =>
  fetchWrapper(methodType, "/user/poolFavorite", body, {}, headers);

// export const deletePoolFavourite = (body, headers) => fetchWrapper("DELETE", "/user/poolFavorite", body, {}, headers);

export const updateSwapFavourite = (body, headers) => fetchWrapper("PUT", "/user/swapFavorite", body, {}, headers);

export const postFaucet = (body, headers) => fetchWrapper("POST", "/user/faucet", body, {}, headers);

export const getFeeGrantOptions = chainId => fetchWrapper("GET", `/misc/feeGrants?chainId=${chainId}`);

export const postFeeGrantTx = (body, chainId) => fetchWrapper("POST", "/misc/feeGrantTx?chainId=" + chainId, body);

export const updateUserNotifications = (body, headers) => fetchWrapper("PUT", "/user/notifications", body, {}, headers);

export const postFarmFavourite = (methodType, body, headers) =>
  fetchWrapper(methodType, "/user/farmFavorite", body, {}, headers);

// export const deleteFarmFavourite = (body, headers) => fetchWrapper("DELETE", "/user/farmFavorite", body, {}, headers);

export const downloadLogs = headers =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/user/downloadLogs`, {
    method: "GET",
    headers: {
      Accept: "text/csv",
      "Content-Type": "text/csv",
      Cache: "no-cache",
      ...headers,
    },
    credentials: "include",
  });
export const getUserComponentBalances = headers => fetchWrapper("GET", `/user/balances`, undefined, {}, headers);
