import BigNumber from "bignumber.js";
import { GasPrice, SigningStargateClient, calculateFee } from "@cosmjs/stargate";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import { updateNativeBalance } from "@axvdex/state/wallet/walletThunks";
import { IAsset, IChain } from "../interfaces";

export default async (
  dispatch: any,
  chains: { [key: string]: IChain },
  offerAsset: IAsset,
  offerAssetAmountRaw: string,
  askAsset: IAsset
) => {
  const options = {
    method: "POST",
    headers: { accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      amount_in: BigNumber(offerAssetAmountRaw).times(Math.pow(10, offerAsset.decimals)).decimalPlaces(0).toString(10),
      source_asset_denom: offerAsset.denom,
      source_asset_chain_id: offerAsset.contextChainId,
      dest_asset_denom: askAsset.denom,
      dest_asset_chain_id: askAsset.contextChainId,
      cumulative_affiliate_fee_bps: "0",
    }),
  };

  const skipRoute = await fetch("https://api.skip.money/v2/fungible/route", options);
  const routeResponse = await skipRoute.json();

  if (5 === routeResponse.code) throw Error("Unsupported IBC path");

  // get user addresses for messages
  const userAddresses = [];
  let ibcSignerSource = null;
  let sourceAddr = null;
  let ibcSignerTarget = null;
  let targetAddr = null;

  for (const chainID of routeResponse.chain_ids) {
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    let walletExtensionClient = window[walletExtension];

    if ("cosmostation" === walletExtension) {
      walletExtensionClient = window.cosmostation.providers.keplr;
    }

    if (chains[chainID]) {
      await walletExtensionClient.experimentalSuggestChain(chains[chainID]);
    }

    const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainID);
    const address = (await offlineSigner.getAccounts())[0].address;
    userAddresses.push(address);

    if (routeResponse.chain_ids[0] === chainID) {
      ibcSignerSource = await SigningStargateClient.connectWithSigner(chains[chainID].rpc, offlineSigner, {
        gasPrice: GasPrice.fromString(chains[chainID].defaultFee),
      });
      sourceAddr = address;
    }
    if (routeResponse.chain_ids[routeResponse.chain_ids.length - 1] === chainID) {
      ibcSignerTarget = await SigningStargateClient.connectWithSigner(chains[chainID].rpc, offlineSigner, {
        gasPrice: GasPrice.fromString(chains[chainID].defaultFee),
      });
      targetAddr = address;
    }
  }

  // construct msg request
  const msgRequest = {
    amount_in: routeResponse.amount_in,
    source_asset_denom: routeResponse.source_asset_denom,
    source_asset_chain_id: routeResponse.source_asset_chain_id,
    dest_asset_denom: routeResponse.dest_asset_denom,
    dest_asset_chain_id: routeResponse.dest_asset_chain_id,
    amount_out: routeResponse.amount_out,
    address_list: userAddresses,
    slippage_tolerance_percent: "1",
    client_id: "astrovault",
    operations: routeResponse.operations,
  };

  // request messages
  const skipMsgs = await fetch("https://api.skip.money/v2/fungible/msgs", {
    method: "POST",
    headers: { accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify(msgRequest),
  });
  const msgResponse = await skipMsgs.json();

  if (msgResponse.msgs.length !== 1) {
    throw new Error("Unsupported number of txs. Only single tx routes supported");
  }

  // SIGN AND SUBMIT TX
  const multiHopMsg = msgResponse.msgs[0];
  const msgJSON = JSON.parse(multiHopMsg.multi_chain_msg.msg);

  // case signing and transaction construction based on message type -- could be
  // ibc transfer or cosmwasm contract call
  if ("/ibc.applications.transfer.v1.MsgTransfer" === multiHopMsg.multi_chain_msg.msg_type_url) {
    const msg = {
      typeUrl: multiHopMsg.multi_chain_msg.msg_type_url,
      value: {
        sourcePort: msgJSON.source_port,
        sourceChannel: msgJSON.source_channel,
        token: msgJSON.token,
        sender: msgJSON.sender,
        receiver: msgJSON.receiver,
        timeoutTimestamp: msgJSON.timeout_timestamp,
        timeoutHeight: msgJSON.timeoutHeight,
        memo: msgJSON.memo,
      },
    };

    const tx = await(ibcSignerSource as SigningStargateClient).signAndBroadcast(
      msgJSON.sender,
      [msg],
      calculateFee(200000, chains[routeResponse.source_asset_chain_id].defaultFee),
      ""
    );

    const resTrack = await fetch("https://api.skip.money/v2/tx/track", {
      method: "POST",
      headers: { accept: "application/json", "content-type": "application/json" },
      body: JSON.stringify({
        tx_hash: tx.transactionHash,
        chain_id: routeResponse.source_asset_chain_id,
      }),
    });
    const trackResponse = await resTrack.json();
    if (!trackResponse?.tx_hash) throw new Error("Error tracking transaction");

    // eslint-disable-next-line no-constant-condition
    while (1) {
      // sleep for 5 seconds
      await new Promise(resolve => setTimeout(resolve, 5000));

      const resStatus = await fetch(
        `https://api.skip.money/v2/tx/status?tx_hash=${tx.transactionHash}&chain_id=${routeResponse.source_asset_chain_id}`,
        {
          method: "GET",
          headers: { accept: "application/json", "content-type": "application/json" },
        }
      );

      const resResponse = await resStatus.json();

      if ("STATE_COMPLETED_SUCCESS" === resResponse.state || "STATE_COMPLETED_ERROR" === resResponse.state) {
        const res = {
          status: resResponse.status,
          transfer_asset_release: resResponse.transfer_asset_release,
          error: resResponse.error,
        };
        const msg = "STATE_COMPLETED_SUCCESS" === resResponse.state ? "IBC transfer successful" : "IBC transfer failed";
        const toastType =
          "STATE_COMPLETED_SUCCESS" === resResponse.state ? "ibc-transfer-success" : "ibc-transfer-fail";
        const sendTxHash =
          resResponse.transfer_sequence?.length > 0
            ? resResponse.transfer_sequence[0].ibc_transfer?.packet_txs?.send_tx?.tx_hash
            : undefined;

        dispatch(
          sendToast({
            type: toastType,
            info: {
              msg,
              sendLink: chains[routeResponse.source_asset_chain_id].explorerURL + sendTxHash,
              toastID: "" + new Date().getTime(),
            },
          })
        );

        for (const assetBalanceToUpdate of [
          {
            signer: ibcSignerSource,
            denom: msgRequest.source_asset_denom,
            userAddress: sourceAddr,
          },
          {
            signer: ibcSignerTarget,
            denom: msgRequest.dest_asset_denom,
            userAddress: targetAddr,
          },
        ]) {
          dispatch(
            updateNativeBalance({
              client: assetBalanceToUpdate.signer,
              denom: assetBalanceToUpdate.denom,
              userAddress: assetBalanceToUpdate.userAddress,
            })
          );
        }

        return res;
      }
    }
  }
};
