import { useState } from "react";
import clsx from "clsx";
import { LogOut } from "react-feather";
import { useMediaQuery } from "usehooks-ts";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectChains, selectGlobalConfig, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { disconnectChainWithDispatch, retryWalletWithDispatch } from "@axvdex/state/wallet/walletThunks";
import { useGlobalModalContext } from "@axvdex/providers/GlobalModalProvider";
import { AssetChainLogo } from "@axvdex/utils/chains";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import { responsiveBreakpoints } from "@axvdex/constants";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/SupportedChainsModal.module.scss";
import CustomLoader from "../common/CustomLoader";

function SupportedChainsModal() {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const chains = useAppSelector(selectChains);
  const walletInfo = useAppSelector(selectWalletInfo);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const { modal, hideModal } = useGlobalModalContext();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);

  const [isChainConnectionLoading, setIsChainConnectionLoading] = useState([]);

  return (
    <CustomModal
      isOpen={"supportedChainsModal" === modal.modalType && modal.isOpen}
      onClose={hideModal}
      extraClassName={clsx(styles.supportedChainsModal, "supportedChainsModal")}
      modalSize="md"
    >
      <section className="sectionModalHeader">
        <h2 className="h2">{i18("Supported Chains", "supportedChainsModal.title")}</h2>
      </section>

      <section className="sectionModalContent withGradientBorderBottom">
        <span>{i18("Cosmos Ecosystem", "supportedChainsModal.title")}</span>
      </section>
      <section className="sectionModalContent withGradientBorderBottom">
        {globalConfig?.chainContexts.map((chainContext, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                // placeContent: walletInfo.connectedChains[chainContext.chainId]?.signingStargateClient
                //   ? "space-between"
                //   : undefined,
                // gap: walletInfo.connectedChains[chainContext.chainId]?.signingStargateClient ? undefined : "1.5em",
                marginTop: index !== 0 ? "1.5em" : undefined,
              }}
            >
              <AssetChainLogo
                chain={chains[chainContext.chainId]}
                noText={true}
                imgStyle={{ width: "1.25em", height: "1.5em" }}
              />
              <div style={{ width: "5em", marginLeft: "1.5em", marginRight: "1.15em" }}>
                <span>{chains[chainContext.chainId].displayName}</span>
              </div>
              {walletInfo.connectedChains[chainContext.chainId]?.signingStargateClient && (
                <span
                  title={i18(
                    `Copy ${chains[chainContext.chainId].displayName} address to clipboard`,
                    "userWallet.copyToClipboard.title",
                    {
                      chainName: chains[chainContext.chainId].displayName,
                    }
                  )}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(walletInfo.connectedChains[chainContext.chainId].address);
                    dispatch(
                      sendToast({
                        type: "info",
                        noStore: true,
                        info: {
                          msg: i18("Address copied to clipboard", "toast.addressCopied.text"),
                          toastID: "" + new Date().getTime(),
                        },
                      })
                    );
                  }}
                >
                  {
                    // for mobile get only the first 10 chars and last 5 chars
                    isMobileBreakpoint
                      ? `${walletInfo.connectedChains[chainContext.chainId].address.slice(
                          0,
                          10
                        )}...${walletInfo.connectedChains[chainContext.chainId].address.slice(-5)}`
                      : walletInfo.connectedChains[chainContext.chainId].address
                  }
                </span>
              )}
              {walletInfo.connectedChains[chainContext.chainId]?.signingStargateClient ? (
                <Button
                  style={{ height: "2em", color: "var(--red)", marginLeft: "auto" }}
                  extraClassName="btnDisconnectWallet"
                  title={`Disconnect from ${chains[chainContext.chainId].displayName}`}
                  btnVariant="icon"
                  icon={<LogOut />}
                  onClick={() => {
                    dispatch(disconnectChainWithDispatch({ chainToDisconnect: chains[chainContext.chainId] }));
                  }}
                />
              ) : isChainConnectionLoading.includes(chainContext.chainId) ? (
                <div style={{ width: "100%", textAlign: "center", alignContent: "center" }}>
                  <CustomLoader size="xss" />
                </div>
              ) : (
                <Button
                  style={{ width: "80%", minHeight: "1.5em", height: "2em", marginRight: ".75em " }}
                  btnColor="gradient"
                  title={i18("Claim Airdrop", "dashboard.airdrop.buttonTitle")}
                  onClick={async () => {
                    if (!isChainConnectionLoading.includes(chainContext.chainId)) {
                      setIsChainConnectionLoading(isChainConnectionLoading.concat(chainContext.chainId));
                      await dispatch(
                        retryWalletWithDispatch({
                          chainToConnect: chains[chainContext.chainId],
                        })
                      );
                      setIsChainConnectionLoading(isChainConnectionLoading.filter(id => id !== chainContext.chainId));
                    }
                  }}
                >
                  <span>Connect</span>
                </Button>
              )}
            </div>
          );
        })}
      </section>
    </CustomModal>
  );
}

export default SupportedChainsModal;
