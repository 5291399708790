import { configureStore } from "@reduxjs/toolkit";
// import { ListenerEffectAPI } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import walletReducer from "state/wallet/walletSlice";
import appReducer from "state/app/appSlice";
import listenerMiddleware from "state/listenerMiddleware";

export const store = configureStore({
  reducer: { wallet: walletReducer, app: appReducer },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AsyncThunkConfig = { state: RootState; dispatch: AppDispatch };
