import { useEffect, useState } from "react";
import clsx from "clsx";
import { Bell, CheckCircle, ExternalLink, Trash } from "react-feather";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import useLanguage from "@axvdex/hooks/useLanguage";
import { selectAssets, selectContracts, selectUser, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { updateUserNotifications } from "@axvdex/api/user";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { handleAuthentication, handleClaimUnmint } from "@axvdex/state/wallet/walletThunks";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/MyAssetsManageTokensModal.module.scss";
import DashboardPaginationTable from "../DashboardPaginationTable";

function NotificationsModal({ hideInfo }: any) {
  const { i18 } = useLanguage();
  const user = useAppSelector(selectUser);
  const contracts = useAppSelector(selectContracts);
  const assets = useAppSelector(selectAssets);
  const [isOpen, setIsOpen] = useState(false);
  const [readyUnmints, setReadyUnmints] = useState([]);

  useEffect(() => {
    if (user?.myAssetUnmints && Object.keys(contracts).length > 0 && Object.keys(user.myAssetUnmints).length > 0) {
      const readyUnmints = [];
      for (const unmintAssetId of Object.keys(user.myAssetUnmints)) {
        if (user.myAssetUnmints[unmintAssetId].readyUnmints.length > 0) {
          // get window detail, so we can get the timestamp when the unmint was completed
          readyUnmints.push({
            timestamp: Math.max.apply(0, user.myAssetUnmints[unmintAssetId].readyTimestamps),
            asset: assets[unmintAssetId],
            amount: user.myAssetUnmints[unmintAssetId].readyUnmints
              .reduce((acc, curr) => BigNumber(curr).plus(acc), BigNumber(0))
              .div(Math.pow(10, assets[unmintAssetId].decimals))
              .toString(10),
          });
        }
      }
      setReadyUnmints(readyUnmints);
    }
  }, [user, contracts]);

  return (
    <>
      <div style={{ width: "100%", position: "relative", display: "inline-block" }}>
        <Button
          disabled={!user.notifications || (0 === user.notifications.length && 0 === readyUnmints.length)}
          extraClassName="btnNotifications"
          btnSize="sm"
          btnColor="gradient"
          isFullWidth={true}
          title={i18("Notifications", "userWallet.notifications.title")}
          icon={
            <span className={"bellIcon"}>
              <Bell />
            </span>
          }
          onClick={() => setIsOpen(true)}
        >
          {!hideInfo && <>{i18("Notifications", "userWallet.notifications.title")}</>}
          {user.notifications && 0 < user.notifications.length && (
            <span className="notificationBadge">{user.notifications.length}</span>
          )}
          {user && readyUnmints.length > 0 && (
            <span className="notificationBadgeUnmint">
              <CheckCircle />
            </span>
          )}
        </Button>
      </div>

      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        extraClassName={clsx(styles.permitAuthenticationModal, "permitAuthenticationModal")}
      >
        <section className="sectionModalHeader" style={{ marginBottom: "1em" }}>
          <h2 className="h2">{i18("Notifications", "userWallet.notifications.title")}</h2>
        </section>

        <NotificationsSection setIsOpen={setIsOpen} readyUnmints={readyUnmints} />
      </CustomModal>
    </>
  );
}

const NotificationsSection = ({ setIsOpen, readyUnmints }: any) => {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const walletInfo = useAppSelector(selectWalletInfo);
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const [isLoading, setIsLoading] = useState(false);
  const removeNotificationHandler = async (indexToRemove: number) => {
    setIsLoading(true);

    const updatedNotifications = [...user.notifications];
    updatedNotifications.splice(indexToRemove, 1);

    await updateUserNotifications(
      { notifications: updatedNotifications },
      {
        pubkey: walletInfo.pubKey,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
      }
    );

    await dispatch(handleAuthentication());
    setIsLoading(false);
  };

  const unmintClaimHandler = async row => {
    setIsLoading(true);
    await dispatch(handleClaimUnmint({ derivativeAsset: row.original.extraDetails.asset, i18 }));
    setIsLoading(false);
  };

  useEffect(() => {
    const readyUnmintsNotification = readyUnmints.map(unmint => ({
      type: "readyUnmint",
      timestamp: unmint.timestamp * 1000,
      extraDetails: unmint,
    }));
    const notifications = [...readyUnmintsNotification, ...user.notifications]
      .map((notification: any, index) => {
        let msg = null;
        let actions = [];
        // Parse notification msg depending on type
        if ("saleVestingComplete" === notification.type) {
          msg = i18(
            `${notification.extraDetails.projectName} Sale ${notification.extraDetails.saleID} vesting complete`,
            "userWallet.notifications.saleVestingCompleteMessage",
            {
              projectName: notification.extraDetails.projectName,
              saleID: notification.extraDetails.saleID,
            }
          );
          actions = ["link", "delete"];
        }

        if ("readyUnmint" === notification.type) {
          msg = i18(
            `Unmint of ${notification.extraDetails.amount} ${notification.extraDetails.asset.symbol} is ready to claim.`,
            "userWallet.notifications.readyUnmintMessage"
          );
          actions = ["claimUnmint"];
        }

        return {
          timestamp: notification.timestamp,
          msg,
          extraDetails: notification.extraDetails,
          originalPosition: index,
          isLoading,
          removeNotificationHandler,
          unmintClaimHandler,
          actions,
        };
      })
      .sort((a, b) => b.timestamp - a.timestamp);

    setData(notifications);
  }, [user, isLoading]);

  const messagesColumns: ColumnDef<{
    timestamp: number;
    msg: string;
    extraDetails: any;
    originalPosition: number;
    isLoading: boolean;
    removeNotificationHandler: any;
    unmintClaimHandler: any;
    actions: string[];
  }>[] = [
    {
      accessorKey: "date",
      header: () => <span>{i18("Date", "userWallet.notifications.date")}</span>,
      cell: ({ row }) => (
        <time>
          {new Date(row.original.timestamp).toLocaleDateString()}
          <br />
          {new Date(row.original.timestamp).toLocaleTimeString()}
        </time>
      ),
    },
    {
      id: "message",
      header: () => <span>{i18("Message", "userWallet.notifications.message")}</span>,
      cell: ({ row }) => <span>{row.original.msg}</span>,
    },
    {
      id: "actions",
      header: () => <span>{i18("Actions", "userWallet.notifications.actions")}</span>,
      cell: ({ row }) => (
        <div style={{ display: "flex", placeContent: "space-between" }}>
          {row.original.actions.includes("link") && row.original.extraDetails && (
            <Button
              style={{ padding: "1em", marginRight: "1em" }}
              disabled={row.original.isLoading}
              extraClassName="btnLink"
              btnSize="sm"
              btnColor="dark-medium"
              title={i18("Link", "userWallet.notifications.link")}
              icon={
                <span className={"linkIcon"}>
                  <ExternalLink />
                </span>
              }
              onClick={() => {
                navigate(
                  "/outbid/" + row.original.extraDetails.publicSaleLabel + "/" + row.original.extraDetails.saleID
                );
                setIsOpen(false);
              }}
            ></Button>
          )}
          {row.original.actions.includes("delete") && (
            <Button
              style={{ padding: "1em" }}
              extraClassName="btnLink"
              disabled={row.original.isLoading}
              btnSize="sm"
              btnColor="dark-medium"
              title={i18("Delete", "userWallet.notifications.delete")}
              icon={
                <span className={"linkIcon"}>
                  <Trash />
                </span>
              }
              onClick={async () => {
                await row.original.removeNotificationHandler(row.original.originalPosition);
              }}
            ></Button>
          )}
          {row.original.actions.includes("claimUnmint") && (
            <Button
              style={{ padding: "1em", fontSize: "1em" }}
              extraClassName="btnLink"
              disabled={row.original.isLoading}
              btnSize="sm"
              btnColor="dark-medium"
              title={i18("Claim unmint", "userWallet.notifications.unmintClaim")}
              onClick={async () => {
                await row.original.unmintClaimHandler(row);
              }}
            >
              <CheckCircle style={{ color: "#186218" }} />
              Claim
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {!user.notifications || (0 === user.notifications.length && 0 === readyUnmints.length) ? (
        <>
          <span>{i18("No notifications...", "dashboard.history.tabs.messages.placeholder")}</span>
        </>
      ) : (
        <DashboardPaginationTable
          tableId="myNotificationsTable"
          customData={data}
          customColumns={messagesColumns}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </div>
  );
};

export default NotificationsModal;
