import { Coin, SigningStargateClient } from "@cosmjs/stargate";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { IWalletConnectedChainInfo } from "@axvdex/state/wallet/initialState";
import { IAsset, IChain, IContract, IPool } from "../interfaces";
import simulateTransfer from "./simulateTransfer";
import simulateSwap from "./simulateSwap";
import simulateIbcTransfer from "./simulateIbcTransfer";
import simulateCrosschainSwap from "./simulateCrosschainSwap";

export const simulation = (
  allChains: {
    [key: string]: IChain;
  },
  allPools: {
    [key: string]: IPool;
  },
  allAssets: {
    [key: string]: IAsset;
  },
  allContracts: {
    [key: string]: IContract;
  },
  estimatedFeesReference: {
    estimatedFee: Coin[];
    gasLimit: number;
  } | null,
  hops: {
    [assetX: string]: {
      [assetY: string]: string;
    };
  },
  offerAsset: IAsset,
  offerAssetWalletChainContext: IWalletConnectedChainInfo | undefined,
  offerAssetAmountRaw: string | null,
  askAsset: IAsset,
  askAssetWalletChainContext: IWalletConnectedChainInfo | undefined,
  extraSettings?: {
    slippageTolerance?: number;
    maxHops?: number;
    toAddress?: string;
  }
): ISimulationReturn => {
  if (!offerAssetAmountRaw) {
    throw new Error("No offerAssetAmount specified");
  }

  // find the appropriate action to take based on the offerAsset and askAsset and their chains
  // tokens && chains && actions
  // ===========================
  // 1. assetIn === assetOut && chainIn === chainOut => transfer
  // 2. assetIn === assetOut && chainIn !== chainOut => ibcTransfer
  // 3. assetIn !== assetOut && chainIn === chainOut => swap
  // 4. assetIn !== assetOut && chainIn !== chainOut => crosschainSwap

  let actionType: string;
  let result;
  if (offerAsset.symbol === askAsset.symbol) {
    if (allChains[offerAsset.contextChainId].chainId === allChains[askAsset.contextChainId].chainId) {
      actionType = "transfer";
      result = simulateTransfer(offerAsset, offerAssetAmountRaw, extraSettings?.toAddress);
    } else {
      actionType = "ibcTransfer";
      result = simulateIbcTransfer(
        allChains,
        allContracts,
        offerAssetWalletChainContext,
        offerAsset,
        offerAssetAmountRaw,
        askAsset,
        askAssetWalletChainContext,
        extraSettings?.toAddress,
        estimatedFeesReference
      );
    }
  } else if (allChains[offerAsset.contextChainId].chainId === allChains[askAsset.contextChainId].chainId) {
    actionType = "swap";
    result = simulateSwap(
      offerAssetWalletChainContext,
      allPools,
      allAssets,
      allContracts,
      estimatedFeesReference,
      hops,
      offerAsset,
      offerAssetAmountRaw,
      askAsset,
      extraSettings?.slippageTolerance,
      extraSettings?.maxHops
    );
  } else {
    actionType = "crosschainSwap";
    result = simulateCrosschainSwap(
      offerAssetWalletChainContext,
      askAssetWalletChainContext,
      allPools,
      allAssets,
      allContracts,
      allChains,
      estimatedFeesReference,
      hops,
      offerAsset,
      offerAssetAmountRaw,
      askAsset,
      extraSettings?.slippageTolerance
    );
  }

  if (!actionType) {
    throw new Error("Action type not found");
  }

  return {
    actionType,
    ...result,
  };
};

export const simulatedActionToHumanReadable = (actionType, i18) => {
  switch (actionType) {
    case "transfer":
      return i18("Send", "trade.action.transfer");
    case "ibcTransfer":
      return i18("Move", "trade.action.ibcTransfer");
    case "swap":
      return i18("Trade", "trade.action.swap");
    case "crosschainSwap":
      return i18("Crosschain Swap", "trade.action.crosschainSwap");
  }
};

export interface ISimulationReturn {
  actionType: string;
  offerAmount: string;
  offerAmountInUSD: number;
  askAmount: string;
  askAmountInUSD: number;
  error?: {
    type: string;
    msg: string;
    buttonText?: string;
    data: any;
  };
  minimumReceived?: string;
  minimumReceivedInUSD?: number;
  offerToAskRatio?: string;
  askToOfferRatio?: string;
  feePer?: string;
  feeInUSD?: number;
  expectedCashbackAmount?: string;
  expectedCashbackAXVAmount?: string;
  expectedCashbackInUSD?: number;
  marketImpactPer?: string;
  priceImpactPer?: string;
  route?: {
    p: string;
    x: string;
    y: string;
    askAmount: bigint;
    offerAmount: bigint;
    feeAmount: bigint;
    expectedCashbackMinted: bigint;
    spreadAmount: bigint;
    derivativeOperation: string;
    hopTx: {
      mint_staking_derivative: {
        contract_addr: string;
        offer_asset: {
          native_token: { denom: string };
        };
        ask_asset: {
          token: {
            contract_addr: string;
          };
        };
      };
    };
    funds: { denom: string; amount: string }[];
  }[];
  tx?: {
    contract?: {
      toExecuteContract: string;
      msgBody: any;
      funds: { denom: string; amount: string }[];
    };
    nativeTransfer?: {
      toAddress: string;
      amount: Coin;
    };
    ibcOperatorAction?: {
      sendingClient: SigningCosmWasmClient;
      sendingAddrInput: string;
      toExecuteContract: string;
      msgBody: any;
      srcChain: {
        explorerURL: string;
        chainId: string;
        restURL: string;
        rpcURL: string;
        isEVM: boolean;
      };
      dstChain: {
        explorerURL: string;
        chainId: string;
        restURL: string;
        rpcURL: string;
        isEVM: boolean;
      };
      assetBalancesToUpdate?: [
        {
          client: SigningCosmWasmClient;
          userAddress: string;
          tokens: string[];
          natives: string[];
        }
      ];
    };
    ibcTransfer?: {
      sendingClient: SigningStargateClient;
      sendingAddrInput: string;
      destUserAddress: string;
      denomToSend: string;
      amount: string;
      channel: string;
      fee: {
        denom: string;
        amount: string;
      };
      srcChain: {
        explorerURL: string;
        chainId: string;
        restURL: string;
        rpcURL: string;
        isEVM: boolean;
      };
      dstChain: {
        explorerURL: string;
        chainId: string;
        restURL: string;
        rpcURL: string;
        isEVM: boolean;
      };
      assetBalancesToUpdate?: [
        {
          client: SigningCosmWasmClient;
          userAddress: string;
          tokens: string[];
          natives: string[];
        }
      ];
      cw20_ics20?: {
        ibcRecvChannel: string;
        ibcSendChannel: string;
        cw20Address: string;
        ics20Address: string;
        chainId: string;
        ics20CodeHash?: string;
      };
    };
  };
}
