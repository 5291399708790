import BigNumber from "bignumber.js";
import { Coin, GasPrice } from "@cosmjs/stargate";
import { IWalletConnectedChainInfo } from "@axvdex/state/wallet/initialState";
import { IAsset, IChain, IContract } from "../interfaces";

export default (
  allChains: {
    [key: string]: IChain;
  },
  allContracts: {
    [key: string]: IContract;
  },
  offerAssetWalletChainContext: IWalletConnectedChainInfo | undefined,
  offerAsset: IAsset,
  offerAssetAmountRaw: string,
  askAsset: IAsset,
  askAssetWalletChainContext: IWalletConnectedChainInfo | undefined,
  toAddress: string | undefined,
  estimatedFeesReference: {
    estimatedFee: Coin[];
    gasLimit: number;
  } | null
) => {
  const srcChain = allChains[offerAsset.contextChainId];
  const destChain = allChains[askAsset.contextChainId];
  const denomFoundOnSrcChain = srcChain.ibcDenoms.find(ibcDenoms => askAsset.denom == ibcDenoms.base_denom);
  const denomFoundOnDestChain = destChain.ibcDenoms.find(ibcDenoms => offerAsset.denom == ibcDenoms.base_denom);
  const channel = denomFoundOnSrcChain
    ? denomFoundOnSrcChain.this_chain_channel_id
    : denomFoundOnDestChain
    ? denomFoundOnDestChain.base_chain_channel_id
    : undefined;

  let feeAmount = "0";

  if (!channel) {
    // ibc transfer default channel not found, try to find a channel via ibc-operator from Astrovault
    // this ibc-operator is a contract that handles IBC transfers for specific cw20 tokens that require mint/burn mechanics
    const ibcOperator = allContracts["ibc-operator_" + offerAsset.contextChainId];
    if (ibcOperator && ibcOperator.config) {
      const channelFound = [...ibcOperator.config.channels]
        .reverse() // reverse to get latest created channel
        .find(
          channel =>
            (channel.cw20_mappings && Object.keys(channel.cw20_mappings).includes(offerAsset.id)) ||
            (channel.native_mappings && Object.keys(channel.native_mappings).includes(offerAsset.id))
        );
      if (channelFound) {
        // as channel exists on the ibc-operator, check if the asset is allowed to be used
        const assetSwapConfig = ibcOperator.config.swap_config?.allowed_assets.find(
          asset => asset.asset_id === offerAsset.id
        )?.asset_fee_config;
        if (assetSwapConfig) {
          // for direct ibc transfers via ibc-operator, the raw fee is reduced to 10% of the fee amount
          if (assetSwapConfig.flat_fee_amount)
            feeAmount = BigNumber(assetSwapConfig.flat_fee_amount)
              .times(0.1)
              .div(Math.pow(10, offerAsset.decimals))
              .toString(10);
          if (assetSwapConfig.flat_fee_usd) {
            const cashbackMinter = allContracts["cashback-minter_" + offerAsset.contextChainId];
            const ratio =
              cashbackMinter.extraFields.assets.find(asset => asset.id === offerAsset.address)?.ratio ||
              BigNumber(offerAsset.price * 1000000)
                .decimalPlaces(0)
                .toString(10);
            // to calc fee in USD, we need to use cashback minter ratios
            const feeAmountRaw = BigNumber(assetSwapConfig.flat_fee_usd.usd_amount)
              .div(BigNumber(ratio).div(100000000))
              .times(Math.pow(10, offerAsset.decimals))
              .decimalPlaces(0)
              .toString(10);
            feeAmount = BigNumber(feeAmountRaw).times(0.1).div(Math.pow(10, offerAsset.decimals)).toString(10);
          }
        } else {
          // wallet not allowed as it does not exist in the allowed_assets list
          return {
            offerAsset,
            askAsset,
            feeAmount,
            offerAmount: offerAssetAmountRaw,
            offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
            askAmount: "0",
            askAmountInUSD: 0,
            error: {
              type: "ibcTransfer",
              message: "IBC Transfer: Unsupported IBC Asset Fee",
              data: null,
            },
            tx: null,
          };
        }

        return {
          offerAsset,
          askAsset,
          feeAmount,
          offerAmount: offerAssetAmountRaw,
          offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
          askAmount: BigNumber(feeAmount).gt(offerAssetAmountRaw)
            ? "0"
            : BigNumber(offerAssetAmountRaw).minus(feeAmount).toString(10),
          askAmountInUSD: BigNumber(
            BigNumber(feeAmount).gt(offerAssetAmountRaw)
              ? "0"
              : BigNumber(offerAssetAmountRaw).minus(feeAmount).toString(10)
          )
            .times(offerAsset.price)
            .toNumber(),
          tx: {
            ibcOperatorAction: {
              sendingClient: offerAssetWalletChainContext?.signingClient,
              sendingAddrInput: offerAssetWalletChainContext?.address,
              toExecuteContract: offerAsset.isNative ? ibcOperator.address : offerAsset.address,
              srcChain: {
                explorerURL: srcChain.explorerURL,
                chainId: srcChain.chainId,
                restURL: srcChain.rest,
                rpcURL: srcChain.rpc,
                isEVM: srcChain.isEVM,
              },
              dstChain: {
                explorerURL: destChain.explorerURL,
                chainId: destChain.chainId,
                restURL: destChain.rest,
                rpcURL: destChain.rpc,
                isEVM: destChain.isEVM,
              },
              assetBalancesToUpdate: [
                {
                  client: offerAssetWalletChainContext?.signingClient,
                  userAddress: offerAssetWalletChainContext?.address,
                  tokens: offerAsset.address ? [offerAsset.address] : [],
                  natives: offerAsset.denom ? [offerAsset.denom] : [],
                },
                {
                  client: askAssetWalletChainContext?.signingClient,
                  userAddress: toAddress || askAssetWalletChainContext?.address,
                  tokens: askAsset.address ? [askAsset.address] : [],
                  natives: askAsset.denom ? [askAsset.denom] : [],
                },
              ],
              msgBody: offerAsset.isNative
                ? {
                    ibc_cross_swap: {
                      ibc_channel_id: channelFound.id,
                      target_dst_address: toAddress || askAssetWalletChainContext?.address,
                    },
                  }
                : {
                    send: {
                      contract: ibcOperator.address,
                      amount: BigNumber(offerAssetAmountRaw)
                        .times(Math.pow(10, offerAsset.decimals))
                        .decimalPlaces(0)
                        .toString(10),
                      msg: Buffer.from(
                        JSON.stringify({
                          ibc_cross_swap: {
                            ibc_channel_id: channelFound.id,
                            target_dst_address: toAddress || askAssetWalletChainContext?.address,
                          },
                        })
                      ).toString("base64"),
                    },
                  },
              funds: offerAsset.isNative
                ? [
                    {
                      denom: offerAsset.denom,
                      amount: BigNumber(offerAssetAmountRaw)
                        .times(Math.pow(10, offerAsset.decimals))
                        .decimalPlaces(0)
                        .toString(10),
                    },
                  ]
                : [],
            },
          },
        };
      }
    }

    return {
      offerAsset,
      askAsset,
      feeAmount,
      offerAmount: offerAssetAmountRaw,
      offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
      askAmount: offerAssetAmountRaw,
      askAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
      error: {
        type: "ibcTransfer",
        message: "IBC Transfer: Unsupported IBC path",
        data: null,
      },
      tx: null,
    };
  }

  return {
    offerAsset,
    askAsset,
    feeAmount,
    offerAmount: offerAssetAmountRaw,
    offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
    askAmount: offerAssetAmountRaw,
    askAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
    tx: {
      ibcTransfer: {
        sendingClient: offerAssetWalletChainContext?.signingClient,
        sendingAddrInput: offerAssetWalletChainContext?.address,
        destUserAddress: toAddress || askAssetWalletChainContext?.address,
        denomToSend: offerAsset.denom,
        amount: BigNumber(offerAssetAmountRaw).times(Math.pow(10, offerAsset.decimals)).decimalPlaces(0).toString(10),
        channel,
        fee: estimatedFeesReference
          ? estimatedFeesReference.estimatedFee[0]
          : {
              amount: BigNumber(GasPrice.fromString(srcChain.defaultFee).amount.toString())
                .times(
                  Math.pow(
                    10,
                    srcChain.feeCurrencies.find(
                      feeCurrency => feeCurrency.coinMinimalDenom === GasPrice.fromString(srcChain.defaultFee).denom
                    ).coinDecimals
                  )
                )
                .toString(10),
              denom: GasPrice.fromString(srcChain.defaultFee).denom,
            },
        srcChain: {
          chainId: srcChain.chainId,
          restURL: srcChain.rest,
          explorerURL: srcChain.explorerURL,
          rpcURL: srcChain.rpc,
          isEVM: srcChain.isEVM,
        },
        dstChain: {
          chainId: destChain.chainId,
          restURL: destChain.rest,
          explorerURL: destChain.explorerURL,
          rpcURL: destChain.rpc,
          isEVM: destChain.isEVM,
        },
        assetBalancesToUpdate: [
          {
            client: offerAssetWalletChainContext?.signingClient,
            userAddress: offerAssetWalletChainContext?.address,
            tokens: [],
            natives: [offerAsset.denom],
          },
          {
            client: askAssetWalletChainContext?.signingClient,
            userAddress: toAddress || askAssetWalletChainContext?.address,
            tokens: [],
            natives: [askAsset.denom],
          },
        ],
        cw20_ics20: offerAsset.denom_trace?.cw20_ics20 || askAsset.denom_trace?.cw20_ics20 || undefined,
      },
    },
  };
};
