import BigNumber from "bignumber.js";
import { denormalize_amount, normalize_amount } from "@axvdex/utils/formatNumber";

export const computeStableSwap = (pool: any, hop: any, amount: bigint) => {
  const offerAssetIndex = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.x;
    }
    return asset.info.native_token.denom === hop.x;
  });

  const askAssetIndex = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.y;
    }
    return asset.info.native_token.denom === hop.y;
  });

  const normalized_from_asset_amount = normalize_amount(amount.toString(), pool.assetDecimals[offerAssetIndex]);

  const fromPoolAmount = BigInt(pool.poolAssets[offerAssetIndex].amount) + BigInt(amount);

  const normalized_from_asset_pool_amount = normalize_amount(
    fromPoolAmount.toString(),
    pool.assetDecimals[offerAssetIndex]
  );

  const normalized_to_asset_pool_amount = normalize_amount(
    pool.poolAssets[askAssetIndex].amount,
    pool.assetDecimals[askAssetIndex]
  );

  const B = normalized_to_asset_pool_amount - normalized_from_asset_amount;
  const unit_precision = BigInt(10000);
  let fee_per = BigInt(1000000);

  if (B > BigInt(0)) {
    // (S/B)
    const a = (normalized_from_asset_pool_amount * unit_precision) / B;
    // (S/B)^3
    //let b = (a ** BigInt(3) * unit_precision) / unit_precision ** BigInt(3);
    const b = (a * a * a * unit_precision) / (unit_precision * unit_precision * unit_precision);

    // 0.025 x (S/B)^3
    const c = BigInt(pool.settings.swap?.a_fee_param || 0) * b; // 0.025 => 250 with precision of 4 => after mul precision is 8!

    // 0.03 + 0.025 x (S/B)^3
    const d = BigInt(pool.settings.swap?.b_fee_param || 0) * unit_precision + c; // 0.03 => 3000000 with precision of 8

    const fee_pre4 = d / unit_precision; // back to precision of 4

    // use % that is lower than 100%
    if (fee_pre4 < fee_per) {
      fee_per = fee_pre4;
    }
  }

  const normalized_fee_amount = ((normalized_from_asset_amount / unit_precision) * fee_per) / BigInt(100);
  const normalized_to_asset_amount = normalized_from_asset_amount - normalized_fee_amount;

  const fee_amount = denormalize_amount(normalized_fee_amount.toString(), pool.assetDecimals[askAssetIndex]);

  const to_asset_amount = denormalize_amount(normalized_to_asset_amount.toString(), pool.assetDecimals[askAssetIndex]);

  return {
    returnAmount: BigInt(to_asset_amount.toString()),
    feeAmount: BigInt(fee_amount.toString()),
    swap_to_asset_amount: BigInt(to_asset_amount.toString()),
    normalized_to_asset_amount: normalized_to_asset_amount,
    normalized_fee_amount: normalized_fee_amount,
  };
};

export const computeStableSwapXAssetMode = (pool: any, hop: any, amount: bigint) => {
  const offerAssetIndex = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.x;
    }
    return asset.info.native_token.denom === hop.x;
  });

  const askAssetIndex = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.y;
    }
    return asset.info.native_token.denom === hop.y;
  });

  const normalized_from_asset_amount = normalize_amount(amount.toString(), pool.assetDecimals[offerAssetIndex]);

  const fromPoolAmount = BigInt(pool.poolAssets[offerAssetIndex].amount) + BigInt(amount);

  const normalized_from_asset_pool_amount = normalize_amount(
    fromPoolAmount.toString(),
    pool.assetDecimals[offerAssetIndex]
  );

  const normalized_to_asset_pool_amount = normalize_amount(
    pool.poolAssets[askAssetIndex].amount,
    pool.assetDecimals[askAssetIndex]
  );

  // item 0 index is the main asset
  // item 1 index is the xAsset

  // going from xAsset to main asset
  if (offerAssetIndex === 1 && askAssetIndex === 0) {
    //         // if the trade is coming from xAsset => Native Asset the fee is calculated
    //         // Fee calc => 0.0012+0.002*(Pin/(1-Pin))^2
    //         // to calc total pool amount it's the "from" asset pool amount (minus the amount sent by the user) + to asset pool amount
    const total_pool_amount =
      normalized_from_asset_pool_amount - normalized_from_asset_amount + normalized_to_asset_pool_amount;

    const p_in = BigNumber(normalized_from_asset_pool_amount.toString()).div(total_pool_amount.toString(10));
    const p_out = BigNumber("1").minus(p_in);
    let fee_per;
    if (normalized_from_asset_amount > normalized_to_asset_pool_amount) {
      fee_per = BigNumber("1");
    } else if (p_out !== BigNumber("0")) {
      const p_ratio = BigNumber(p_in).div(p_out);

      const p = p_ratio.pow(2);
      const param_a = pool.settings?.xasset_mode_settings?.swap_formula_param_a || "0.0012";
      const param_b = pool.settings?.xasset_mode_settings?.swap_formula_param_b || "0.002";
      const fee = BigNumber(param_a).plus(BigNumber(param_b).times(p));
      if (fee >= BigNumber("1")) {
        fee_per = BigNumber("1");
      } else {
        fee_per = fee;
      }
    } else {
      fee_per = BigNumber("1");
    }

    const normalized_fee_amount = BigNumber(normalized_from_asset_amount.toString(10))
      .times(fee_per)
      .decimalPlaces(0, BigNumber.ROUND_FLOOR);
    const normalized_to_asset_amount = BigNumber(normalized_from_asset_amount.toString(10)).minus(
      normalized_fee_amount
    );

    const fee_amount = denormalize_amount(normalized_fee_amount.toString(10), pool.assetDecimals[offerAssetIndex]);

    const swap_to_asset_amount = denormalize_amount(
      normalized_to_asset_amount.toString(10),
      pool.assetDecimals[askAssetIndex]
    );

    return {
      returnAmount: BigInt(swap_to_asset_amount.toString(10)),
      swap_to_asset_amount: swap_to_asset_amount.toString(10),
      feeAmount: BigInt(fee_amount.toString()),
    };
  }

  // going from main asset to xAsset
  if (offerAssetIndex === 0 && askAssetIndex === 1) {
    // if the trade is coming from Main Asset => xAsset the fee is 0, and we can do partial swap + mint
    const fee_amount = "0";
    let mint_amount = "0";

    const swap_ratio = pool.settings?.xasset_mode_settings?.swap_ratio || "1";
    const mint_ratio = pool.settings?.xasset_mode_settings?.mint_ratio || "1";

    const normalized_swap_to_asset_amount = BigInt(
      BigNumber(normalized_from_asset_amount.toString())
        .div(swap_ratio)
        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
        .toString(10)
    );

    let swap_to_asset_amount = denormalize_amount(
      normalized_swap_to_asset_amount.toString(10),
      pool.assetDecimals[askAssetIndex]
    );

    // if pool amount of xAsset is smaller than the amount requested, this pool sends the amount of xAssets it has + mint the rest
    if (normalized_to_asset_pool_amount < normalized_swap_to_asset_amount) {
      let extra_mint_amount = "0";

      if (BigNumber(swap_ratio).lte("1")) {
        extra_mint_amount = BigNumber(normalized_to_asset_pool_amount.toString())
          .div(swap_ratio)
          .decimalPlaces(0, BigNumber.ROUND_FLOOR)
          .minus(normalized_to_asset_pool_amount.toString())
          .toString(10);
      } else {
        throw new Error("Unimplemented for swap ratios above 1!");
      }

      mint_amount = denormalize_amount(
        BigNumber(
          (
            BigInt((normalized_from_asset_amount - normalized_to_asset_pool_amount).toString(10)) +
            BigInt(extra_mint_amount)
          ).toString(10)
        )
          .times(mint_ratio)
          .decimalPlaces(0, BigNumber.ROUND_FLOOR)
          .toString(10),
        pool.assetDecimals[askAssetIndex]
      ).toString(10);

      swap_to_asset_amount = denormalize_amount(
        normalized_to_asset_pool_amount.toString(10),
        pool.assetDecimals[askAssetIndex]
      );
    }

    return {
      returnAmount: swap_to_asset_amount + BigInt(mint_amount),
      swap_to_asset_amount: swap_to_asset_amount.toString(10),
      feeAmount: fee_amount.toString(),
    };
  }
};

export const computeStableOfferAmount = (pool: any, hop: any, amount: bigint) => {
  // TODO: INCORRECT CALCS NOW, WAITING FOR ERIC
  // calc the reverse of a stable swap
  // we want to reach the offer amount necessary to get exactly the ask amount inputted by the user (excluding just some minor approximations)
  const { feeAmount, normalized_to_asset_amount, normalized_fee_amount } = computeStableSwap(pool, hop, amount);

  const offerAssetIndex = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.x;
    }
    return asset.info.native_token.denom === hop.x;
  });

  return {
    offerAmount: denormalize_amount(
      (normalized_to_asset_amount + normalized_fee_amount).toString(),
      pool.assetDecimals[offerAssetIndex]
    ),
    feeAmount: feeAmount ?? BigInt(0),
  };
};
