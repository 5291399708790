import BigNumber from "bignumber.js";
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { toPairs } from "lodash";
import { NumericFormat } from "react-number-format";
import { ChevronLeft, ChevronRight } from "react-feather";
import { selectAssets } from "@axvdex/state/wallet/walletSelectors";
import { abbreviateNumber, abbreviateUSD } from "@axvdex/utils/formatNumber";
import { useAppSelector } from "@axvdex/state";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import Button from "../common/Button";

interface POLTableProps {
  polBalances: {
    [key: string]: {
      amount: string;
      amountInUSD: number;
      priceVariation?: {
        variationSum: number;
        variationCount: number;
      };
    };
  };
}

const POLTable = ({ polBalances }: POLTableProps) => {
  const { i18 } = useLanguage();
  const assets = useAppSelector(selectAssets);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const defaultColumns = useMemo(() => {
    return [
      {
        id: "token",
        accessorKey: "token",
        header: () => <span>{i18("Token", "statistics.polTable.column.token")}</span>,
        cell: ({ row }) => (
          <span className="inlineFlexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img src={imgSanitize(row.original.symbol)} alt="{row.original.symbol}" />
            </span>
            <span className="tokenName">{row.original.symbol}</span>
          </span>
        ),
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: () => <span>{i18("Amount", "statistics.polTable.column.amount")}</span>,
        cell: ({ row }) => {
          return abbreviateNumber(row.original.amount);
        },
      },
      {
        id: "amountInUSD",
        accessorKey: "amountInUSD",
        header: () => <span>{i18("USD", "statistics.polTable.column.amountInUSD")}</span>,
        cell: ({ row }) => abbreviateUSD(row.original.amountInUSD),
      },
      {
        id: "priceChange",
        accessorKey: "priceChange",
        header: () => <span>{i18("24h Change", "statistics.polTable.column.priceChange")}</span>,
        cell: ({ row }) => (
          <span
            className={clsx(
              "variation",
              row.original.priceVariation > 0 && "higher",
              row.original.priceVariation < 0 && "lower"
            )}
          >
            <NumericFormat
              value={Math.abs(row.original.priceVariation)}
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
              suffix="%"
            />
          </span>
        ),
      },
    ];
  }, [i18]);

  const [columns] = useState(() => [...defaultColumns]);
  const [data, setData] = useState(() => []);
  const [polTotalUSD, setPolTotalUSD] = useState(null);

  useEffect(() => {
    if (Object.keys(assets).length !== 0) {
      let totalUSD = 0;

      const mappedData = toPairs(polBalances)
        .filter(([token, { amount }]) => amount !== "0" && assets[token])
        .map(([token, { amount, amountInUSD, priceVariation }]) => {
          const symbol = assets[token].symbol;
          totalUSD += amountInUSD;
          return {
            symbol,
            amount: BigNumber(amount)
              .div(Math.pow(10, assets[token].decimals))
              .decimalPlaces(4, BigNumber.ROUND_FLOOR)
              .toString(),
            amountInUSD,
            priceVariation: priceVariation
              ? priceVariation?.variationSum / priceVariation?.variationCount
              : assets[token].price_24h_change || 0,
          };
        });

      // group native and derivative together
      const tableData: {
        [key: string]: {
          symbol: string;
          amount: string;
          amountInUSD: number;
          priceVariation: number;
        };
      } = {};

      mappedData.forEach(asset => {
        let symbol = asset.symbol;
        if ("x" === symbol[0]) {
          // if x is in front it's our derivative, so we remove it to be grouped to its respective native
          symbol = symbol.substring(1);
        }
        if (!tableData[symbol]) {
          tableData[symbol] = asset;
        } else {
          tableData[symbol].symbol = symbol;
          tableData[symbol].amount = BigNumber(tableData[symbol].amount).plus(asset.amount).toString(10);
          tableData[symbol].amountInUSD = tableData[symbol].amountInUSD + asset.amountInUSD;
        }
      });

      setData(Object.values(tableData).sort((a, b) => b.amountInUSD - a.amountInUSD));
      setPolTotalUSD(BigNumber(totalUSD).decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10));
    }
  }, [polBalances, assets]);

  const table = useReactTable({
    data,
    state: {
      pagination,
    },
    columns,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    autoResetAll: false,
  });

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", pagination.pageIndex === i && "isCurrent")}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  return (
    <div>
      <div className="statsSectionTableHeader inlineFlexbox">
        <h2>
          {i18("Protocol Owned Liquidity", "statistics.polTable.title")}{" "}
          {polTotalUSD ? `- ${abbreviateUSD(polTotalUSD)}` : ""}
        </h2>
      </div>
      <table className={clsx("table", "statsTable")}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {table.getPageCount() > 1 && (
        <section className="statisticsGridItemsSectionTablePagination" style={{ paddingTop: "1rem" }}>
          <Button
            extraClassName="btnTablePagination previous"
            title={i18("Previous page", "global.pagination.previousPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronLeft />}
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
          />

          <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

          <Button
            extraClassName="btnTablePagination next"
            title={i18("Next page", "global.pagination.nextPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronRight />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
        </section>
      )}
    </div>
  );
};

export default POLTable;
