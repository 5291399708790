import { AlertTriangle, CheckCircle, Info, Eye, Zap, Layers, Hexagon, Download } from "react-feather";
import { Link } from "react-router-dom";
import clsx from "clsx";
import DOMPurify from "dompurify";
import { useMediaQuery } from "usehooks-ts";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectGlobalSettings, selectMyToastMessages } from "@axvdex/state/wallet/walletSelectors";
import { responsiveBreakpoints } from "@axvdex/constants";
import { updateUserGlobalSettingsFields } from "@axvdex/state/wallet/walletThunks";
import useLanguage from "@axvdex/hooks/useLanguage";
import { ReactComponent as IcnMenuUp } from "../assets/icons/icn-menu-up.svg";
import { ReactComponent as IcnMenuDown } from "../assets/icons/icn-menu-down.svg";
import Button from "./common/Button";

function FooterUtilitiesMenu() {
  const { i18 } = useLanguage();

  const myToastMessages = useAppSelector(selectMyToastMessages);

  const dispatch = useAppDispatch();
  const globalSettings = useAppSelector(selectGlobalSettings);

  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  const toggleMenuPosition = () => {
    dispatch(
      updateUserGlobalSettingsFields(
        "top" === globalSettings?.menuPosition ? { menuPosition: "bottom" } : { menuPosition: "top" }
      )
    );
  };

  return (
    <>
      {!isMobileBreakpoint && (
        <div
          className={clsx(
            "top" === globalSettings?.menuPosition ? "headerUtilitiesMenuSwitch" : "footerUtilitiesMenuSwitch"
          )}
        >
          <Button
            id={"top"}
            name="menuPosition"
            onClick={toggleMenuPosition}
            title={
              "top" === globalSettings?.menuPosition
                ? i18("Move menu to bottom", "footerUtitilitesMenu.btnMenuPosition.bottom")
                : i18("Move menu to top", "footerUtitilitesMenu.btnMenuPosition.top")
            }
            btnVariant="icon"
          >
            <span className="btnMenuIcon">
              {("top" === globalSettings?.menuPosition && <IcnMenuDown />) || <IcnMenuUp />}
            </span>
          </Button>
        </div>
      )}
      <ul className="footerUtilitiesMenuList primary">
        <li>
          {/* This anchor must not be a <Link /> because it fails ONLY on the live server - go figure! */}
          <a
            href="https://astrovault.io/whitepaper"
            target="_blank"
            rel="noreferrer"
            title={i18("See Astrovault Whitepaper", "footerUtitilitesMenu.link.seewhitepaper.title")}
          >
            <i>
              <Zap />
            </i>
            {i18("AXV Whitepaper", "footerUtitilitesMenu.link.seewhitepaper.text")}
          </a>
        </li>
        <li>
          {/* This anchor must not be a <Link /> because it fails ONLY on the live server - go figure! */}
          <a
            href="https://astrovault.io/whitepaper/tf"
            target="_blank"
            rel="noreferrer"
            title={i18("See Timeflux Whitepaper", "footerUtitilitesMenu.link.seetfwhitepaper.title")}
          >
            <i>
              <Zap />
            </i>
            {i18("Timeflux Whitepaper", "footerUtitilitesMenu.link.seetfwhitepaper.text")}
          </a>
        </li>
        <li>
          {/* This anchor must not be a <Link /> because it fails ONLY on the live server - go figure! */}
          <a
            href="https://astrovault.io/whitepaper/econ"
            target="_blank"
            rel="noreferrer"
            title={i18("See Econ Whitepaper", "footerUtitilitesMenu.link.seeeconwhitepaper.title")}
          >
            <i>
              <Zap />
            </i>
            {i18("Econ Whitepaper", "footerUtitilitesMenu.link.seeeconwhitepaper.text")}
          </a>
        </li>
        <li>
          {/* This anchor must not be a <Link /> because it fails ONLY on the live server - go figure! */}
          <a
            href="https://astrovault.io/mediakit"
            target="_blank"
            rel="noreferrer"
            title={i18("Download Branding", "footerUtitilitesMenu.link.downloadBranding.title")}
          >
            <i>
              <Download />
            </i>
            {i18("Logos and Branding", "footerUtitilitesMenu.link.downloadBranding.text")}
          </a>
        </li>
        <li>
          <Link
            to="/about#astrovault-partners"
            title={i18("See Astrovault partners", "footerUtitilitesMenu.link.ourpartners.title")}
          >
            <i>
              <Layers />
            </i>
            {i18("Our partners", "footerUtitilitesMenu.link.ourpartners.text")}
          </Link>
        </li>
        <li>
          <Link to="/legal" title={i18("See Legal", "footerUtitilitesMenu.link.legal.title")}>
            <i>
              <Eye />
            </i>
            {i18("Risks and Disclaimers", "footerUtitilitesMenu.link.legal.text")}
          </Link>
        </li>
        <li>
          <Link to="/legal/tou" title={i18("See Terms of Use", "footerUtitilitesMenu.link.legalTOU.title")}>
            <i>
              <Eye />
            </i>
            {i18("Terms of Use", "footerUtitilitesMenu.link.legalTOU.text")}
          </Link>
        </li>
        <li>
          <Link to="/help" title={i18("See Help", "footerUtitilitesMenu.link.help.title")}>
            <i>
              <Info />
            </i>
            {i18("Help", "footerUtitilitesMenu.link.help.text")}
          </Link>
        </li>
        <li>
          <Link to="/about" title={i18("See About", "footerUtitilitesMenu.link.about.title")}>
            <i>
              <Hexagon />
            </i>
            {i18("About", "footerUtitilitesMenu.link.about.text")}
          </Link>
        </li>
      </ul>

      <p className="footerUtilitiesMenuListTitle">{i18("Messages", "footerUtitilitesMenu.messages.title")}</p>

      <ul className="footerUtilitiesMenuList messages">
        {myToastMessages &&
          myToastMessages.slice(0, 5).map((message, index) => {
            const messageHTML = `${message.message} ${message.links
              .map(
                link => `<a target="_blank" href=${link.url} key=${link.url} rel="noreferrer" class="btn" data-color="gradient" data-variant="link">
                ${link.label}
              </a>`
              )
              .join(" / ")}`;
            const sanitizedHtml = DOMPurify.sanitize(messageHTML, {
              ALLOWED_TAGS: ["a"],
              ALLOWED_ATTR: ["target", "href", "class", "data-color", "data-variant", "rel", "key"],
            });
            return (
              <li key={index} className={clsx("flexbox", message.links.length ? "success" : "error")}>
                <>
                  {(!!message.links.length && <CheckCircle />) || <AlertTriangle />}
                  <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                </>
              </li>
            );
          })}
      </ul>
    </>
  );
}

export default FooterUtilitiesMenu;
