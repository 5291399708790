import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { responsiveBreakpoints } from "@axvdex/constants";
import { selectGlobalSettings } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import styles from "../styles/HeaderNavItems.module.scss";
import HeaderUtilitiesMenu from "./HeaderUtilitiesMenu";
import { HeaderUtilitiesTickerPrice, HeaderUtilitiesTradeButton } from "./HeaderUtilities";

function HeaderNavItems() {
  const globalSettings = useAppSelector(selectGlobalSettings);

  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  return (
    <>
      <ul className={clsx(styles.headerNavItemsList, "headerNavItemsList")}>
        {!isMobileBreakpoint && (
          <li className="headerNavItem utilities">
            <HeaderUtilitiesTickerPrice />
          </li>
        )}
        {!isMobileBreakpoint && (
          <li className="headerNavItem utilities">
            <HeaderUtilitiesTradeButton />
          </li>
        )}
        {!isMobileBreakpoint && "top" === globalSettings?.menuPosition && (
          <li className="headerNavItem utilities">
            <HeaderUtilitiesMenu />
          </li>
        )}
      </ul>
    </>
  );
}

export default HeaderNavItems;
