import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useMediaQuery } from "usehooks-ts";
import { ReactComponent as IcnSortAsc } from "@axvdex/assets/icons/icn-sort-arrow-down-short-wide-solid.svg";
import { ReactComponent as IcnSortDesc } from "@axvdex/assets/icons/icn-sort-arrow-down-wide-short-solid.svg";
import { selectAssets, selectPools } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { abbreviateUSD } from "@axvdex/utils/formatNumber";
import { poolTableData } from "@axvdex/views/Statistics";
import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "@axvdex/constants";
import CustomInput from "../form-element/CustomInput";
import Button from "../common/Button";

const SortOrderIcon = ({ sortOrder }: { sortOrder: string }) => {
  const icon = () => {
    if ("desc" === sortOrder) {
      return <IcnSortDesc />;
    } else if ("asc" === sortOrder) {
      return <IcnSortAsc />;
    }
    return <></>;
  };

  return icon();
};

const StatisticsPoolsTable = ({ analyticsData }: { analyticsData: poolTableData }) => {
  const { i18 } = useLanguage();
  const pools = useAppSelector(selectPools);
  const assets = useAppSelector(selectAssets);
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  const defaultColumns = useMemo(() => {
    return [
      {
        id: "pool",
        accessorKey: "pool",
        header: () => <span>{i18("Pool", "statistics.poolsTable.column.pool")}</span>,
        cell: ({ row }) => (
          <div>
            <ul
              className="tokenGridList poolCardTokenList icons"
              style={{ marginBottom: "0rem", paddingBottom: "0rem" }}
            >
              {row.original.poolAssetIDs.map((_, assetIndex) => (
                <li key={assetIndex}>
                  <span
                    title={row.original.poolAssetSymbols[assetIndex]}
                    aria-label={row.original.poolAssetSymbols[assetIndex]}
                  >
                    <img
                      src={imgSanitize(row.original.poolAssetSymbols[assetIndex])}
                      alt={row.original.poolAssetSymbols[assetIndex]}
                    />
                  </span>
                </li>
              ))}
              {!isMobileBreakpoint && (
                <span style={{ marginLeft: "1rem" }}>
                  {row.original.poolAssetIDs.map((_, _x) => (
                    <li key={_x}>
                      <span>{row.original.poolAssetSymbols[_x]}</span>
                    </li>
                  ))}
                </span>
              )}
            </ul>
          </div>
        ),
      },
      {
        id: "liquidity",
        accessorKey: "liquidity",
        header: () => <span>{i18("Liquidity", "statistics.poolsTable.column.liquidity")}</span>,
        cell: ({ row }) => abbreviateUSD(`${Math.round(row.original.poolTVL * 100) / 100}`),
        sortingFn: (a, b) => {
          // console.log(a, b);
          return a.original.poolTVL - b.original.poolTVL;
        },
        enableSorting: true,
      },
      {
        id: "24hvolume",
        accessorKey: "24hvolume",
        header: () => <span>{i18("24h Volume", "statistics.poolsTable.column.24hVolume")}</span>,
        cell: ({ row }) => abbreviateUSD(`${row.original.volume24h}`),
        sortingFn: (a, b) => a.original.volume24h - b.original.volume24h,
        enableSorting: true,
      },

      // {
      //   id: "24hvolumeChange",
      //   accessorKey: "24hvolumeChange",
      //   header: () => <span>{i18("24h Volume (Change)", "statistics.poolsTable.column.24hVolumeChange")}</span>,
      //   cell: ({ row }) => {
      //     const change = Math.round(row.original.volume24hChange * 100) / 100;
      //     if (change) {
      //       return (
      //         <span className={clsx("variation", change > 0 && "higher", change < 0 && "lower")}>
      // TODO: use abbreviateNumber
      //           <NumericFormat value={change} fixedDecimalScale decimalScale={2} displayType="text" suffix="%" />
      //         </span>
      //       );
      //     }
      //     return (
      //       <span className={clsx("variation", change > 0 && "higher", change < 0 && "lower")}>
      //         <NumericFormat value={"-"} displayType="text" />
      //       </span>
      //     );
      //   },
      // },
      {
        id: "7dvolume",
        accessorKey: "7dvolume",
        header: () => <span>{i18("7D Volume", "statistics.poolsTable.column.7dVolume")}</span>,
        cell: ({ row }) => abbreviateUSD(`${row.original.volume7D}`),
        sortingFn: (a, b) => a.original.volume7D - b.original.volume7D,
        enableSorting: true,
      },
    ];
  }, [i18]);

  const [columns] = useState(() => [...defaultColumns]);
  const [data, setData] = useState(() => []);
  useEffect(() => {
    if (analyticsData && Object.keys(analyticsData).length > 0) parseData();
    if (data.length === 0 && pools && Object.keys(pools).length > 0 && assets && Object.keys(assets).length > 0) {
      setSorting([{ id: "liquidity", desc: true }]);
    }
  }, [analyticsData, pools, assets]);

  const parseData = (searchFilter = "") => {
    setData(
      Object.keys(pools)
        .map(poolAddress => {
          return {
            poolAddress,
            poolAssetIDs: pools[poolAddress].poolAssets.map(
              poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
            ),
            poolAssetSymbols: pools[poolAddress].poolAssetSymbols,
            poolTVL: analyticsData[poolAddress]?.liquidityInUSD,
            //poolStatistics24h,
            volume24h: analyticsData[poolAddress]?.volume24hInUSD,
            //prevVolume24h,
            //volume24hChange,
            volume7D: analyticsData[poolAddress]?.volume7dInUSD,
          };
        })
        .filter(
          a =>
            // filter out pools with 0 tvl and if there is a search term, search for it on the symbols name of the assets
            a.poolTVL > 0 &&
            pools[a.poolAddress] && // this is to prevent disabled pools on this context to appear
            (searchFilter !== ""
              ? a.poolAssetIDs.find((assetID: string) =>
                  assets[assetID].symbol.toLowerCase().includes(searchFilter.toLowerCase())
                )
              : true)
        )
        .sort((a, b) => b.poolTVL - a.poolTVL)
    );
  };

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    debugTable: !1,
    autoResetAll: false,
  });

  const [searchString, setSearchString] = useState("");

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", pagination.pageIndex === i && "isCurrent")}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  return (
    <section className="statsSectionTable">
      <div>
        <div className="statsSectionTableHeader inlineFlexbox" style={{ width: "100%" }}>
          <h2>{i18("Pools", "statistics.pools.title")}</h2>
          <div style={{ marginLeft: "auto" }}>
            <CustomInput
              name="asset_search"
              labelText={i18("Search", "statistics.pools.filter.search.label")}
              placeholder={i18("Search...", "statistics.pools.filter.search.placeholder")}
              hiddenLabel={true}
              value={searchString}
              onChange={e => {
                setSearchString(e.target.value);
                parseData(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <table className={clsx("table", "statsTable")}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return "pool" === header.id ? (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ) : (
                    <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                      <div className={"tableHeaderColumnWithIcon"}>
                        <span className="icon">
                          <SortOrderIcon sortOrder={(header.column.getIsSorted() as string) ?? "unsorted"} />
                        </span>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {table.getPageCount() > 1 && (
        <section className="statisticsGridItemsSectionTablePagination">
          <Button
            extraClassName="btnTablePagination previous"
            title={i18("Previous page", "global.pagination.previousPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            // eslint-disable-next-line react/jsx-no-undef
            icon={<ChevronLeft />}
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
          />

          <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

          <Button
            extraClassName="btnTablePagination next"
            title={i18("Next page", "global.pagination.nextPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronRight />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
        </section>
      )}
    </section>
  );
};

export default StatisticsPoolsTable;
