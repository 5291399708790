import BigNumber from "bignumber.js";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "@axvdex/state";
import { selectAssets } from "@axvdex/state/wallet/walletSelectors";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../styles/HeaderUtilities.module.scss";
import ButtonMenu from "./common/ButtonMenu";

export const HeaderUtilitiesTickerPrice = () => {
  const assets = useAppSelector(selectAssets);

  if (!assets || !Object.keys(assets).length) return null;
  const AXV = Object.values(assets).find(asset => "AXV" === asset.symbol);

  if (!AXV) return null;

  return (
    <section className={clsx(styles.sectionHeaderUtilities, "sectionHeaderUtilities")}>
      <ButtonMenu extraClassName={clsx("defaultCursor", "btnMenuHeader")} title={"AXV"} noIcon={true}>
        <div className="flexbox" style={{ width: "100%", cursor: "default" }}>
          <img className="tokenIcon" src={imgSanitize(AXV.symbol)} alt={AXV.symbol} style={{ width: "2em" }} />
          <span className="tokenName">${BigNumber(AXV.price).decimalPlaces(4).toString(10)}</span>
        </div>
      </ButtonMenu>
    </section>
  );
};

export const HeaderUtilitiesTradeButton = () => {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const assets = useAppSelector(selectAssets);
  if (!assets || !Object.keys(assets).length) return null;

  // check url, if user on trade page already the text changes
  return (
    <section className={clsx(styles.sectionHeaderUtilities, "sectionHeaderUtilities")}>
      <ButtonMenu
        extraClassName={clsx("btnMenuHeader", "primaryButtonBackground")}
        title={i18("See deposit, trade, move, send, or withdraw assets", "header.btn.view.asset.title")}
        noIcon={true}
        onClick={() => {
          location.pathname.includes("/trade")
            ? navigate(
                `/trade?from=ATOM&fromChain=${
                  "MAINNET" === process.env.REACT_APP_MODE ? "cosmoshub-4" : "theta-testnet-001"
                }&to=ATOM&toChain=${"MAINNET" === process.env.REACT_APP_MODE ? "neutron-1" : "pion-1"}&amount=${10}`
              )
            : navigate("/trade");
        }}
      >
        {location.pathname.includes("/trade")
          ? i18("Deposit Assets", "header.btn.view.asset.depositText")
          : i18("Move & Trade Assets", "header.btn.view.asset.text")}
      </ButtonMenu>
    </section>
  );
};
