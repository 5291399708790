import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ColumnDef } from "@tanstack/react-table";
// import { MinusCircle } from "react-feather";
import { useMediaQuery } from "usehooks-ts";
import { isEqual } from "lodash";
import { NumericFormat } from "react-number-format";
import { useAppDispatch, useAppSelector } from "@axvdex/state";

import {
  selectAssets,
  selectIsWalletLoading,
  selectUser,
  selectUserPriceWatch,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { IAsset } from "@axvdex/utils/interfaces";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { removePriceWatch, updateUserPriceOrder } from "@axvdex/state/wallet/walletThunks";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import imgSanitize from "@axvdex/utils/imgSanitize";

import useLanguage from "@axvdex/hooks/useLanguage";
import { dashboardMarketList } from "../mocks/DummyData";
import { responsiveBreakpoints } from "../constants";
import styles from "../styles/DashboardMarket.module.scss";
import CustomLoader from "./common/CustomLoader";
import DashboardMultiDndPaginationTable from "./DashboardMultiDndPaginationTable";
// import Button from "./common/Button";
import CustomInputButton from "./form-element/CustomInputButton";
import DragHandle from "./common/DragHandle";
import DashboardDndPaginationTable from "./DashboardDndPaginationTable";

interface TableMarketProps {
  id: string;
  symbol: string;
  price: number;
  token: string;
  usdConversion: number;
  variation: number;
  oldValue: number;
  currentValue: number;
  price_24h_change: number;
}

function DashboardMarket() {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [priceWatchAssets, setPriceWatchAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNotConnectedData, setshowNotConnectedData] = useState(false);

  // const marketData: TableMarketProps[] = dashboardMarketList;

  const assetsStored = useAppSelector(selectAssets, isEqual);
  const userPriceWatch = useAppSelector(selectUserPriceWatch, isEqual);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading, isEqual);
  const user = useAppSelector(selectUser, isEqual);

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setPriceWatchAssets([]);
      setIsLoading(false);
      setshowNotConnectedData(true);
    } else {
      setshowNotConnectedData(false);
      setIsLoading(true);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  useEffect(() => {
    if (user.isUserDetailsLoad && walletInfo.isConnected && assetsStored) {
      // get details of assets on user watch price list
      const userPriceWatchDetails = userPriceWatch.map(assetID => {
        return {
          ...assetsStored[assetID],
          key: assetID,
        };
      });
      // everything that is coming from the userPriceWatch comes first,
      // then the rest of the assets are added incase they don't exist yet on the user price watch array
      let priceWatch = userPriceWatchDetails
        .concat(
          Object.entries(assetsStored)
            .filter(
              ([assetID, info]: [string, IAsset]) =>
                !userPriceWatchDetails.find(asset => asset.id === assetID) && info.isPriceWatch
            )
            .map(([assetID, _]: [string, IAsset]) => {
              return {
                ...assetsStored[assetID],
                key: assetID,
              };
            })
        )
        .filter(assetOnPriceList => {
          // filter out assets that are NOT on the BE assetStored list
          return !!(assetOnPriceList && assetsStored[assetOnPriceList.id]) && !assetOnPriceList.isHidden;
        });

      // Remove duplicates
      priceWatch = priceWatch.filter(
        (assetOnPriceList, i) =>
          priceWatch.findIndex(priceWatchAsset => priceWatchAsset.symbol === assetOnPriceList.symbol) === i
      );

      setPriceWatchAssets(priceWatch);
      setIsLoading(false);
    }
  }, [userPriceWatch, assetsStored, user, walletInfo]);

  const handleOrderUpdate = async newOrder => {
    await dispatch(updateUserPriceOrder({ priceWatchList: newOrder }));
  };

  const handleRemoveButton = async (assetID: string) => {
    await dispatch(removePriceWatch(assetID));
  };

  const marketColumns: ColumnDef<TableMarketProps>[] = useMemo(
    () => [
      {
        id: "token",
        accessorKey: "token",
        header: () => <span>{i18("Token", "dashboard.market.table.token")}</span>,
        cell: ({ row }) => {
          return (
            <>
              <div className="flexbox">
                <span className="tokenIcon" title={row.original?.symbol} aria-label={row.original?.symbol}>
                  <img className="tokenIcon" src={imgSanitize(row.original.symbol)} alt={row.original.symbol} />
                </span>
                <span className="tokenName">{row.original.symbol}</span>
              </div>
            </>
          );
        },
      },
      {
        id: "usdConversion",
        accessorKey: "usdConversion",
        header: () => <span>{i18("USD", "dashboard.market.table.usdConversion")}</span>,
        cell: ({ row }) => {
          // const currentVal = Math.abs(row.original.currentValue - row.original.oldValue).toFixed(2);
          const change = row.original.price_24h_change;
          return (
            <>
              <span className="usdConversion">
                <NumericFormat value={row.original.price} fixedDecimalScale decimalScale={6} displayType="text" />
              </span>
              {isMobileBreakpoint && change && (
                <span className={clsx("variation", change > 0 && "higher", change < 0 && "lower")}>
                  <NumericFormat
                    value={Math.abs(change)}
                    fixedDecimalScale
                    decimalScale={2}
                    displayType="text"
                    suffix="%"
                  />
                </span>
              )}
            </>
          );
        },
      },
      {
        id: "variation",
        header: () => <span>{i18("Variation", "dashboard.market.table.variation")}</span>,
        cell: ({ row }) => {
          const change = row.original.price_24h_change;
          if (change) {
            return (
              <span
                className={clsx(
                  "variation",
                  row.original.price_24h_change > 0 && "higher",
                  row.original.price_24h_change < 0 && "lower"
                )}
              >
                <NumericFormat
                  value={Math.abs(change)}
                  fixedDecimalScale
                  decimalScale={2}
                  displayType="text"
                  suffix="%"
                />
              </span>
            );
          }
          return (
            <span className={clsx("variation", change > 0 && "higher", change < 0 && "lower")}>
              <NumericFormat value={"-"} displayType="text" />
            </span>
          );
        },
      },
      // todo: reinstate this (-) btn when the rest of the functionality exists
      // {
      //   id: "actions",
      //   header: () => <span>{i18("Actions", "dashboard.market.table.actions")}</span>,
      //   cell: ({ row }) => (
      //     <>
      //       <Button
      //         title={i18("Remove from watchlist", "dashboard.market.table.actions.remove")}
      //         btnVariant="icon"
      //         btnColor="purple"
      //         icon={<MinusCircle />}
      //         onClick={() => handleRemoveButton(row.original.id)}
      //       />
      //     </>
      //   ),
      // },
    ],
    [i18, isMobileBreakpoint, handleRemoveButton]
  );

  return (
    <section className={clsx(styles.dashboardGridItemMarket, "dashboardGridItemMarket")}>
      <div className="dashboardGridItemTitle">
        <h2>{i18("The Market", "dashboard.market.title")}</h2>
        <div
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet))
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>

      <section className="withGradientBorder">
        {!showNotConnectedData && !isLoading && (
          <fieldset>
            <legend className="visuallyHidden">{i18("Select tab", "dashboard.market.tabs.legend")}</legend>
            <div className="btnGroup">
              <CustomInputButton
                type="radio"
                disabled
                id={"watch_list"}
                name={"market_filter"}
                labelText={i18("Watch List", "dashboard.market.tabs.watchList")}
              />
              {/* <CustomInputButton type="radio" id={"trending"} name={"market_filter"} labelText={i18("Trending", "dashboard.market.tabs.trending")} /> */}
            </div>
          </fieldset>
        )}

        {showNotConnectedData ||
        (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
        !hasPermitStored(walletInfo) ? (
          <div style={{ pointerEvents: "none", filter: "blur(0.3rem)" }}>
            <DashboardDndPaginationTable
              tableId="marketTable"
              extraClassName="dashboardMarketTable"
              customData={dashboardMarketList}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              customColumns={marketColumns}
              // TODO: update watchlist of the user for the correct order (API request)
              onOrderUpdate={handleOrderUpdate}
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
        ) : isLoading ? (
          <CustomLoader size="xs" />
        ) : (
          /*
          <DashboardDndPaginationTable
            tableId="marketTable"
            extraClassName="dashboardMarketTable"
            customData={priceWatchAssets}
            customColumns={marketColumns}
            // TODO: update watchlist of the user for the correct order (API request)
            onOrderUpdate={handleOrderUpdate}
            pagination={pagination}
            setPagination={setPagination}
          />
          */

          <DashboardMultiDndPaginationTable
            tableId="marketTable"
            extraClassName="dashboardMarketTable"
            customData={priceWatchAssets}
            customColumns={marketColumns}
            // TODO: update watchlist of the user for the correct order (API request)
            onOrderUpdate={handleOrderUpdate}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
      </section>
    </section>
  );
}

export default DashboardMarket;
