import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import ReactSlider from "react-slider";
import BigNumber from "bignumber.js";
import { NumericFormat } from "react-number-format";
import { AlertTriangle } from "react-feather";
import {
  formatBalance,
  fromHumanAmountToTokenBalance,
  humanizeAmount,
  maxDecimalsToDisplay,
} from "@axvdex/utils/formatNumber";
import unstakeSimulation, { standardLPTokenRepresentation } from "@axvdex/utils/unstakeSimulation";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  // selectGlobalConfig,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";

import {
  executePoolAction,
  retryWalletWithDispatch,
  updateLpStakingBalance,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import CustomNumericInput from "../form-element/CustomNumericInput";
//import stakeSimulation from "@axvdex/utils/swapScripts/stakeSimulation";
import CustomLoader from "../common/CustomLoader";
//import BigNumber from "bignumber.js";

// const DummyPoolCardDetailsTableList = [
//   { token: "ARCH", total: 10, deposits: 10 },
//   { token: "xARCH", total: 11, deposits: 15 },
// ];

interface PoolCardStandardWithdrawalTableProps {
  id: string;
  symbol: string;
  image: string;
  total: number;
  deposited: {
    amount: number;
    usdConversion: number;
  };
  input: string;
  balance: number | null;
}

interface ComponentProps {
  poolData: any;
  [key: string]: any;
}

function PoolCardStandardWithdrawalTable({ poolData }: ComponentProps) {
  const { i18 } = useLanguage();
  const assetBalances = useAppSelector(selectAssetBalances);
  const assets = useAppSelector(selectAssets);
  const chains = useAppSelector(selectChains);
  //const globalConfig = useAppSelector(selectGlobalConfig);

  const [data, setData] = useState<PoolCardStandardWithdrawalTableProps[]>([]);
  const [simulationValues, setSimulationValues] = useState<any>({});
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const [isLoading, setIsLoading] = useState(false);

  const walletChainContext = walletInfo?.connectedChains[poolData.contextChainId];

  useEffect(() => {
    const update = async () => {
      // if there is no wallet connect and auto connect is enabled, we should show the loader as we are trying to auto connect
      if (!walletInfo.isConnected && loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        setIsLoading(true);
        return;
      }
      if (0 === data.length) setIsLoading(true);
      // get asset balances of this pool that are not already in state
      const requests = [];
      for (const [i, poolAsset] of poolData.poolAssets.entries()) {
        if (!assetBalances[poolData.assetIDs[i]] && walletInfo.isConnected) {
          if (poolAsset.info.token) {
            requests.push(
              dispatch(
                updateTokenBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  tokenAddress: poolAsset.info.token.contract_addr,
                })
              )
            );
          } else {
            requests.push(
              dispatch(
                updateNativeBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  denom: poolAsset.info.native_token.denom,
                })
              )
            );
          }
        } else {
          requests.push(Promise.resolve());
        }
      }

      const poolAssetBalances = await Promise.all(requests);

      // get balance of this user on this pool
      let lpStakingBalance = assetBalances[poolData?.lp_staking];
      if (!assetBalances[poolData?.lp_staking] && walletInfo.isConnected) {
        lpStakingBalance = (
          await dispatch(
            updateLpStakingBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              lpStakingAddress: poolData?.lp_staking,
            })
          )
        ).payload;
      }

      const pPoolData = poolData.poolAssets.map((asset, i) => {
        return {
          id: poolData?.assetIDs[i],
          total: humanizeAmount(asset.amount, assets[poolData?.assetIDs[i]]?.decimals),
          symbol: assets[poolData?.assetIDs[i]]?.symbol,
          deposited: formatBalance(
            standardLPTokenRepresentation(lpStakingBalance as string, poolData).refund_assets[i],
            assets[poolData?.assetIDs[i]]?.price,
            assets[poolData?.assetIDs[i]]?.decimals
          ),
          input: "",
          balance: poolAssetBalances[i]
            ? formatBalance(
                poolAssetBalances[i].payload,
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0
            : formatBalance(
                assetBalances[poolData?.assetIDs[i]],
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0,
        };
      });
      setData(pPoolData);
      setIsLoading(false);
    };
    update();
  }, [walletInfo.isConnected]);

  useEffect(() => {
    setData(prevData => {
      const newData = [...prevData];
      newData.map((row, i) => {
        row.deposited = formatBalance(
          standardLPTokenRepresentation(assetBalances[poolData?.lp_staking], poolData).refund_assets[i],
          assets[poolData?.assetIDs[i]]?.price,
          assets[poolData?.assetIDs[i]]?.decimals
        );
        row.balance = formatBalance(
          assetBalances[poolData?.assetIDs[i]],
          assets[poolData?.assetIDs[i]].price,
          assets[poolData?.assetIDs[i]].decimals
        ).amount;
        return row;
      });
      return newData;
    });
  }, [assetBalances]);

  const handleWithdrawalSliderChange = lpAmount => {
    const transformData = unstakeSimulation(
      poolData,
      lpAmount,
      null,
      null,
      walletChainContext.address,
      assetBalances[poolData?.lp_staking]
    );
    setData(prevData => {
      const newData = [...prevData];
      if (transformData.refund_assets) {
        newData[0].input = transformData.refund_assets[0];
        newData[1].input = transformData.refund_assets[1];
      }
      return newData;
    });
    setSimulationValues({ ...transformData });
  };

  const reconnectWallet = async () => {
    setIsTransactionStarts(true);
    await dispatch(
      retryWalletWithDispatch({
        chainToConnect: chains[poolData.contextChainId],
      })
    );
    setIsTransactionStarts(false);
  };

  const handleSubmit = async () => {
    setIsTransactionStarts(true);

    await dispatch(
      executePoolAction({
        poolData,
        simulationValues,
        isDeposit: false,
        i18,
      })
    );

    setSliderValue(0);
    handleWithdrawalSliderChange("0");
    setIsTransactionStarts(false);
  };

  const defaultColumns = useMemo<ColumnDef<PoolCardStandardWithdrawalTableProps>[]>(() => {
    return [
      {
        id: "token",
        header: () => <span>{i18("Token Balance", "poolCardTable.table.columns.token")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img
                src={imgSanitize(row.original.symbol)}
                alt={row.original.symbol}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    updateTokenBalance({
                      client: walletChainContext.signingClient,
                      userAddress: walletChainContext.address,
                      tokenAddress: row.original.id,
                    })
                  )
                }
              />
            </span>

            {null !== row.original.balance && <span>{row.original.balance}</span>}
          </div>
        ),
      },
      {
        id: "deposited",
        accessorKey: "deposited",
        header: () => <span>{i18("Deposited", "poolCardTable.table.columns.deposited")}</span>,
        cell: ({ row }) => (
          <div className="flexbox deposited">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={maxDecimalsToDisplay(BigNumber(row.original.deposited.amount || 0))}
              suffix=""
              value={BigNumber(row.original.deposited.amount || 0)
                .decimalPlaces(
                  maxDecimalsToDisplay(BigNumber(row.original.deposited.amount || 0)),
                  BigNumber.ROUND_FLOOR
                )
                .toString()}
            />

            <NumericFormat
              className="tableInputValueConversion"
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              prefix={"($"}
              suffix={")"}
              value={BigNumber(row.original.deposited.usdConversion || 0)
                .decimalPlaces(2, BigNumber.ROUND_FLOOR)
                .toString()}
            />
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className="visuallyHidden">{i18("Actions", "poolCardTable.table.columns.actions")}</span>,
        cell: ({ row }) => {
          return (
            <>
              <CustomNumericInput
                extraClassName="derivativeFromUsdFormGroup"
                name={`amount_${row.index}`}
                labelText={i18("Enter amount", "poolCardTable.input.label")}
                hiddenLabel={true}
                placeholder={"0"}
                decimalScale={6}
                value={row.original.input}
                disabled
              />

              <div className="tableInputValueConversion withMarginTop">
                ($
                {
                  formatBalance(
                    fromHumanAmountToTokenBalance(
                      row.original.input?.replaceAll(",", ""),
                      assets[row.original.id].decimals
                    ) || "0",
                    assets[row.original.id].price,
                    assets[row.original.id].decimals
                  ).usdConversion
                }
                )
              </div>
            </>
          );
        },
      },
    ];
  }, [i18]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  // useEffect(() => {
  //   setData(DummyPoolCardDetailsTableList);
  // }, [defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const submitButtonText = () => {
    const action = i18("Withdrawal", "poolCardTable.submitBtn.text.withdrawal");
    if (!walletInfo.connectedChains[poolData?.contextChainId]?.signingStargateClient) {
      return {
        buttonText: i18(
          "Not connected to " + chains[poolData?.contextChainId]?.displayName + ". Try reconnect...",
          "poolCardTable.submitBtn.text.notConnected"
        ),
        disabled: false,
        reconnectWalletAction: true,
        extraClass: "",
      };
    }

    if (data.every(value => !value.input || !Number(value.input))) {
      return {
        buttonText: action,
        disabled: true,
      };
    }

    if (!poolData?.owner_settings?.is_withdraw_liquidity_enabled) {
      return {
        buttonText: i18("Withdrawal disabled", "poolCardTable.submitBtn.text.disabled", { action }),
        disabled: true,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "poolCardTable.submitBtn.text.executing"),
        disabled: true,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this
    // const calcEstimatedFees = estimatedFees(
    //   GAS_ESTIMATION_STATIC_STANDARD_POOL_WITHDRAWAL,
    //   globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference
    // );
    // const feeDenom = globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference.estimatedFee[0].denom;
    // if (assetBalances[feeDenom] && BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for Withdrawal`,
    //       "poolCardTable.submitBtn.text.walletNoFunds",
    //       { action, symbol: assets[feeDenom].symbol }
    //     ),
    //     disabled: true,
    //   };
    // }

    return {
      buttonText: action,
      disabled: false,
    };
  };

  return (
    <section className={clsx("poolCardModalSectionTable withGradientBorderBottom")}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginBottom: "1.5em" }}>
          {walletChainContext?.signingStargateClient && <CustomLoader size="xs" />}
        </div>
      ) : (
        <>
          <table
            className={clsx(
              "table",
              "withGradientBorderRows",
              "poolCardModalDetailsTable",
              "isResponsive",
              "col-3",
              "standardWithdrawal"
            )}
          >
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <ReactSlider
            className="horizontalSlider"
            thumbClassName="horizontalSliderThumb"
            trackClassName="horizontalSliderTrack"
            value={sliderValue}
            onChange={value => {
              const inputValue = BigNumber(value).div(100).times(assetBalances[poolData.lp_staking]).toString(10);
              if (undefined !== inputValue && null !== inputValue) {
                handleWithdrawalSliderChange(inputValue);
              }
              setSliderValue(value);
            }}
            renderThumb={(props, state) => (
              <div {...props}>
                <span className="thumbNowValue">{state.valueNow}%</span>
                <span className="thumbHandle" />
              </div>
            )}
          />
        </>
      )}

      {!isTransactionStarts ? (
        <>
          <Button
            text={submitButtonText().buttonText}
            title={i18("Submit", "poolCardTable.submitBtn.title")}
            isFullWidth={true}
            btnColor="gradient"
            onClick={submitButtonText().reconnectWalletAction ? reconnectWallet : handleSubmit}
            disabled={submitButtonText().disabled}
          />
          {simulationValues &&
            assetBalances &&
            poolData &&
            simulationValues.lpTokensAmount === assetBalances[poolData?.lp_staking] && (
              <>
                <div className="flexbox helpText">
                  <span className="warning tokenIcon">
                    <AlertTriangle />
                  </span>
                  <span>
                    {i18(
                      `Withdrawing the full amount from a pool will automatically claim it's accumulated rewards`,
                      "poolCardTable.withdrawTable.withdrawFullAmount"
                    )}
                  </span>
                </div>
              </>
            )}
        </>
      ) : (
        <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
      )}
    </section>
  );
}

export default PoolCardStandardWithdrawalTable;
