import { ReactNode, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

import { isEqual } from "lodash";
import { responsiveBreakpoints } from "@axvdex/constants";
import { selectNavOpen } from "@axvdex/state/app/appSelectors";
import { setNavOpen } from "@axvdex/state/app/appSlice";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState, persistState } from "@axvdex/state/persist";
import ComplianceModal from "@axvdex/components/modals/ComplianceModal";
import PermitAuthenticationModal from "@axvdex/components/modals/PermitAuthenticationModal";
import { updatingUser } from "@axvdex/state/wallet/walletSlice";
import SubFooterLegalContent from "@axvdex/components/common/SubFooterLegalContent";
import useLanguage from "@axvdex/hooks/useLanguage";
import SEO from "@axvdex/components/SEO";
import styles from "@axvdex/styles/MainLayout.module.scss";
import SupportedChainsModal from "@axvdex/components/modals/SupportedChainsModal";
import { handleAuthenticationOnAccountChange } from "state/wallet/walletThunks";
import { selectUser, selectUserLoading, selectWalletInfo } from "state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "state";
import WalletSelectionModal from "components/modals/WalletSelectionModal";
import Footer from "components/Footer";
import Header from "components/Header";
import MobileNav from "components/MobileNav";
import NavSidebar from "components/NavSidebar";
import { GlobalModalProvider, useGlobalModalContext } from "providers/GlobalModalProvider";

interface MainLayoutProps {
  pageClass: string;
  headTitle: string;
  headDescription?: string;
  children: ReactNode;
}

function MainLayout({ pageClass, headTitle, headDescription, children }: MainLayoutProps) {
  const dispatch = useAppDispatch();
  const { i18 } = useLanguage();
  const isNavMenuOpen = useAppSelector(selectNavOpen);
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const user = useAppSelector(selectUser);
  const userLoading = useAppSelector(selectUserLoading);

  const figureOutWhenToOpenNav = () => {
    if (!isNavMenuOpen) {
      return false;
    } else if (
      isNavMenuOpen &&
      loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet) &&
      !walletInfo.isConnected
    ) {
      return false;
    } else if (isNavMenuOpen && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
      return true;
    }
    return isNavMenuOpen;
  };

  const [expandNav, setExpandNav] = useState(figureOutWhenToOpenNav());

  useEffect(() => {
    setExpandNav(figureOutWhenToOpenNav());
  }, [isNavMenuOpen, walletInfo.isConnected]);

  const { showModal } = useGlobalModalContext();

  const handleToggleNavMenu = useCallback(() => {
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.isSideMenuOpen, !expandNav);
    setExpandNav(!expandNav);
    dispatch(setNavOpen(!expandNav));
  }, [expandNav]);

  useEffect(() => {
    if (isMobileBreakpoint) {
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.isSideMenuOpen, false);
      dispatch(setNavOpen(false));
    }
  }, [isMobileBreakpoint]);

  useEffect(() => {
    const initWalletInfoEffect = async () => {
      if (walletInfo.isConnected && !user.isUserDetailsLoad && !userLoading) {
        dispatch(updatingUser(true));
        dispatch(handleAuthenticationOnAccountChange({ showModal })).unwrap();
      }
    };
    initWalletInfoEffect();
  }, [walletInfo.isConnected, user, userLoading]);

  if ("Landing" === headTitle) {
    return (
      <>
        <SEO title={`${i18(headTitle, "landing.headTitle")}`} description={headDescription} />

        <div className={clsx(styles.dashboardPage, "dashboardPage", pageClass)}>
          <div className={clsx("dashboardPageWrapper", expandNav && "isOpen")}>
            <main className={clsx(expandNav && "isOpen")}>{children}</main>
          </div>
        </div>
      </>
    );
  }

  return (
    <GlobalModalProvider>
      <SEO title={`${i18(headTitle, "mainLayout.headTitle")}`} description={headDescription} />

      <div className={clsx(styles.dashboardPage, "dashboardPage", pageClass)}>
        {!isMobileBreakpoint && <Header />}
        <div className={clsx("dashboardPageWrapper", expandNav && "isOpen")}>
          {!isMobileBreakpoint && (
            <NavSidebar isOpen={expandNav} walletInfo={walletInfo} onClick={handleToggleNavMenu} />
          )}

          <main className={clsx(expandNav && "isOpen")}>{children}</main>
        </div>

        {!isMobileBreakpoint && <Footer />}
      </div>

      <SubFooterLegalContent />

      {isMobileBreakpoint && <MobileNav />}

      <WalletSelectionModal />
      <SupportedChainsModal />
      <ComplianceModal />
      <PermitAuthenticationModal />
    </GlobalModalProvider>
  );
}

export default MainLayout;
