import { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import CustomLoader from "@axvdex/components/common/CustomLoader";
import useLanguage from "@axvdex/hooks/useLanguage";
import { getOutbidSales } from "@axvdex/api/outbid";
import { socket } from "@axvdex/config/socket";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { IOutbidSale } from "@axvdex/utils/interfaces";
import SalesSelectGrid from "@axvdex/components/outbid/SalesSelectGrid";
import { selectOutbid, selectSalesBids, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import SaleMain from "@axvdex/components/outbid/SaleMain";
import { getServerTime } from "@axvdex/api/misc";
import { updateUserSaleBids } from "@axvdex/state/outbid/saleThunks";
import styles from "../styles/Outbid.module.scss";
import MainLayout from "../layouts/MainLayout";
import {
  updateOutbidSales,
  updateOutbidServerTime,
  updateWsOutbidSaleFields,
  updateWsServerTimeField,
} from "state/wallet/walletSlice";

const Outbid = () => {
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [outbidSales, setOutbidSales] = useState<{ [key: string]: IOutbidSale }>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const [outbidSales, serverTime] = await Promise.all([getOutbidSales({}), getServerTime()]);

      const outbidSalesData = outbidSales.data;
      const serverTimeData = serverTime.data;

      const _outbidSales: { [key: string]: IOutbidSale } = {};
      outbidSalesData.forEach(outbidSale => (_outbidSales[outbidSale.label] = outbidSale));

      setOutbidSales(_outbidSales);
      dispatch(updateOutbidSales(_outbidSales));
      dispatch(updateOutbidServerTime(serverTimeData));

      socket.on("outbid-update", data => {
        try {
          dispatch(updateWsOutbidSaleFields(JSON.parse(data)));
        } catch (e) {
          console.log(e);
        }
      });

      socket.on("server-time-update", data => {
        try {
          dispatch(updateWsServerTimeField(data));
        } catch (e) {
          console.log(e);
        }
      });

      setLoading(false);
    };
    run();
  }, []);

  if (loading) {
    return <CustomLoader isFixed={true} showGRVT8 />;
  }

  return <OutbidSalesComponent />;
};

function OutbidSalesComponent() {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const routeParams = useParams();
  const walletInfo = useAppSelector(selectWalletInfo);
  const outbidSales = useAppSelector(selectOutbid);
  const salesBids = useAppSelector(selectSalesBids);

  const topFilterOptions = [
    {
      label: i18("All", "pools.topFilter.all"),
      id: "all",
    },
    {
      label: i18("Upcoming", "pools.topFilter.all"),
      id: "upcoming",
    },
    {
      label: i18("Live", "pools.topFilter.all"),
      id: "live",
    },
    {
      label: i18("Vesting", "pools.topFilter.all"),
      id: "vesting",
    },
    {
      label: i18("Completed", "pools.topFilter.all"),
      id: "completed",
    },
  ];

  const [topFilter, setTopFilter] = useState(topFilterOptions[0]);
  const [outbidSaleSelected, setOutbidSaleSelected] = useState(null);

  useEffect(() => {
    if (routeParams && routeParams.label) setOutbidSaleSelected(routeParams.label);
    // else if (!outbidSaleSelected && 0 < Object.keys(outbidSales).length)
    //   setOutbidSaleSelected(Object.keys(outbidSales)[0]);
  }, [outbidSales]);

  const getGridUserBids = async () => {
    const promisses = [];
    for (const sale of Object.values(outbidSales)) {
      if (Object.keys(sale.salesState).length > 0 && walletInfo?.connectedChains[sale.contextChainId]) {
        for (const saleAddr of Object.keys(sale.salesState)) {
          promisses.push(
            dispatch(
              updateUserSaleBids({
                client: walletInfo.connectedChains[sale.contextChainId].signingClient,
                walletAddress: walletInfo.connectedChains[sale.contextChainId].address,
                saleAddress: saleAddr,
                withDetails: true,
              })
            )
          );
        }
      }
    }

    await Promise.all(promisses);
  };

  useEffect(() => {
    if (walletInfo?.isConnected && outbidSales && !salesBids) getGridUserBids();
  }, [outbidSales, salesBids, walletInfo]);

  return (
    <MainLayout
      pageClass={clsx(styles.sales, "sales")}
      headTitle={i18("Outbid", "outbid.headTitle")}
      headDescription={i18("Outbid", "outbid.headDescription")}
    >
      <div style={{ maxWidth: "64em", width: "100%", alignSelf: "center" }}>
        {!outbidSaleSelected && (
          <SalesSelectGrid
            topFilterOptions={topFilterOptions}
            topFilter={topFilter}
            setTopFilter={setTopFilter}
            setOutbidSaleSelected={setOutbidSaleSelected}
          />
        )}
        {outbidSaleSelected && (
          <SaleMain outbidSale={outbidSaleSelected} setOutbidSaleSelected={setOutbidSaleSelected} />
        )}

        {/* {outbidSaleSelected && (
          <SaleDetails outbidSaleSelected={outbidSaleSelected} setOutbidSaleSelected={setOutbidSaleSelected} />
        )} */}
      </div>
    </MainLayout>
  );
}

export default Outbid;
