import imgSanitize from "@axvdex/utils/imgSanitize";

export const getTokenList = assetsStored => {
  const symbolsAlreadyAdded = new Set();
  const list = [];

  // loop through assetsStored and add the symbol to the asset object and ignore hidden assets
  for (const key in assetsStored) {
    const asset_ = assetsStored[key];
    if (asset_.isHidden || symbolsAlreadyAdded.has(asset_.symbol)) {
      continue;
    }
    list.push({
      value: asset_.symbol,
      label: asset_.symbol,
      optionPrefix: <img src={imgSanitize(asset_.symbol)} alt={asset_.symbol} />,
    });
    symbolsAlreadyAdded.add(asset_.symbol);
  }

  return list;
};
