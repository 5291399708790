import clsx from "clsx";
import { Key } from "react-feather";
import { useState } from "react";
import { handleAuthentication } from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch } from "@axvdex/state";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/PermitAutheticationModal.module.scss";
import CustomLoader from "../common/CustomLoader";

function PermitAuthenticationModal() {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const { modal, hideModal } = useGlobalModalContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <CustomModal
      isOpen={"permitAuthenticationModal" === modal.modalType && modal.isOpen}
      onClose={hideModal}
      extraClassName={clsx(styles.permitAuthenticationModal, "permitAuthenticationModal")}
    >
      <section className="sectionModalHeader">
        <h1 className="h2 flexbox">
          <Key />
          {i18("Permit Authentication", "permitAuthenticationModal.header")}
        </h1>
      </section>

      <section className="sectionModalContent withGradientBorderBottom">
        <p>{i18("Authenticate for your full Astrovault features.", "permitAuthenticationModal.text.0")}</p>
        <p>
          {i18(
            "Permits provide another layer of security by checking you own the wallet that is accessing Astrovault.",
            "permitAuthenticationModal.text.1"
          )}
        </p>
        <p>{i18("Permits are free to create and use.", "permitAuthenticationModal.text.2")}</p>
      </section>

      <div className="buttonContainer">
        <Button
          btnColor="gradient"
          text={i18("Create Permit", "permitAuthenticationModal.btnCreatePermit.title")}
          title={i18("Create Permit to continue", "permitAuthenticationModal.btnCreatePermit.text")}
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await dispatch(handleAuthentication());
            hideModal();
            setIsLoading(false);
          }}
        />
        {isLoading && (
          <span style={{ marginLeft: "auto" }}>
            <CustomLoader size="xs" />
          </span>
        )}
      </div>
    </CustomModal>
  );
}

export default PermitAuthenticationModal;
