import { useEffect } from "react";
import CustomInput from "@axvdex/components/form-element/CustomInput";
import useLanguage from "@axvdex/hooks/useLanguage";

export const TransferDetailsContainer = ({ connectedChains, toAddr, setToAddr, assetChain }: any) => {
  const { i18 } = useLanguage();

  useEffect(() => setToAddr(assetChain.bech32Config.bech32PrefixAccAddr + "1"), [assetChain]);

  if (!connectedChains || 0 === Object.keys(connectedChains).length) return null;
  return (
    <ul className="tradeFormList">
      <CustomInput
        name="to_address"
        labelText={i18("To address", "trade.form.toAdress.label")}
        title={i18("To address", "trade.form.toAdress.label")}
        value={toAddr}
        onChange={e => setToAddr(e.target.value)}
      />
    </ul>
  );
};
