import { fetchWrapper } from ".";

export const getOutbidSales = headers =>
  fetchWrapper(
    "GET",
    "/outbid" + (process.env.REACT_APP_SUB_MODE ? "?env=" + process.env.REACT_APP_SUB_MODE.toLowerCase() : ""),
    undefined,
    {},
    headers
  );

// export const getOutbidSalesWalletSalesWithBids = headers =>
//   fetchWrapper("GET", "/outbid/walletSalesWithBids", undefined, {}, headers);

export const getOutbidSaleBidTable = (chainId, outbidAddress, saleAddress) => {
  let queryString = "?chainId=" + chainId;
  if (outbidAddress) queryString += "&outbidAddress=" + outbidAddress;
  if (saleAddress) queryString += "&saleAddress=" + saleAddress;

  return fetchWrapper("GET", "/outbid/saleBidTable" + queryString, undefined, {});
};
