import { BigNumber } from "bignumber.js";

export const humanizeAmount = (amount: BigNumber.Value, decimals: number): string =>
  BigNumber(amount)
    .div(BigNumber(Math.pow(10, decimals)))
    .toString(10);

export const normalize_amount = (amount: string | number | bigint, assetDecimals: number) => {
  const factor = Math.pow(10, 18 - assetDecimals);
  return BigInt(amount) * BigInt(factor);
};

export const denormalize_amount = (amount: string | number | bigint, assetDecimals: number) => {
  const factor = Math.pow(10, 18 - assetDecimals);
  return BigInt(amount) / BigInt(factor);
};

export const lp_token_from_18_to_6 = (amount: string | number | bigint) => {
  return BigInt(amount) / BigInt(Math.pow(10, 12));
};

export const lp_token_from_6_to_18 = (amount: string | number | bigint) => {
  return BigInt(amount) * BigInt(Math.pow(10, 12));
};

export const toUSDAmount = (price: number, assetAmount: bigint, assetDecimals: number) => {
  return parseFloat(BigNumber(assetAmount.toString(10)).div(Math.pow(10, assetDecimals)).times(price).toString(10));
};

export const formatBalance = (balance: string, price: number, assetDecimals: number) => {
  const maxDecimals = maxDecimalsToDisplay(BigNumber(balance).div(10 ** assetDecimals));
  const amount = parseFloat(
    BigNumber(balance)
      .div(10 ** assetDecimals)
      .decimalPlaces(maxDecimals, BigNumber.ROUND_FLOOR)
      .toString(10)
  );
  const usdConversion = BigNumber(price * amount)
    .decimalPlaces(2, BigNumber.ROUND_FLOOR)
    .toNumber();

  return { amount, usdConversion };
};

export const fromHumanAmountToTokenBalance = (amount: string, assetDecimals: number) => {
  if ("" === amount || !amount) return "0";
  return BigNumber(amount)
    .times(10 ** assetDecimals)
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString(10);
};

export const abbreviateNumber = (num: BigNumber.Value) => {
  const number = BigNumber(num);
  const digits = number.decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10).split("");
  if (0 === digits.length) return "0";
  if ("0" === digits[0]) {
    // if number has below 3 decimal places, return it as is
    if (number.times(1000).toString(10) < BigNumber(1).toString(10)) {
      return number.decimalPlaces(6, BigNumber.ROUND_FLOOR).toString(10);
    }
    return number.decimalPlaces(3, BigNumber.ROUND_FLOOR).toString(10);
  }
  if (digits.length <= 3) return number.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
  let suffix = "";
  let formattedNumber = "";
  if (digits.length > 18) {
    formattedNumber = number
      .div(10 ** 18)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString(10);
    suffix = "Qi";
  } else if (digits.length > 15) {
    formattedNumber = number
      .div(10 ** 15)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString(10);
    suffix = "Qa";
  } else if (digits.length > 12) {
    formattedNumber = number
      .div(10 ** 12)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString(10);
    suffix = "T";
  } else if (digits.length > 9) {
    formattedNumber = number
      .div(10 ** 9)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString(10);
    suffix = "B";
  } else if (digits.length > 6) {
    formattedNumber = number
      .div(10 ** 6)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString(10);
    suffix = "M";
  } else if (digits.length > 3) {
    formattedNumber = number
      .div(10 ** 3)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString(10);
    suffix = "K";
  }
  return `${formattedNumber}${suffix}`;
};

export const abbreviateUSD = (num: BigNumber.Value) => `$${abbreviateNumber(num)}`;

export const maxDecimalsToDisplay = (num: BigNumber) => {
  let maxDecimals = 4;
  if (num.times(10000).toString(10) < BigNumber(1).toString(10)) {
    maxDecimals = 6;
  }

  return maxDecimals;
};
