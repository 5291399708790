import clsx from "clsx";
import { NumericFormat } from "react-number-format";
import { isEqual } from "lodash";
import { Tooltip } from "react-tooltip";
import ButtonFavourite from "@axvdex/components/common/ButtonFavourite";
import styles from "@axvdex/styles/PoolCard.module.scss";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectAssets, selectChains, selectPoolsWithBalance } from "@axvdex/state/wallet/walletSelectors";
import { triggerFavouriteChange } from "@axvdex/state/pool/poolThunks";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { abbreviateUSD } from "@axvdex/utils/formatNumber";
import useLanguage from "@axvdex/hooks/useLanguage";
// import Button from "../common/Button";
// import { Info } from "react-feather";

import { AssetChainLogo } from "@axvdex/utils/chains";
import CollectRewardModal from "../modals/CollectRewardModal";

interface PoolCardProps {
  onClick: (pool: number) => void;
  pool: any;
  poolId: number;
  isSelected: boolean;
  favourite: boolean;
}

function PoolCard({ pool, poolId, isSelected, onClick, favourite }: PoolCardProps) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets, isEqual);
  const chains = useAppSelector(selectChains);
  const poolsWithBalance = useAppSelector(selectPoolsWithBalance);

  const toggleFavourite = async () => {
    await dispatch(triggerFavouriteChange({ isFavourite: !favourite, poolAddress: pool?.address }));
  };

  return (
    <article className={clsx(styles.poolCard, "poolCard", isSelected && "isSelected")} tabIndex={0}>
      <div className="buttonContainer">
        {poolsWithBalance.find(poolWithBalance => poolWithBalance.contract_addr === pool.address) &&
          pool.lp_staking_info.reward_sources.length > 0 && (
            <CollectRewardModal
              poolsToShow={[pool]}
              farmsToShow={[]}
              triggerElement="icon"
              specificChainId={pool.contextChainId}
            />
          )}
        <ButtonFavourite btnColor="purple" onToggleFavourite={toggleFavourite} isFavourite={favourite} />
      </div>
      <div className="poolCardHeader" onClick={() => onClick(poolId)}>
        <ul className="tokenGridList poolCardTokenList icons" style={{ marginBottom: "0em" }}>
          {pool.assetIDs.map((assetID, assetIndex) => (
            <li key={assetIndex}>
              <span title={assets[assetID]?.label} aria-label={assets[assetID]?.label}>
                <img src={imgSanitize(assets[assetID]?.symbol)} alt={assets[assetID]?.label} />
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="poolCardBody" onClick={() => onClick(poolId)}>
        <div className="poolCardBodyHeader">
          <ul className="poolCardTokenList name">
            {pool.assetIDs.map((assetID, assetIdx) => (
              <li key={assetIdx}>
                <span>{assets[assetID]?.symbol}</span>
              </li>
            ))}
          </ul>

          <div className="poolCardChain" style={{ display: "flex", marginTop: ".25em" }}>
            <span style={{ marginRight: ".5em" }}>
              {i18(pool.type.charAt(0).toUpperCase() + pool.type.slice(1) + " Pool", "poolCard.body.poolType.text", {
                poolType: pool.type.charAt(0).toUpperCase() + pool.type.slice(1),
              })}
            </span>
            <AssetChainLogo chain={chains[pool.contextChainId]} />
          </div>
        </div>

        <div className="poolCardRewards">
          <ul className="poolCardRewardsList">
            <li data-tooltip-id={"tooltip" + poolId}>
              <div className={clsx("inlineFlexbox")}>
                <span className={"poolAttribute"}>{i18("APR ", "poolCard.body.apr.text")}</span>
                <NumericFormat
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                  value={`${
                    pool && pool.percentageAPRs && pool.percentageAPRs.length > 0
                      ? pool.percentageAPRs.reduce((sum, source) => sum + source, 0) || 0
                      : 0
                  }`}
                  suffix="%"
                />
                <ul className="tokenGridList">
                  {pool &&
                    pool.lp_staking_info.reward_sources.map((rewardSource, idx) => {
                      const rewardAsset =
                        assets[
                          rewardSource.reward_asset.token?.contract_addr ||
                            rewardSource.reward_asset.native_token?.denom
                        ];
                      if (!rewardAsset) return;
                      return (
                        <li key={idx}>
                          <span title={rewardAsset.symbol} aria-label={rewardAsset.symbol}>
                            <img className="tokenIcon" src={imgSanitize(rewardAsset.symbol)} alt={rewardAsset.symbol} />
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </li>
            {pool && pool.lp_staking_info.reward_sources.length > 1 && (
              <Tooltip
                id={"tooltip" + poolId}
                place="top"
                style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                render={() => (
                  <div style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "1.5em" }}>APR distribution</span>
                    <br />
                    {pool.lp_staking_info.reward_sources.map((rewardSource, i) => {
                      const rewardAsset =
                        assets[
                          rewardSource.reward_asset.token?.contract_addr ||
                            rewardSource.reward_asset.native_token?.denom
                        ];
                      return (
                        <>
                          <div
                            className={clsx("inlineFlexbox")}
                            key={pool.address + "_" + i}
                            style={{ marginTop: "1em" }}
                          >
                            <ul className="tokenGridList">
                              <li>
                                <span title={rewardAsset.symbol} aria-label={rewardAsset.symbol}>
                                  <img
                                    className="tokenIcon"
                                    src={imgSanitize(rewardAsset.symbol)}
                                    alt={rewardAsset.symbol}
                                  />
                                </span>
                              </li>
                            </ul>
                            {pool.percentageAPRs && (
                              <NumericFormat
                                displayType="text"
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={2}
                                value={`${pool.percentageAPRs[i]}`}
                                suffix="%"
                              />
                            )}
                          </div>
                          <br />
                        </>
                      );
                    })}
                  </div>
                )}
              />
            )}
            <li>
              <span className={"poolAttribute"}>{i18("TVL ", "poolCard.body.tvl.text")}</span>
              <span>{abbreviateUSD(pool?.pool_tvl || 0)}</span>
            </li>
          </ul>
        </div>
      </div>
    </article>
  );
}

export default PoolCard;
