import { InputHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";

interface CustomInputButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  extraClassName?: string;
  type: "radio" | "checkbox";
  id: string;
  name: string;
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  labelText: string;
  labelIcon?: ReactNode;
  labelIconPlacement?: "left" | "right";
  suffixLabel?: ReactNode;
  badge?: ReactNode;
  fullWidth?: boolean;
}

function CustomInputButton({
  extraClassName,
  type,
  id,
  name,
  checked,
  onChange,
  disabled,
  labelText,
  labelIcon,
  labelIconPlacement,
  suffixLabel,
  fullWidth,
  badge,
  ...props
}: CustomInputButtonProps) {
  const { i18 } = useLanguage();

  // do NOT i18 labelText here, should only be done at the callee
  return (
    <div className={clsx("customInputButton", extraClassName && extraClassName)}>
      <input
        className="buttonInput"
        type={type ? type : "checkbox"}
        id={`id_${name}_${id}`}
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <label
        className={clsx("btn", disabled && "disabled")}
        data-icon-placement={labelIconPlacement && labelIconPlacement}
        htmlFor={`id_${name}_${id}`}
        style={
          fullWidth
            ? {
                width: "100%",
                backgroundColor: checked ? "var(--dark-blue-gradient)" : undefined,
              }
            : {}
        }
      >
        {labelIcon && labelIcon}
        {labelText && (typeof "" === typeof labelText ? labelText : i18("label", "customInputButton.label"))}
        {suffixLabel && suffixLabel}
        {badge}
      </label>
    </div>
  );
}

export default CustomInputButton;
