import BigNumber from "bignumber.js";
import { toUSDAmount } from "@axvdex/utils/formatNumber";
// import checkDerivativeMintOrTrade from "@axvdex/utils/swapScripts/checkDerivativeMintOrTrade";
import { IPoolAsset } from "../interfaces";
import estimatedFees, {
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD,
  GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD,
} from "../estimatedFees";
import { computeCashback } from "./simulateCashback";
import { computeStableSwap, computeStableSwapXAssetMode } from "./simulateStable";
import { computeStandardSwap } from "./simulateStandard";
import { computeHybridSwap } from "./simulateHybrid";

const simulateSwaps = (
  allPools: any,
  allContracts: any,
  cashbackMinter: any,
  allAssets: any,
  estimatedFeesReference: any,
  cashback: any,
  offerAssetAmount: any,
  path: any
) => {
  const pathSwaps: any = [];
  let gasEstimation = 0;

  // offer amount in each hop will be the amount returned by the previous swap hop
  let offerAmount = offerAssetAmount;

  for (const hop of path) {
    if (pathSwaps.length > 0) {
      offerAmount = pathSwaps[pathSwaps.length - 1].askAmount;
    }
    const pool = allPools[hop.p];

    if ("standard" === pool.type) {
      const { returnAmount, spreadAmount, feeAmount } = computeStandardSwap(pool, hop, offerAmount);

      const expectedCashbackMinted = cashbackMinter
        ? computeCashback(allAssets, pool, cashbackMinter, hop.x, offerAmount)
        : BigInt(0);

      const funds = [];
      const offeredAmount = offerAmount;
      pool.poolAssets.forEach(asset => {
        if (asset.info.native_token && asset.info.native_token.denom === hop.x) {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: offeredAmount.toString(),
          });
        }
      });

      pathSwaps.push({
        ...hop,
        offerAmount,
        askAmount: returnAmount,
        feeAmount,
        spreadAmount,
        expectedCashbackMinted,
        hopTx: {
          standard_hop_info: {
            pool: {
              id: pool.poolId,
            },
            from_asset_index: pool.poolAssets.findIndex((asset: IPoolAsset) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.x;
              }
              return asset.info.native_token.denom === hop.x;
            }),
          },
        },
        funds,
      });

      gasEstimation += GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD;
    }

    if ("stable" === pool.type) {
      const xAssetMode = !!pool.settings.xasset_mode_minter;

      const {
        returnAmount: askAmount,
        swap_to_asset_amount,
        feeAmount,
      } = xAssetMode ? computeStableSwapXAssetMode(pool, hop, offerAmount) : computeStableSwap(pool, hop, offerAmount);

      const expectedCashbackMinted = cashbackMinter
        ? computeCashback(
            allAssets,
            pool,
            cashbackMinter,
            hop.x,
            xAssetMode ? BigInt(swap_to_asset_amount) : offerAmount
          )
        : BigInt(0);

      // NOTE: With the introduction of xAsset Mode, the pool is the one responsible for minting or not
      //       So the derivativeOperation will always be "trade" from now on
      const derivativeOperation = "trade";
      // const derivativeOperation = checkDerivativeMintOrTrade(
      //   allAssets,
      //   allContracts,
      //   cashback,
      //   hop,
      //   offerAmount,
      //   askAmount,
      //   expectedCashbackMinted,
      //   pool
      // );

      const funds = [];
      const offeredAmount = offerAmount;
      pool.poolAssets.forEach(asset => {
        if (asset.info.native_token && asset.info.native_token.denom === hop.x) {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: offeredAmount.toString(),
          });
        }
      });

      // NOTE: With the introduction of xAsset Mode, the pool is the one responsible for minting or not
      //       So the derivativeOperation will always be "trade" from now on

      // if (derivativeOperation === "mint") {
      //   pathSwaps.push({
      //     ...hop,
      //     offerAmount,
      //     askAmount: offerAmount,
      //     feeAmount: BigInt(0),
      //     spreadAmount: BigInt(0),
      //     expectedCashbackMinted: BigInt(0),
      //     derivativeOperation,
      //     hopTx: {
      //       mint_staking_derivative: {
      //         contract_addr: allAssets[hop.y].derivativeContract,
      //         offer_asset: pool.poolAssets.find((asset: IPoolAsset) => {
      //           if (asset.info.token) {
      //             return asset.info.token.contract_addr === hop.x;
      //           }
      //           return asset.info.native_token.denom === hop.x;
      //         })!.info,
      //         ask_asset: pool.poolAssets.find((asset: IPoolAsset) => {
      //           if (asset.info.token) {
      //             return asset.info.token.contract_addr === hop.y;
      //           }
      //           return asset.info.native_token.denom === hop.y;
      //         })!.info,
      //       },
      //     },
      //     funds,
      //   });
      // } else {
      pathSwaps.push({
        ...hop,
        offerAmount,
        askAmount,
        feeAmount,
        spreadAmount: BigInt(0),
        expectedCashbackMinted,
        derivativeOperation,
        // ignore minimum received amount if on xAssetMode doing an index 0 to index 1 swap
        ignoreMinimumReceiveAmount:
          pathSwaps.length + 1 <= 1 &&
          xAssetMode &&
          pool.poolAssets.findIndex((asset: any) => {
            if (asset.info.token) {
              return asset.info.token.contract_addr === hop.x;
            }
            return asset.info.native_token.denom === hop.x;
          }) === 0,
        hopTx: {
          stable_hop_info: {
            pool: {
              id: pool.poolId,
            },
            from_asset_index: pool.poolAssets.findIndex((asset: any) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.x;
              }
              return asset.info.native_token.denom === hop.x;
            }),
            to_asset_index: pool.poolAssets.findIndex((asset: any) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.y;
              }
              return asset.info.native_token.denom === hop.y;
            }),
          },
        },
        funds,
      });

      gasEstimation += GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE;
      //}
    }

    if ("hybrid" === pool.type) {
      const { toAmount, feeAmount } = computeHybridSwap(pool, hop, offerAmount);

      const expectedCashbackMinted = cashbackMinter
        ? computeCashback(allAssets, pool, cashbackMinter, hop.x, offerAmount)
        : BigInt(0);

      const funds = [];
      const offeredAmount = offerAmount;
      pool.poolAssets.forEach(asset => {
        if (asset.info.native_token && asset.info.native_token.denom === hop.x) {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: offeredAmount.toString(),
          });
        }
      });

      pathSwaps.push({
        ...hop,
        offerAmount,
        askAmount: toAmount,
        feeAmount,
        spreadAmount: BigInt(0),
        expectedCashbackMinted,
        derivativeOperation: null,
        hopTx: {
          ratio_hop_info: {
            pool: {
              id: pool.poolId,
            },
            from_asset_index: pool.poolAssets.findIndex((asset: any) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.x;
              }
              return asset.info.native_token.denom === hop.x;
            }),
          },
        },
        funds,
      });

      gasEstimation += GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID;
    }
  }

  // calc final amount in USD
  const finalAskAmountUSD = toUSDAmount(
    allAssets[pathSwaps[pathSwaps.length - 1].y].price!,
    pathSwaps[pathSwaps.length - 1].askAmount,
    allAssets[pathSwaps[pathSwaps.length - 1].y].decimals
  );

  try {
    // calc estimated gas fees
    if (path.length > 1) gasEstimation += GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD * path.length;
    const estimatedFeesAmount = estimatedFees(gasEstimation, estimatedFeesReference);
    const gasAsset = allAssets[estimatedFeesReference.estimatedFee[0].denom];
    const estimatedFeesUSD = BigNumber(estimatedFeesAmount)
      .div(Math.pow(10, gasAsset.decimals))
      .times(gasAsset.price)
      .toNumber();

    // put a field on the last hop to show the final amount in USD including the estimated gas fee that will be used for sorting for best route
    if (estimatedFeesUSD > 0)
      pathSwaps[pathSwaps.length - 1].estimatedFinalAskAmountUSD_withGasFee = finalAskAmountUSD - estimatedFeesUSD;
  } catch (error) {
    console.error("Error estimating gas fee", error);
    pathSwaps[pathSwaps.length - 1].estimatedFinalAskAmountUSD_withGasFee = finalAskAmountUSD;
  }

  return pathSwaps;
};
export default simulateSwaps;
