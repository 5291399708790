import { io } from "socket.io-client";
import parser from "socket.io-msgpack-parser";

export const socket =
  "1" !== process.env.REACT_APP_MAINTENANCE_MODE
    ? io(process.env.REACT_APP_BACKEND_URL, {
        parser,
        reconnectionAttempts: 5,
        reconnectionDelay: 30000,
      })
    : null;
