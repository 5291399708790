/* eslint-disable import/no-named-as-default-member */
import React, { useState, createContext, useEffect, useCallback } from "react";
import { I18nextProvider } from "react-i18next";
import dayjs from "dayjs";
import i18n from "../localization/config";

const LANGUAGE_KEY = "lang";
const DEFAULT_LANGUAGE = "EN";

const LanguageContext = createContext({
  language: DEFAULT_LANGUAGE,
  changeLanguage: (_: string) => {},
});

const locales = {
  de: () => import("dayjs/locale/de"),
  en: () => import("dayjs/locale/en"),
  es: () => import("dayjs/locale/es"),
};

const LanguageContextProvider = ({ children }: React.PropsWithChildren) => {
  const [language, setLanguage] = useState(localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE);

  useEffect(() => {
    locales[(language ?? "en").toLowerCase()]()
      .then(locale => {
        dayjs.locale(locale);
        return;
      })
      .catch(() => {});
    i18n.changeLanguage(language);
  }, []);

  const changeLanguage = useCallback(
    (lang: string) => {
      setLanguage(() => {
        localStorage.setItem(LANGUAGE_KEY, lang);
        return lang;
      });
      i18n.changeLanguage(lang);
      locales[lang]()
        .then(locale => {
          dayjs.locale(locale);
          return;
        })
        .catch(() => {});
    },
    [i18n]
  );

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageContextProvider };
