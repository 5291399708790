import { useCallback, useState, useMemo, useEffect } from "react";
import clsx from "clsx";
//import { NumericFormat } from "react-number-format";
import { isEqual } from "lodash";
import BigNumber from "bignumber.js";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  // selectAssetBalances,
  selectAssets,
  selectChains,
  selectFarmsWithBalance,
  selectFavoriteFarms,
  // selectFavoritePools,
} from "@axvdex/state/wallet/walletSelectors";
// import { formatBalance } from "@axvdex/utils/formatNumber";
// import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import { triggerFarmFavouriteChange } from "@axvdex/state/farm/farmThunks";
import { AssetChainLogo } from "@axvdex/utils/chains";
import CustomModal from "../common/CustomModal";
import Button from "../common/Button";
import ButtonFavorite from "../common/ButtonFavourite";
//import UserRewardsAmount from "../user/UserRewardsAmount";
import styles from "../../styles/PoolCardDetailsModal.module.scss";

//import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
// import PoolCardStandardDepositTable from "../pools/PoolCardStandardDepositTable";
// import PoolCardLockupsTable from "../pools/PoolCardLockupsTable";
// import PoolCardStableDepositTable from "../pools/PoolCardStableDepositTable";
// import PoolCardStandardWithdrawalTable from "../pools/PoolCardStandardWithdrawalTable";
// import PoolCardStableWithdrawalTable from "../pools/PoolCardStableWithdrawalTable";
// import PoolCardHybridDepositTable from "../pools/PoolCardHybridDepositTable";
// import PoolCardHybridWithdrawalTable from "../pools/PoolCardHybridWithdrawalTable";
import FarmCardDetailsTable from "../farms/FarmCardDetailsTable";
import { FarmRepresentation } from "../common/FarmRepresentation";
import CollectRewardModal from "./CollectRewardModal";

interface FarmCardDetailsModalProps {
  openModal: boolean;
  onCloseModal: () => void;
  farms: any;
  farmAddress: string;
}

function FarmCardDetailsModal({ openModal, onCloseModal, farms, farmAddress }: FarmCardDetailsModalProps) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const favoriteFarms = useAppSelector(selectFavoriteFarms, isEqual);
  const assets = useAppSelector(selectAssets, isEqual);
  const chains = useAppSelector(selectChains);
  const farmsWithBalance = useAppSelector(selectFarmsWithBalance);
  const [farmModalTabList, setFarmModalTabList] = useState([
    { name: i18("Deposit", "farmCardDetails.deposit") },
    { name: i18("Withdraw", "farmCardDetails.withdraw") },
  ]);

  const farmData = useMemo(() => farms?.find(farm => farmAddress === farm.address) || null, [farmAddress, farms]);
  const [isFavourite, setIsFavourite] = useState(favoriteFarms.includes(farmAddress));

  useEffect(() => {
    setIsFavourite(favoriteFarms.includes(farmAddress));
  }, [farmAddress, favoriteFarms]);

  const toggleFavourite = async () => {
    setIsFavourite(isFavourite => !isFavourite);
    await dispatch(triggerFarmFavouriteChange({ isFavourite: !isFavourite, farmAddress: farmData.address }));
  };

  const [activeTab, setActiveTab] = useState(0);
  const HandleTabClick = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  return (
    <CustomModal
      isOpen={openModal}
      onClose={onCloseModal}
      extraClassName={clsx(styles.poolCardDetailsModal, "poolCardDetailsModal")}
      modalType="right"
    >
      {farmData && (
        <>
          <section className="poolCardModalSectionHeader withGradientBorderBottom">
            <div className="poolCardModalTokenListIconWrapper">
              <ul className="tokenGridList poolCardModalTokenList icons">
                {/* {tokenList?.map((item: any, idx: number) => (
                  <li key={idx}>
                    <span title={item.name}>{item.icon}</span>
                  </li>
                ))} */}
              </ul>
            </div>
            <div className="flexbox">
              <div className="poolCardModalTokenListNameWrapper">
                <ul className="poolCardTokenList name">
                  {farmData && (
                    <FarmRepresentation
                      address={farmData.address}
                      inputAsset={{
                        label: assets[farmData.config.inc_token]?.label,
                        symbol: assets[farmData.config.inc_token]?.symbol,
                      }}
                      outputAssets={farmData.reward_sources.map(rewardSource => {
                        const rewardAsset =
                          assets[
                            rewardSource.reward_asset.token?.contract_addr ||
                              rewardSource.reward_asset.native_token?.denom
                          ];
                        return {
                          label: rewardAsset?.label,
                          symbol: rewardAsset?.symbol,
                        };
                      })}
                      sizeScale={1.5}
                    />
                  )}
                </ul>
                <ul className="poolCardTokenList name" style={{ marginTop: ".5em", fontSize: "var(--px24)" }}>
                  {farmData.reward_sources.map(rewardSource => (
                    <li
                      key={
                        rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom
                      }
                    >
                      <span>
                        {
                          assets[
                            rewardSource.reward_asset.token?.contract_addr ||
                              rewardSource.reward_asset.native_token?.denom
                          ].symbol
                        }
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="poolCardChain" style={{ display: "flex" }}>
                  <span style={{ fontSize: "16px", marginRight: ".5em" }}>
                    {i18("Farm", "dashboard.farm.farmTitle")}
                  </span>
                  <AssetChainLogo chain={chains[farmData.contextChainId]} />
                  <span style={{ fontSize: "16px", marginLeft: ".5em" }}>
                    (
                    {`${i18("APR", "poolCard.body.apr.text")}: ${
                      farmData && farmData.percentageAPRs && farmData.percentageAPRs.length > 0
                        ? farmData.percentageAPRs.reduce((sum, source) => sum + source, 0) || 0
                        : 0
                    }%`}
                    )
                  </span>
                </div>
              </div>

              <div className="buttonContainer">
                {
                  // only show this if the user have lp balance > 0 on this pool
                  farmsWithBalance.find(farmWithBalance => farmAddress === farmWithBalance.contract_addr) &&
                    farmData.reward_sources.length > 0 && (
                      <CollectRewardModal
                        poolsToShow={[]}
                        farmsToShow={[farmData]}
                        triggerElement="icon"
                        specificChainId={farmData.contextChainId}
                      />
                    )
                }
                <ButtonFavorite btnColor="purple" onToggleFavourite={toggleFavourite} isFavourite={isFavourite} />
              </div>
            </div>
          </section>

          <section className="poolCardModalSectionRewards withGradientBorderBottom">
            <ul className="poolCardModalSectionRewardsList">
              {farmData && (
                <li>
                  <span>
                    {BigNumber(farmData.tvl)
                      .div(Math.pow(10, assets[farmData.config.inc_token].decimals))
                      .decimalPlaces(4)
                      .toString(10)}
                    &nbsp;{assets[farmData.config.inc_token].symbol}
                  </span>
                </li>
              )}
            </ul>
          </section>

          <section className="poolCardModalSectionTabs">
            <ul className="btnGroup navTabs">
              {farmModalTabList.map((item, idx) => (
                <li key={idx}>
                  <Button
                    text={item.name}
                    title={i18(`View ${item.name}`, "farmCardDetails.body.tabs.title", { tabName: item.name })}
                    extraClassName={clsx(activeTab === idx && "isActive")}
                    onClick={() => HandleTabClick(idx)}
                  />
                </li>
              ))}
            </ul>

            <section className="poolCardModalSectionTabsContent">
              {0 === activeTab && <FarmCardDetailsTable farmData={farmData} isDeposit={true} />}
              {1 === activeTab && <FarmCardDetailsTable farmData={farmData} isDeposit={false} />}
            </section>
          </section>
        </>
      )}
    </CustomModal>
  );
}

export default FarmCardDetailsModal;
