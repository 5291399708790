import clsx from "clsx";
import { WHITE_LIST_PERSISTED_STATE_KEYS, persistState } from "@axvdex/state/persist";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/CollectRewardsModal.module.scss";

function ComplianceModal() {
  const { i18 } = useLanguage();
  const { showModal } = useGlobalModalContext();

  const { modal, hideModal } = useGlobalModalContext();
  return (
    <>
      <CustomModal
        isOpen={"complianceModal" === modal.modalType && modal.isOpen}
        onClose={hideModal}
        extraClassName={clsx(styles.collectRewardsModal, "collectRewardsModal")}
        closeButton={false}
        {...modal.modalProps}
      >
        <section className="sectionModalHeader">
          <h1 className="h2">{i18("Compliance", "complianceModal.header")}</h1>

          <p className="textGrey">
            {/*{i18("I accept that I am not in any region deemed not acceptable to use this dApp", "complianceModal.text.0")}*/}
            {i18(
              "I understand and accept that I am not in any region deemed not acceptable to use this dApp.",
              "complianceModal.text.1"
            )}
          </p>

          <p>
            <a href="/legal#restricted-countries" rel="noopener noreferrer">
              {i18("View restricted countries, risks and disclaimers", "complianceModal.text.2")}
            </a>
          </p>
        </section>

        <section className="sectionCollectRewards">
          <div className="buttonContainer">
            <Button
              btnColor="gradient"
              title={i18("Confirm", "complianceModal.btnConfirm.title")}
              text={i18("I confirm this to be true", "complianceModal.btnConfirm.text")}
              onClick={() => {
                persistState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance, true);
                hideModal();
                if ((modal.modalProps as any)?.connectWalletAfter) {
                  showModal("walletSelectionModal");
                }
              }}
            />
          </div>
        </section>
      </CustomModal>
    </>
  );
}

export default ComplianceModal;
