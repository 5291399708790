import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectMyToastMessages, selectToast, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { updateUserToastMessages } from "@axvdex/api/user";
import { sendToast, updateToastMessages } from "@axvdex/state/wallet/walletSlice";
import Toaster from "./toasts/Toaster";
import ToastIbcTransfer from "./toasts/ToastIbcTransfer";

export interface ToastMessage {
  id: string;
  date: string;
  time: string;
  message: string;
  links: { label: string; url: string }[];
}

// we use this component to pick up a dispatched toaster
// and then clean it up with the useEffect so other toasts can be dispatched
function BackgroundToastSender() {
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useAppSelector(selectToast);
  const myToastMessages = useAppSelector(selectMyToastMessages);
  const walletInfo = useAppSelector(selectWalletInfo);

  const triggerToast = () => {
    if (toast?.info?.msg) {
      let messages = myToastMessages ? [...myToastMessages] : [];
      const timestamp = new Date().getTime();
      let message: ToastMessage | null;
      switch (toast?.type) {
        case "tx-success":
          message = {
            id: "" + timestamp,
            date: dayjs(timestamp).format("DD/MM/YYYY"),
            time: dayjs(timestamp).format("LT"),
            message: toast.info.msg,
            links: toast.info.txLink ? [{ label: "Link", url: toast.info.txLink }] : [],
          };
          messages ? messages.unshift(message) : (messages = [message]);
          break;
        case "tx-fail":
          message = {
            id: "" + timestamp,
            date: dayjs(timestamp).format("DD/MM/YYYY"),
            time: dayjs(timestamp).format("LT"),
            message: toast.info.msg,
            links: toast.info.txLink ? [{ label: "Link", url: toast.info.txLink }] : [],
          };
          messages ? messages.unshift(message) : (messages = [message]);
          break;
        case "ibc-transfer-success":
          message = {
            id: "" + timestamp,
            date: dayjs(timestamp).format("DD/MM/YYYY"),
            time: dayjs(timestamp).format("LT"),
            message: toast.info.msg,
            links: [],
          };
          if (toast.info.sendLink) message.links.push({ label: "Send", url: toast.info.sendLink });
          if (toast.info.receiveLink) message.links.push({ label: "Receive", url: toast.info.receiveLink });
          if (toast.info.ackLink) message.links.push({ label: "Ack", url: toast.info.ackLink });
          messages ? messages.unshift(message) : (messages = [message]);
          break;
        case "ibc-transfer-fail":
          message = {
            id: "" + timestamp,
            date: dayjs(timestamp).format("DD/MM/YYYY"),
            time: dayjs(timestamp).format("LT"),
            message: toast.info.msg,
            links: [],
          };
          if (toast.info.sendLink) message.links.push({ label: "Send", url: toast.info.sendLink });
          if (toast.info.receiveLink) message.links.push({ label: "Receive", url: toast.info.receiveLink });
          if (toast.info.ackLink) message.links.push({ label: "Ack", url: toast.info.ackLink });
          if (toast.info.timeoutLink) message.links.push({ label: "Timeout", url: toast.info.timeoutLink });
          messages ? messages.unshift(message) : (messages = [message]);
          break;
        default:
          message = {
            id: "" + timestamp,
            date: dayjs(timestamp).format("DD/MM/YYYY"),
            time: dayjs(timestamp).format("LT"),
            message: toast.info.msg,
            links: [],
          };
          messages ? messages.unshift(message) : (messages = [message]);
      }

      if (!toast.noStore) {
        dispatch(updateToastMessages(messages));
        try {
          if (loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey]) {
            updateUserToastMessages(
              { myToastMessages: messages },
              {
                pubkey: walletInfo.pubKey,
                signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
              }
            );
          }
        } catch (e) {
          console.log(e);
        }
      }

      return;
    }
    dispatch(sendToast(null));
  };

  useEffect(() => {
    toast && triggerToast();
  }, [toast]);

  return (
    <>
      {toast && "info" === toast.type && (
        <Toaster
          openToast={true}
          isSuccess={true}
          isInfo={true}
          msg={toast.info.msg}
          txLink={toast.info.txLink}
          toastID={toast.info.toastID}
        />
      )}
      {toast && "tx-success" === toast.type && (
        <Toaster
          openToast={true}
          isSuccess={true}
          msg={toast.info.msg}
          txLink={toast.info.txLink}
          toastID={toast.info.toastID}
        />
      )}
      {toast && "tx-fail" === toast.type && (
        <Toaster
          openToast={true}
          isSuccess={false}
          msg={toast.info.msg}
          txLink={toast.info.txLink}
          toastID={toast.info.toastID}
        />
      )}
      {toast && "ibc-transfer-success" === toast.type && (
        <ToastIbcTransfer
          openToast={true}
          isSuccess={true}
          msg={toast.info.msg}
          sendLink={toast.info.sendLink}
          receiveLink={toast.info.receiveLink}
          ackLink={toast.info.ackLink}
          timeoutLink={toast.info.timeoutLink}
          toastID={toast.info.toastID}
        />
      )}
      {toast && "ibc-transfer-fail" === toast.type && (
        <ToastIbcTransfer
          openToast={true}
          isSuccess={false}
          msg={toast.info.msg}
          sendLink={toast.info.sendLink}
          receiveLink={toast.info.receiveLink}
          timeoutLink={toast.info.timeoutLink}
          ackLink={toast.info.ackLink}
          toastID={toast.info.toastID}
        />
      )}
    </>
  );
}

export default BackgroundToastSender;
