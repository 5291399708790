import { AlertTriangle } from "react-feather";
import { useEffect, useState } from "react";
import { useAppSelector } from "@axvdex/state";
import { selectChains, selectGlobalConfig } from "@axvdex/state/wallet/walletSelectors";
import useLanguage from "@axvdex/hooks/useLanguage";

function StatusMessages() {
  const { i18 } = useLanguage();
  const globalConfig = useAppSelector(selectGlobalConfig);
  const chains = useAppSelector(selectChains);
  const [statusMessages, setStatusMessages] = useState(null);

  const addedTextForMigration = null;
  //"Astrovault is deploying and migrating liquidity for multichain, some services/pools might be temporarily inaccessible, funds are safe";

  useEffect(() => {
    if (globalConfig && globalConfig.statusCounters) {
      const chainsAffected = [];
      Object.keys(globalConfig.statusCounters).forEach((chainId: string) => {
        if (globalConfig.statusCounters[chainId]?.rpc > 15) {
          chainsAffected.push(chainId);
        }
      });

      // alert appears if there are more than 15 messages of error
      // between current window and prev (15min windows so 30 min in total)
      if (addedTextForMigration || chainsAffected.length > 0) {
        setStatusMessages(
          <div className="flexbox">
            <span className="warning tokenIcon">
              <AlertTriangle />
            </span>
            <span className="tokenName">
              {addedTextForMigration}
              {/* good msg but too techie, toned it down but kept an indicator of context for tech support *RPC nodes* */}
              {/*Status Report: RPC nodes are currently unstable. Onchain requests can be affected by this...*/}
              {chainsAffected.length > 0 &&
                i18(
                  "Network service issues detected that can disrupt Astrovault onchain requests with RPC nodes. Affected chains: " +
                    chainsAffected.map(chainId => chains[chainId].displayName).join(", ") +
                    ".",
                  "statusMessages.rpcNodes.text"
                )}
            </span>
          </div>
        );
      }

      /* output a warning to the console to prevent this test mode being accidentally left on */
      console.log("WARNING: Status 'testStatusMsg' mode is active");
    }
  }, [globalConfig]);

  if (!statusMessages) return <></>;

  return <section className="seviceStatus withGradientBorder">{statusMessages}</section>;
}

export default StatusMessages;
